import * as Yup from "yup";
import {
  COUNTRIES_PHONE_AREA_CODE_REGEX,
  PASSWORD_DIGITS_CHARACTER_ERROR_MESSAGE,
  PASSWORD_DIGITS_CHARACTER_REGEX,
  PASSWORD_LOWERCASE_CHARACTER_ERROR_MESSAGE,
  PASSWORD_LOWERCASE_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_LENGTH_ERROR_MESSAGE,
  PASSWORD_SPECIAL_CHARACTER_ERROR_MESSAGE,
  PASSWORD_SPECIAL_CHARACTER_REGEX,
  PASSWORD_UPPERCASE_CHARACTER_ERROR_MESSAGE,
  PASSWORD_UPPERCASE_CHARACTER_REGEX,
} from "./common";

export const PhoneCountryCodeSchema = Yup.string()
  .trim()
  .required("Required")
  .transform((value) => value.replace("+", "").replace(/ /g, ""))
  .matches(COUNTRIES_PHONE_AREA_CODE_REGEX, "Invalid country code");

export const PhoneNumberSchema = Yup.string()
  .trim()
  .required("Phone number is required")
  .when("countryCode", {
    is: (countryCode) => !countryCode || countryCode === "1",
    then: Yup.string().matches(/(^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$)/, { message: "Phone number must be a valid 10 digit US number" }),
    otherwise: Yup.string().matches(/^[0-9 ]+$/, "Must be only digits and spaces"),
  });

export const PasswordSchema = Yup.string()
  .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_ERROR_MESSAGE)
  .matches(PASSWORD_UPPERCASE_CHARACTER_REGEX, PASSWORD_UPPERCASE_CHARACTER_ERROR_MESSAGE)
  .matches(PASSWORD_LOWERCASE_CHARACTER_REGEX, PASSWORD_LOWERCASE_CHARACTER_ERROR_MESSAGE)
  .matches(PASSWORD_DIGITS_CHARACTER_REGEX, PASSWORD_DIGITS_CHARACTER_ERROR_MESSAGE)
  .matches(PASSWORD_SPECIAL_CHARACTER_REGEX, PASSWORD_SPECIAL_CHARACTER_ERROR_MESSAGE)
  .required("Required");

export const PasswordConfirmSchema = Yup.string()
  .oneOf([Yup.ref("password")], "Passwords must match")
  .required("Required");