import React, { useState } from "react";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import { usePageControls } from "ampla-core/contexts";
import { tagForTimeframe, xForTimeframe } from "components/AnalyticsLineGraph";
import AnalyticsLineStatsDashboard, {
  AggregateType,
  ChartProps,
} from "components/AnalyticsLineStatsDashboard";
import Header from "components/Header";
import PageControls from "components/PageControls";
import useChartData from "hooks/useChartData";
import IntegrationCta from "components/IntegrationCta";
import { useQuery } from "react-query";
import FivetranAPI from "ampla-core/api/fivetran";
import camelcaseKeys from "camelcase-keys";
import { CamelCasedFivetranIntegration } from "ampla-core/api/fivetran/types";
import { useEmbedded } from "contexts/EmbedContext";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

enum MarketingChart {
  CostPerClick = "CostPerClick",
  CostPerMile = "CostPerMile",
  CustomerAcquisitionCost = "CustomerAcquisitionCost",
  Impressions = "Impressions",
  NewCustomerRevenue = "NewCustomerRevenue",
  NewCustomers = "NewCustomers",
  ReturnOnAdSpend = "ReturnOnAdSpend",
  Spend = "Spend",
}

const Marketing: React.FC = () => {
  const pageControls = usePageControls();
  const isEmbedded = useEmbedded();

  const analyticsData = useChartData({ controls: true }, [
    AnalyticsGraph.MarketingPerformance,
  ]);

  const [selectedChart, setSelectedChart] = useState(
    MarketingChart.CostPerClick
  );

  const fetchFivetranIntegrations = async () => {
    const response = await FivetranAPI.get_available_integrations();
    return camelcaseKeys(response, { deep: true }) as {
      ads: Array<CamelCasedFivetranIntegration>;
    };
  };

  const { data: availableIntegrations } = useQuery({
    queryKey: "fivetran-integrations",
    queryFn: fetchFivetranIntegrations,
  });

  const logoUrls =
    availableIntegrations?.ads
      ?.map((i) => i.logoUrl)
      .filter((url) => url != null) ?? [];

  const CHART_PROPS: ChartProps<MarketingChart>[] = [
    {
      key: MarketingChart.CostPerClick,
      title: "Cost per Click",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Cost per click on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "cost_per_click",
      unit: "per click",
    },
    {
      key: MarketingChart.CostPerMile,
      title: "Cost per Million Impressions",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Cost per million impressions on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "cost_per_mile",
      unit: "per 1m impressions",
    },
    {
      key: MarketingChart.CustomerAcquisitionCost,
      title: "Customer Acquisition Cost",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Customer acquisition cost on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "customer_acquisition_cost",
      unit: "per customer",
    },
    {
      key: MarketingChart.Impressions,
      title: "Impressions",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Impressions on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "impressions",
      unit: "impressions",
    },
    {
      key: MarketingChart.NewCustomerRevenue,
      title: "New Customer Revenue",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Revenue from new customers on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "new_customer_revenue",
      unit: "from new customers",
    },
    {
      key: MarketingChart.NewCustomers,
      title: "New Customers",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `New customers on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "new_customers",
      unit: "new customers",
    },
    {
      key: MarketingChart.ReturnOnAdSpend,
      title: "Return on Ad Spend",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Return on ad spend on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "return_on_ad_spend",
    },
    {
      key: MarketingChart.Spend,
      title: "Spend",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.MarketingPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Marketing performance on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "spend",
      unit: "spent",
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Marketing</Header>
          <PageControls />
        </div>
      ) : null}

      <AnalyticsLineStatsDashboard
        data={analyticsData}
        placeholderComponent={
          <IntegrationCta
            title="Connect your advertising platforms"
            icons={logoUrls.map((logoUrl: string) => (
              <img
                src={logoUrl}
                alt="Integration logo"
                className="object-cover rounded-full w-16 h-16"
              />
            ))}
          />
        }
        chartProps={CHART_PROPS}
        selectedChart={selectedChart}
        onSelectChart={setSelectedChart}
      />
    </>
  );
};

export default Marketing;
