import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { NumberTypography } from "@ampla/ui-components";
import SerifTypography from "ampla-core/components/Common/SerifTypography";

export interface ChartTitleProps {
  title: string;
  value: string | number;
  isMoney: boolean;
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    fontSize: 13,
    textTransform: "uppercase",
    fontWeight: 400,
    lineHeight: "1.063rem",
    marginBottom: 8,
  },
  content: {
    fontSize: 26,
    fontWeight: 400,
    lineHeight: "1.75rem",
  },
}));

const ChartTitle: React.FC<ChartTitleProps> = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.title} variant="h6">
        {props.title}
      </Typography>
      {props.isMoney ? (
        <NumberTypography
          unit="currency"
          value={props.value}
          variant="h3"
          className={classes.content}
        />
      ) : (
        <SerifTypography variant="h3" className={classes.content}>
          {props.value}
        </SerifTypography>
      )}
    </div>
  );
};

export default ChartTitle;
