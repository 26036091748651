import React, { createContext, useContext, useEffect, useState } from "react";
import { User } from "ampla-core/types";
import { SESSION_TOKEN_KEY } from "../api/rest";
import * as Auth from "ampla-core/api/auth/auth";
import useSession from "hooks/useSession";
import { Event, useSubscribe } from "ampla-core/pubsub";
import { identifyUserInHeap } from "util/heap";

export interface AuthState {
  user: null | User;
  isLoading: boolean;
}

export interface AuthActions {
  setToken: (token: string) => void;
  refetchUser: () => Promise<void>;
  signOut: () => void;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
};

type AuthContext = AuthState & AuthActions;

const Context = createContext(initialState as AuthContext);

const AuthContextProvider: React.FC = (props) => {
  const [token, tokenSession] = useSession(SESSION_TOKEN_KEY);
  const [user, setUser] = useState(null as null | User);

  useSubscribe(Event.SIGN_IN, tokenSession.set);
  useSubscribe(Event.REFETCH_USER, () => refetchUser());

  useEffect(() => {
    if (token == null) {
      setUser(null);
    } else {
      refetchUser();
    }
  }, [token]);

  useEffect(() => {
    if (user && token) identifyUserInHeap(user);
  }, [user]);

  async function refetchUser() {
    await Auth.get_current_user().then(setUser);
  }

  const value: AuthContext = {
    user,
    isLoading: user === null && token !== null,
    setToken: tokenSession.set,
    refetchUser,
    signOut: () => tokenSession.remove(),
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const useAuthContext = () => useContext(Context);

export default AuthContextProvider;
