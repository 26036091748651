const { Typography, withStyles } = require("@material-ui/core");

const SerifTypography = withStyles({
  root: {
    fontFamily: "Merriweather",
  },
  h1: {
    fontWeight: "normal",
  },
  h2: {
    fontWeight: "normal",
  },
  h3: {
    fontWeight: "normal",
  },
  h4: {
    fontWeight: "normal",
  },
  h5: {
    fontWeight: "normal",
  },
  h6: {
    fontWeight: "normal",
  },
})(Typography);

export default SerifTypography;
