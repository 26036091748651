import React from "react";
import classNames from "classnames";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import moment from "moment";
import { formatChartValue } from "util/charts";
import { Datum } from "@nivo/line";
import { theme } from "@ampla/ui-components";
import interpolate from "color-interpolate";
import { processHeatmapData } from "components/AnalyticsHeatmap";
import { Tooltip } from "@ampla/ui-components";
import ContextualTooltip, {
  buildTooltipData,
  TooltipData,
} from "components/ContextualTooltip";

const colormap = interpolate([
  theme.palette.grey[100],
  theme.palette.ampla.teal[400],
]);

export interface CohortsTableProps {
  g: string;
  x: string;
  y: string;
  type: AnalyticsGraphType;
  data: AnalyticsResult;
}

// Should explore how to display these in a way that doesn't crowd the page
const SHOW_CAC = false;
const SHOW_AD_SPEND = false;

const CohortsTable: React.FC<CohortsTableProps> = (props) => {
  const data = processHeatmapData(props.data, props.g, props.x, props.y);

  const { min, max } = calcMinMax(data.flatMap((d) => d.data));

  return (
    <div className="flex w-full">
      <div className="border-r">
        <div className="h-4" />

        <Table>
          <Thead>
            <Th>Cohort</Th>
            <Th className="text-right">New customers</Th>
            <Th>First order</Th>
            {SHOW_CAC && <Th>CAC</Th>}
            {SHOW_AD_SPEND && <Th>Ad spend</Th>}
          </Thead>
          <Tbody>
            {data.map((d) => (
              <tr key={d.id}>
                <Td>{moment(d.id).format("MMM YYYY")}</Td>
                <Td alignRight>
                  {d.item.signups != null
                    ? formatChartValue(
                        AnalyticsGraphType.Integer,
                        d.item.signups
                      )
                    : "--"}
                </Td>
                <Td>
                  {d.item.average_first_purchase_value != null
                    ? formatChartValue(
                        AnalyticsGraphType.Money,
                        d.item.average_first_purchase_value,
                        { short: true }
                      )
                    : "--"}
                </Td>
                {SHOW_CAC && (
                  <Td>
                    {d.item.average_first_purchase_value != null
                      ? formatChartValue(
                          AnalyticsGraphType.Money,
                          d.item.signup_month_cac,
                          { short: true }
                        )
                      : "--"}
                  </Td>
                )}
                {SHOW_AD_SPEND && (
                  <Td>
                    {d.item.average_first_purchase_value != null
                      ? formatChartValue(
                          AnalyticsGraphType.Money,
                          d.item.signup_month_ad_spend,
                          { short: true }
                        )
                      : "--"}
                  </Td>
                )}
              </tr>
            ))}
          </Tbody>
        </Table>
      </div>

      <div className="flex-1 overflow-hidden">
        <div className="h-4 text-center">
          <p className="text-sm text-gray-500 tracking-wide">
            Months since first order
          </p>
        </div>

        <div className="overflow-x-scroll">
          <Table>
            <Thead>
              {data[0].data.map((d: Datum, i: number) => (
                <Th key={i} cell>
                  {d.x}
                </Th>
              ))}
            </Thead>
            <Tbody>
              {data.map((d) => (
                <tr key={d.id}>
                  {d.data.map((c: Datum, i: number) => (
                    <TdCell
                      key={i}
                      shade={linearShade(min, max, c.y as number)}
                      isLast={i === d.data.length - 1}
                      isPaydownMonth={i === d.item.months_to_paydown}
                      tooltipData={buildTooltipData({
                        chart: AnalyticsGraph.InsightsCohortSpendPerUser,
                        id: d.id,
                        item: d.item,
                        point: c,
                        month: i,
                      })}
                    >
                      {c.y != null
                        ? formatChartValue(props.type, c.y!, { short: true })
                        : "--"}
                    </TdCell>
                  ))}
                </tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const Table: React.FC = (props) => {
  return <table>{props.children}</table>;
};

const Thead: React.FC<{ className?: string }> = (props) => {
  return (
    <thead
      className={classNames(
        "text-sm text-gray-500 tracking-wide font-normal",
        props.className
      )}
    >
      <tr>{props.children}</tr>
    </thead>
  );
};

const Th: React.FC<{ className?: string; cell?: boolean }> = (props) => {
  return (
    <th className={classNames("relative py-8 px-10", props.className)}>
      <div
        className={classNames(
          "absolute inset-0 flex items-end pb-4",
          props.cell ? "justify-center" : "pr-4"
        )}
      >
        {props.children}
      </div>
    </th>
  );
};

const Tbody: React.FC = (props) => {
  return <tbody>{props.children}</tbody>;
};

const Td: React.FC<{ alignRight?: boolean }> = (props) => {
  return (
    <td className="relative py-6 border-2 border-white">
      <div
        className={classNames(
          "absolute inset-0 flex items-center text-sm text-gray-500 pr-4",
          props.alignRight && "justify-end"
        )}
      >
        {props.children}
      </div>
    </td>
  );
};

export const TdCell: React.FC<{
  shade: number;
  isLast: boolean;
  isPaydownMonth: boolean;
  tooltipData: TooltipData | null;
}> = (props) => {
  const style = props.isLast
    ? { background: stripesBackground(colormap(props.shade)) }
    : { background: colormap(props.shade) };

  return (
    <ContextualTooltip data={props.tooltipData}>
      <td
        style={style}
        className="relative py-6 border-2 border-white overflow-hidden"
      >
        <div
          className={classNames(
            "absolute inset-0 flex items-center text-sm justify-center",
            props.shade! < 0.5 ? "text-gray-800" : "text-white"
          )}
        >
          <span
            style={{ background: colormap(props.shade) }}
            className="px-1 rounded"
          >
            {props.children}
          </span>
        </div>
        {props.isPaydownMonth && (
          <Tooltip title="This month, LTV exceeded CAC for this cohort. Note: LTV is currently based on revenue and does not take gross margin into account.">
            <div className="absolute -top-3 -right-3 h-6 w-6 bg-orange-300 rotate-45" />
          </Tooltip>
        )}
      </td>
    </ContextualTooltip>
  );
};

export const stripesBackground = (color: string) => `
  repeating-linear-gradient(
    135deg,
    ${color},
    ${color} 7px,
    white 7px,
    white 8px
  )
`;

function calcMinMax(data: AnalyticsResult): { min: number; max: number } {
  let min = data[0].y;
  let max = data[0].y;

  for (let i = 0; i < data.length; i++) {
    min = Math.min(min, data[i].y);
    max = Math.max(max, data[i].y);
  }

  return { min, max };
}

function linearShade(min: number, max: number, value: number): number {
  if (min === max) {
    return 1;
  } else {
    return (value - min) / (max - min);
  }
}

export default CohortsTable;
