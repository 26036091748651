import React from "react";
import { AmplaLogo } from "@ampla/ui-components";
import { logout } from "ampla-core/api/rest";
import { Paper } from "@material-ui/core";
import SerifTypography from "ampla-core/components/Common/SerifTypography";

export type SessionComponentWrapperProps = {
  title: string;
  titleAction?: React.ReactElement | null;
};

const SessionComponentWrapper: React.FC<SessionComponentWrapperProps> = (
  props
) => {
  return (
    <div className="flex min-h-full items-center justify-center pt-12 pb-24 px-4 sm:px-6 lg:px-8">
      <Paper className="w-full max-w-lg space-y-8 py-8 px-4 sm:px-6 lg:px-8">
        <div>
          <AmplaLogo onClick={() => logout()} />
          <div className="mt-8">
            <SerifTypography variant="h3">{props.title}</SerifTypography>
            {props.titleAction}
          </div>
        </div>
        {props.children}
      </Paper>
    </div>
  );
};

export default SessionComponentWrapper;
