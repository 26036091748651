import React from "react";
import {
  ChartBarIcon,
  Cog6ToothIcon,
  SparklesIcon,
  CursorArrowRippleIcon,
  ClipboardDocumentCheckIcon,
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  PresentationChartBarIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import { AmplaLogo } from "@ampla/ui-components";
import { useAuthContext } from "ampla-core/contexts";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import routes from "routes/routes";
import Badge from "components/Badge";
import AppBrandSwitcher from "ampla-core/components/AppLayout/components/AppBrandSwitcher";
import { useEmbedded } from "contexts/EmbedContext";

interface SidebarSection {
  items: SidebarItemProps[];
  className?: string;
}

interface SidebarItemProps {
  name: string;
  path: string;
  icon?: React.FC<React.ComponentProps<"svg">>;
  sub?: boolean;
  newBadge?: boolean;
}

const SIDEBAR: SidebarSection[] = [
  {
    items: [
      {
        name: "Summary",
        path: routes.appSummary.path,
        icon: ChartBarIcon,
      },
      {
        name: "Financials",
        path: routes.appFinancials.path,
        icon: CurrencyDollarIcon,
      },
      {
        name: "Cashflow",
        path: routes.appCashflow.path,
        icon: ArrowsRightLeftIcon,
      },
      {
        name: "AP & AR",
        path: routes.appAccountsPayablesReceivables.path,
        icon: PresentationChartBarIcon,
      },
      {
        name: "Marketing",
        path: routes.appMarketing.path,
        icon: CursorArrowRippleIcon,
      },
      {
        name: "Orders",
        path: routes.appOrders.path,
        icon: ClipboardDocumentCheckIcon,
      },
      {
        name: "Customer Behavior",
        path: routes.appCustomerBehavior.path,
        icon: SparklesIcon,
      },
      {
        name: "Benchmarks",
        path: routes.appBenchmarks.path,
        icon: ArrowTrendingUpIcon,
      },
    ],
  },
  {
    className: "flex-1 flex flex-col justify-end",
    items: [
      {
        name: "Settings",
        path: routes.appSettings.path,
        icon: Cog6ToothIcon,
      },
    ],
  },
];

const AppLayout: React.FC = (props) => {
  const isEmbedded = useEmbedded();
  const auth = useAuthContext();
  const isStaff = auth.user?.is_staff;

  return (
    <main className="px-4 sm:px-6 lg:px-8">
      <div className="flex">
        <Sidebar />
        <div className="flex flex-1 flex-col ml-64">
          <main className="flex-1 pt-2">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              {props.children}
              {isEmbedded ? null : !isStaff ? null : <AppBrandSwitcher />}
            </div>
          </main>
        </div>
      </div>
    </main>
  );
};

const Sidebar: React.FC = () => {
  const auth = useAuthContext();

  return (
    <div className="fixed inset-y-0 flex w-64 flex-col">
      <div className="flex items-center justify-between py-6 mt-3">
        <div className="flex items-center gap-4">
          <div onClick={() => auth.signOut()}>
            <AmplaLogo />
          </div>
        </div>
      </div>
      <div className="flex min-h-0 flex-1 flex-col">
        <div className="flex flex-1 flex-col overflow-y-auto pb-10">
          <nav className="mt-5 flex-1 space-y-4 bg-white flex flex-col">
            {SIDEBAR.map((section, i) => (
              <div
                key={i}
                className={classNames("space-y-4", section.className)}
              >
                {section.items.map((item) => (
                  <SidebarItem key={item.path} {...item} />
                ))}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

const SidebarItem: React.FC<SidebarItemProps> = (props) => {
  const Icon = props.icon;
  const current = useLocation().pathname === props.path;

  return (
    <Link
      to={props.path}
      className={classNames(
        "group rounded-md flex items-center font-medium",
        props.sub ? "text-sm px-4 py-2" : "p-4",
        current
          ? "bg-teal-100 text-teal-500"
          : "text-gray-500 hover:bg-gray-50 hover:text-gray-700",
        Icon == null && "ml-9"
      )}
    >
      {Icon && (
        <Icon
          className={classNames(
            "mr-4 flex-shrink-0 h-5 w-5",
            current
              ? "text-teal-500"
              : "text-gray-500 group-hover:text-gray-700"
          )}
        />
      )}
      {props.name}
      {props.newBadge && <Badge className="right-3">New!</Badge>}
    </Link>
  );
};

export default AppLayout;
