const skipCacheValues = [undefined, "null", "[]", "{}"];

const useJsonCache = <V>(
  sessionKey: string,
  cacheFn: () => Promise<V>
): (() => Promise<V>) => {
  return () => cache(sessionKey, cacheFn);
};

export const cache = async <V>(
  sessionKey: string,
  cacheFn: () => Promise<V>
): Promise<V> => {
  let item = sessionStorage.getItem(sessionKey);

  if (item == null) {
    item = JSON.stringify(await cacheFn());
    if (skipCacheValues.indexOf(item) === -1) {
      sessionStorage.setItem(sessionKey, item);
    }
  }

  return JSON.parse(item);
};

export const clearCache = (notify: boolean = false) => {
  for (const item in sessionStorage) {
    if (item.indexOf("_cache") === 0) {
      sessionStorage.removeItem(item);
    }
  }
  if (notify) alert("Cleared local cache.");
};

export default useJsonCache;
