import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button } from "@ampla/ui-components";
import routes from "routes/routes";
import Header from "./Header";
import MutedText from "./Common/MutedText";

const IntegrationCta: React.FC<{ title: string; icons: JSX.Element[] }> = (
  props
) => {
  const navigate = useNavigate();

  return (
    <div className="col-span-12 my-6">
      <Paper className="grid grid-cols-5 gap-12 overflow-hidden">
        <div className="col-span-3 p-6 space-y-6">
          <Header variant="h4">{props.title}</Header>
          <MutedText>
            To get the most out of Ampla Insights, make sure you connect all of
            your accounting, banking, e-commerce, and advertising platforms.
            We'll aggregate all of your data to give you the best picture of
            your business, right in this dashboard.
          </MutedText>
          <div className="py-3">
            <Button onClick={() => navigate(routes.appSettings.path)}>
              <span className="flex items-center">
                Start connecting
                <ArrowRightIcon className="h-4 w-4 ml-2" />
              </span>
            </Button>
          </div>
        </div>
        <div className="col-span-2 relative flex items-center">
          <div className="absolute -inset-y-32 -right-16 left-0 rotate-12 bg-gray-50" />
          <div className="z-10 flex-1 flex justify-around">{props.icons}</div>
        </div>
      </Paper>
    </div>
  );
};

export default IntegrationCta;
