import { MessageType } from "contexts/EmbedContext";
import { useEffect } from "react";

export const useEventFromParent = (handler: () => void) => {
  useEffect(() => {
    const messageEvent = window.addEventListener("message", (event) => {
      if (event.data.type === MessageType.PARENT_APP_CLICK) {
        handler();
      }
    });
    return messageEvent;
  }, []);
};
