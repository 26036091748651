import React from "react";
import { Theme, withStyles, withTheme } from "@material-ui/core";
import { theme as amplaDesignSystem } from "@ampla/ui-components";
import { Color, LogoSize } from "ampla-core/images/enums";
import { LogoProps } from "ampla-core/images/types";
import LargeAmplaLogo from "./LargeAmplaLogo";
import SmallAmplaLogo from "./SmallAmplaLogo";
import SmallerAmplaLogo from "./SmallerAmplaLogo";

const logoSrcMap = {
  default: SmallAmplaLogo,
  lg: LargeAmplaLogo,
  md: LargeAmplaLogo,
  sm: SmallAmplaLogo,
  xs: SmallAmplaLogo,
  xxs: SmallerAmplaLogo,
};

const Logo = ({
  size = LogoSize.Xs,
  color = Color.Teal,
  classes = {},
  className,
}: LogoProps<LogoSize, Color.Primary | Color.Black | Color.White | Color.Teal> &
  React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const sizeStyles = classes[`size_${size}`];
  const LogoSVG = logoSrcMap[size];
  const logoColor = {
    teal: amplaDesignSystem.palette.teal[500],
    white: amplaDesignSystem.palette.white, // confirm looks okay in components/Common/Header
    black: amplaDesignSystem.palette.black,
    primary: amplaDesignSystem.palette.mint[500],
  }[color];

  return (
    <div className={[className, sizeStyles, classes.logo].join(" ")}>
      <LogoSVG color={logoColor as string} />
    </div>
  );
};

const styles = (theme: Theme) => ({
  size_xxs: {
    width: "100%",
  },
  size_xs: {
    padding: `${theme.spacing(1)}px 0`,
    width: "30px",
  },
  size_md: {
    padding: theme.spacing(1),
    width: "200px",
  },
  size_lg: {
    width: "100%",
  },
  logo: {
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export default withTheme(withStyles(styles)(Logo));
