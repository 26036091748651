import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "ampla-core/contexts";
import { magic_auth_login } from "ampla-core/api/auth/auth";
import { safeSearchParams } from "util/params";
import { clearCache } from "hooks/useJsonCache";
import routes from "routes/routes";
import PageLoading from "routes/components/PageLoading";
import {
  SESSION_INTEGRATION_ID_KEY,
  SESSION_RETURN_URL_KEY,
} from "ampla-core/constants/storageKeys";
import { useMutation } from "react-query";
import useSession from "hooks/useSession";

const Connect: React.FC = () => {
  const auth = useAuthContext();
  const [params] = useSearchParams();
  const [fallbackRedirect, setFallbackRedirect] = useState(false);
  const token = params.get("token");
  const returnUrl = params.get("return_url");
  const integrationId = params.get("integration_id");

  const [, { set: setReturnUrl }] = useSession(SESSION_RETURN_URL_KEY);
  const [, { set: setIntegrationId }] = useSession(SESSION_INTEGRATION_ID_KEY);

  const { mutate: mutateMagicAuth } = useMutation(magic_auth_login, {
    onMutate: () => clearCache(),
    onSuccess: (response) => {
      auth.setToken(response.token);
    },
  });

  useEffect(() => {
    if (integrationId) setIntegrationId(integrationId);
    if (returnUrl) setReturnUrl(returnUrl);

    if (token && !auth.user && !auth.isLoading) mutateMagicAuth(token);

    if (!auth.isLoading && auth.user) {
      setFallbackRedirect(true);
    }
  }, [token, auth.user, auth.isLoading]);

  if (fallbackRedirect) {
    return <Navigate to={routes.entry.path + safeSearchParams()} />;
  }

  return <PageLoading />;
};

export default Connect;
