/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from "react";

import PropTypes from "prop-types";
import { noop } from "lodash";
import { v4 as uuidv4 } from "uuid";

const OverlaysContext = createContext({
  modals: [],
});

export const OverlaysContextProvider = ({ children, ...props }) => {
  const [modals, setModals] = useState([]);

  const openModal = (Component, props) => {
    const id = uuidv4();
    setModals([...modals, { id, Component, props }]);
    return id;
  };

  const closeModal = (id) =>
    setModals((currentModals) =>
      currentModals.filter((modal) => modal.id !== id)
    );

  const handleModalClose =
    (id, onClose) =>
    (...params) => {
      closeModal(id);
      onClose(...params);
    };

  return (
    <OverlaysContext.Provider
      value={{
        modals,
        openModal,
        closeModal,
      }}
      {...props}
    >
      {modals.map(
        ({ id, Component, props: { onClose = noop, ...otherProps } = {} }) => (
          <Component
            key={id}
            {...otherProps}
            onClose={handleModalClose(id, onClose)}
            open
          />
        )
      )}
      {children}
    </OverlaysContext.Provider>
  );
};

OverlaysContextProvider.propTypes = {
  children: PropTypes.node,
};

OverlaysContextProvider.defaultProps = {
  children: null,
};

export const useModals = () => {
  const { openModal, closeModal } = useContext(OverlaysContext);
  return {
    openModal,
    closeModal,
  };
};
