import React from "react";

const SmallAmplaLogo = ({
  color,
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="126px"
    height="43px"
    viewBox="0 0 126 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="small" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <path
          d="M19.0876162,2 L11.9241375,2 L0,33 L5.93964064,33 L23.5482613,20.1496534 L21.3794095,24.7978747 L24.1672657,33 L31,33 L19.0876162,2 Z M8.64483755,25.2925844 L15.4964777,7.21760723 L19.9311272,20.4926429 L8.64483755,25.2925844 Z"
          id="Shape"
        />
        <path
          d="M56.717331,14.2694063 C55.7826884,14.2483755 54.8688155,14.5545879 54.1242968,15.1381641 C53.4532713,15.6540784 52.8623428,16.2726142 52.3718551,16.9725202 C52.4655324,17.5532886 52.5436589,18.1728153 52.6061282,18.8310452 C52.6685975,19.4892751 52.6998055,20.1410637 52.6998055,20.7864109 L52.6998055,33 L46.2996601,33 L46.2996601,20.7380181 C46.2996601,18.5503231 46.0037165,16.925724 45.4117762,15.8641659 C44.819889,14.8026077 43.8226706,14.2710029 42.4202273,14.2694063 C41.5701019,14.2365939 40.7306277,14.4730524 40.0142813,14.9470153 C39.3929969,15.3788617 38.8478686,15.9174034 38.4025419,16.5393525 L38.4025419,32.9951552 L32,32.9951552 L32,9.49239468 L38.1682688,9.49239468 L38.1682688,12.6770691 C38.8453123,11.491033 39.8349684,10.5283525 41.0216184,9.90133846 C42.1971379,9.30014486 43.493442,8.9942078 44.8050306,9.00835677 C46.3933906,9.00835677 47.7021566,9.37865238 48.7313819,10.1191335 C49.7760515,10.8785533 50.6259639,11.8893513 51.2075726,13.0642664 C52.2352002,11.5832491 53.3877664,10.5345737 54.6653777,9.9182952 C56.0159496,9.29161144 57.4836848,8.9785173 58.9642014,9.00114465 C60.4292737,9.00114465 61.6674756,9.29876851 62.6793394,9.89407128 C63.6938661,10.4963109 64.5438317,11.3537825 65.15095,12.3866573 C65.8251709,13.5405967 66.3007469,14.8064065 66.5569082,16.1279313 C66.8615325,17.6604795 67.0101167,19.2218761 66.9994655,20.7864109 L66.9994655,33 L60.5970301,33 L60.5970301,20.7380181 C60.5970301,18.5503231 60.3009268,16.925724 59.7092527,15.8641659 C59.1170461,14.8026077 58.1200939,14.2710029 56.717331,14.2694063 Z"
          id="Path"
        />
        <path
          d="M70,11.4570805 L76.0747253,11.4570805 L76.0747253,14.2766785 C76.808301,13.2832877 77.7622664,12.4665079 78.864744,11.888202 C79.9772633,11.2966101 81.337655,11.0007882 82.9448622,11.0007882 C84.2328478,10.9830907 85.5076205,11.2636545 86.6645348,11.8197997 C87.7786396,12.3667588 88.7532171,13.1517248 89.5174465,14.1169865 C90.3440404,15.1801809 90.9650429,16.3833457 91.3492716,17.6666419 C91.7985075,19.1661995 92.0173117,20.7232607 91.9977568,22.2861865 C92.029996,24.0208446 91.7144739,25.7448117 91.0681029,27.359688 C90.5031228,28.7523345 89.6506317,30.0152345 88.5640096,31.0690352 C87.5127977,32.0693285 86.255466,32.8370642 84.8765763,33.3206033 C83.4258088,33.8281195 81.895236,34.082163 80.3551499,34.0711605 C79.6099469,34.0782187 78.865801,34.0247632 78.1301113,33.9114686 C77.535006,33.8177398 76.9467713,33.6889794 76.3675213,33.525914 L76.3675213,40.1119478 L70,43 L70,11.4570805 Z M80.8059712,15.8690307 C79.8752603,15.8479599 78.9556482,16.0682694 78.139096,16.5077466 C77.4620712,16.8677151 76.8569241,17.3453897 76.35378,17.9175715 L76.35378,28.883586 C76.8040729,29.0485197 77.2723351,29.1632674 77.7485251,29.2257533 C78.3018779,29.3032898 78.8594589,29.3413834 79.4180968,29.3398723 C81.5876416,29.3398723 83.1647235,28.6874093 84.1493427,27.3825233 C85.1334334,26.0776373 85.6275928,24.3940473 85.6323495,22.3318053 C85.6323495,20.4809462 85.2386075,18.9411153 84.4511235,17.7122607 C83.6641681,16.4834061 82.4485888,15.8690307 80.8059712,15.8690307 Z"
          id="Shape"
        />
        <path
          d="M100.787049,26.8664593 C100.787049,27.5956408 100.943482,28.1437467 101.258606,28.505952 C101.574295,28.8682116 102.108538,29.054058 102.868676,29.054058 C103.166858,29.054058 103.479723,29.0374142 103.810096,29.0064036 C104.126914,28.9774532 104.440909,28.9296905 104.751515,28.8634407 L105,33.4269783 C104.317231,33.6088128 103.621473,33.7433726 102.918373,33.8296816 C102.114185,33.939845 101.30322,33.9955772 100.491126,33.9964989 C99.4378886,34.0283768 98.3897335,33.8422594 97.418383,33.4507783 C96.6362199,33.1196378 95.9478034,32.6138732 95.4107369,31.9756632 C94.8996484,31.3593557 94.5286151,30.647577 94.3202265,29.8857586 C94.1061905,29.1024713 93.9983254,28.2958719 94,27.4860196 L94,3.01693342 L100.787049,0 L100.787049,26.8664593 Z"
          id="Path"
        />
        <path
          d="M123.386235,11.5076354 C121.645512,9.83777378 119.253225,9.00200658 116.210446,9.00044168 C114.640792,8.98975716 113.075965,9.17295897 111.551358,9.54599994 C110.166717,9.88455905 108.836238,10.4179758 107.600143,11.1304932 L108.86895,15.6727649 C109.767198,15.2001097 110.717463,14.8347313 111.699904,14.5840767 C112.805687,14.2944074 113.944718,14.1508678 115.087503,14.1570735 C116.625516,14.1570735 117.754358,14.543713 118.475101,15.3169919 C119.195308,16.0902708 119.556216,17.2177579 119.557824,18.6993991 L119.557824,19.5011701 C118.741626,19.5343569 117.83319,19.5652233 116.828225,19.5960358 C115.823261,19.6268482 114.85369,19.6766553 113.912542,19.7383342 C111.463946,19.8964975 109.534458,20.528989 108.123539,21.6359164 C106.713157,22.7428439 106.005285,24.351836 106.000458,26.4628929 C105.990269,27.3549427 106.150077,28.2407869 106.472373,29.0720212 C106.772146,29.8385549 107.220466,30.5375818 107.791054,31.1285223 C108.376657,31.7279888 109.081848,32.1960033 109.859972,32.5019153 C110.729797,32.8420933 111.656466,33.0104015 112.589035,32.9976664 C113.73611,33.0229747 114.878895,32.8422551 115.96269,32.4639798 C117.951168,31.7096956 119.50098,30.2864955 121.173597,29.0340858 L122.753976,27.8481036 L120.61427,32.5161613 L126,32.5161613 L126,18.9366172 C125.998391,15.6553891 125.126957,13.1790618 123.386235,11.5076354 Z M119.550317,26.9254033 C118.920739,27.4182404 118.224128,27.817615 117.481935,28.1113854 C116.672172,28.4319211 115.808782,28.5929445 114.938957,28.5857675 C114.243955,28.6214905 113.556997,28.4218842 112.988017,28.0188941 C112.498942,27.6393777 112.25494,27.0716949 112.256541,26.3157918 C112.256541,25.4650771 112.539162,24.85784 113.103315,24.4941345 C113.797781,24.0851546 114.565715,23.8198223 115.363144,23.7137865 C115.991113,23.6188669 116.679679,23.5406217 117.434743,23.4765685 C118.189271,23.4125153 118.892317,23.3508365 119.557824,23.2868372 L119.550317,26.9254033 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SmallAmplaLogo;
