import React from "react";
import { Color } from "ampla-core/images/enums";

const IconCircle: React.FC<{ background?: Color }> = (props) => {
  return (
    <div
      style={{ backgroundColor: props.background }}
      className="z-10 w-16 h-16 flex items-center justify-center rounded-full"
    >
      {props.children}
    </div>
  );
};

export default IconCircle;
