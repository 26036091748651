import React, { useState } from "react";
import { fold3 } from "@devexperts/remote-data-ts";
import {
  AnalyticsData,
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import {
  Timeframe,
  defaultDateRange,
} from "ampla-core/contexts/PageControlsProvider";
import { useEmbedded } from "contexts/EmbedContext";
import Header from "components/Header";
import SelectMenu from "components/SelectMenu";
import useChartData from "hooks/useChartData";
import CohortsTable, { TdCell } from "./components/CohortsTable";
import ProgressPlaceholder from "components/ProgressPlaceholder";
import { isMissingData } from "util/charts";
import EmptyPlaceholder from "components/EmptyPlaceholder";
import MutedText from "components/Common/MutedText";
import EcommerceIntegrationsCta from "components/EcommerceIntegrationsCta";
import { useEventFromParent } from "hooks/useEventFromParent";

const Cohorts: React.FC = () => {
  const isEmbedded = useEmbedded();
  const [open, setOpen] = useState(false);
  useEventFromParent(() => setOpen(false));

  const analyticsData = useChartData(
    {
      timeframe: Timeframe.Cohorts,
      dateRange: defaultDateRange(Timeframe.Cohorts),
    },
    [
      AnalyticsGraph.InsightsCohortRetention,
      AnalyticsGraph.InsightsCohortSpendPerUser,
      AnalyticsGraph.InsightsCohortTotalSpend,
    ]
  );

  const [selectedChart, setSelectedChart] = useState(
    AnalyticsGraph.InsightsCohortSpendPerUser
  );

  const selectMenu = (
    <SelectMenu
      open={open}
      setOpen={setOpen}
      tooltip="Metric"
      value={selectedChart}
      options={[
        [AnalyticsGraph.InsightsCohortSpendPerUser, "Spend per customer"],
        [AnalyticsGraph.InsightsCohortTotalSpend, "Spend for all customers"],
        [AnalyticsGraph.InsightsCohortRetention, "Customer retention"],
      ]}
      onChange={(chart) => setSelectedChart(chart)}
    />
  );

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Customer Behavior</Header>

          <div className="flex items-center space-x-6">{selectMenu}</div>
        </div>
      ) : null}

      {fold3(
        () => <ProgressPlaceholder />,
        () => <>Error</>,
        (data: AnalyticsData) => {
          if (isMissingData(data)) {
            if (isEmbedded) {
              return <EcommerceIntegrationsCta />;
            } else {
              return <EmptyPlaceholder />;
            }
          } else {
            return (
              <>
                {isEmbedded ? (
                  <div className="absolute top-3 right-0">{selectMenu}</div>
                ) : null}
                <SuccessComponent data={data} selectedChart={selectedChart} />
                <HowToRead />
              </>
            );
          }
        }
      )(analyticsData)}
    </>
  );
};

interface SuccessComponentProps {
  data: AnalyticsData;
  selectedChart: AnalyticsGraph;
}

const SuccessComponent: React.FC<SuccessComponentProps> = (props) => {
  const titleMap: Partial<Record<AnalyticsGraph, string>> = {
    [AnalyticsGraph.InsightsCohortSpendPerUser]: "Spend per customer",
    [AnalyticsGraph.InsightsCohortTotalSpend]: "Spend for all customers",
    [AnalyticsGraph.InsightsCohortRetention]: "Customer retention",
  };

  return (
    <div className="col-span-12">
      <Header variant="h4" className="mb-6">
        {titleMap[props.selectedChart]}
      </Header>

      {props.selectedChart === AnalyticsGraph.InsightsCohortSpendPerUser && (
        <CohortsTable
          g="signup_month"
          x="purchase_month"
          y="spend_per_active_user"
          type={AnalyticsGraphType.Money}
          data={props.data[AnalyticsGraph.InsightsCohortSpendPerUser]!}
        />
      )}
      {props.selectedChart === AnalyticsGraph.InsightsCohortRetention && (
        <CohortsTable
          g="signup_month"
          x="purchase_month"
          y="percent_of_cohort_active"
          type={AnalyticsGraphType.Percentage}
          data={props.data[AnalyticsGraph.InsightsCohortRetention]!}
        />
      )}
      {props.selectedChart === AnalyticsGraph.InsightsCohortTotalSpend && (
        <CohortsTable
          g="signup_month"
          x="purchase_month"
          y="total_amount_spent"
          type={AnalyticsGraphType.Money}
          data={props.data[AnalyticsGraph.InsightsCohortTotalSpend]!}
        />
      )}
    </div>
  );
};

const HowToRead: React.FC = () => {
  return (
    <div className="col-span-12">
      <Header variant="h4" className="mb-6">
        How to read this chart
      </Header>

      <div className="bg-gray-50 p-4">
        <table>
          <tbody>
            <HowToRow description="Batch of customers who placed their first order with you in the same month.">
              <MutedText>Cohort</MutedText>
            </HowToRow>
            <HowToRow description="Reference to mark changes in behavior between the month a cohort was acquired and now.">
              <MutedText>Months since first order</MutedText>
            </HowToRow>
            <HowToRow description="Indicator that LTV has surpassed CAC for a cohort during a particular month.">
              <table className="w-20">
                <tbody>
                  <tr>
                    <TdCell
                      shade={1}
                      isLast={false}
                      isPaydownMonth={true}
                      tooltipData={null}
                    />
                  </tr>
                </tbody>
              </table>
            </HowToRow>
            <HowToRow description="Indicator that a month is in progress.">
              <table className="w-20">
                <tbody>
                  <tr>
                    <TdCell
                      shade={1}
                      isLast={true}
                      isPaydownMonth={false}
                      tooltipData={null}
                    />
                  </tr>
                </tbody>
              </table>
            </HowToRow>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const HowToRow: React.FC<{ description: string }> = (props) => {
  return (
    <tr>
      <td className="py-4 px-6">{props.children}</td>
      <td className="py-4 px-6">
        <MutedText>=</MutedText>
      </td>
      <td className="py-4 px-6">
        <MutedText>{props.description}</MutedText>
      </td>
    </tr>
  );
};

export default Cohorts;
