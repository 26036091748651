export const isNonEmptyArray = (input: unknown) =>
  !!input && Array.isArray(input) && !!input.length;

export const isEmptyArray = (input: unknown) =>
  Array.isArray(input) && !input.length;

export const mapToObject = (
  l: unknown[],
  fn: (item: unknown) => [string, string]
) =>
  l.reduce((accumulator: object, item) => {
    const keyValue = fn(item);
    const key = keyValue[0];
    const val = keyValue[1];
    return { ...accumulator, [key]: val };
  }, {});
