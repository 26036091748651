import { Event, publish } from "ampla-core/pubsub";
import { User } from "ampla-core/types";
import {
  IntegrationStatus,
  IntegrationType,
} from "ampla-core/api/integration/enums";
import { clearCache } from "hooks/useJsonCache";
import { get, patch, post } from "../rest";

export interface RegistrationData {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: {
    country_code: string;
    number: string;
  };
}

export const register = async (data: RegistrationData): Promise<User> => {
  const user = await post("/auth/register/", data);

  if (user.token) {
    clearCache();
    publish(Event.SIGN_IN, user.token);

    delete user.token;
  }

  return user;
};

export interface SignInData {
  username: string;
  password: string;
}

export const sign_in = async (data: SignInData): Promise<User> => {
  const response = await post("/auth/token-auth/", data);

  if (response && response.token) {
    clearCache();
    publish(Event.SIGN_IN, response.token);

    return get_current_user();
  }

  throw response;
};

export const magic_auth_login = (token: string): Promise<{ token: string }> =>
  post("/auth/magic-auth/login/", { token });

export const get_current_user = async (): Promise<User> => {
  return get("/auth/current_user/");
};

export const shopify_connection_link_url = async (): Promise<{
  connection_link_url: string;
}> => {
  return get("/codat/connection_link_url/", { platform_key: "fztf" });
};

export const get_shopify_store_name = async (store_name: string) => {
  return post("/codat/get_shopify_store_name/", { store_name });
};

export const store_integrations = async (company_id: string) => {
  return post("/codat/store_integrations/", { company_id });
};

export const remove_connection = async (
  company_id: string,
  connection_id: string
) => {
  return patch("/codat/remove_connection/", { company_id, connection_id });
};

export const disconnect_shopify = async () => {
  const user = await get_current_user();

  const integrations = (user.active_entity_integrations ?? [])?.filter(
    (integration) =>
      integration?.type == IntegrationType.CodatInsightsEcommerce &&
      integration?.status == IntegrationStatus.Ok &&
      integration?.data?.platform_name === "Shopify"
  );

  for (const integration of integrations) {
    if (integration.data) {
      try {
        await remove_connection(
          integration.data?.codat_company_id,
          integration.data?.codat_connection_id
        );
      } catch (e) {
        console.error(e);
      }
    }
  }
};

export interface InsightsUserData {
  entity_id: string;
  contact_email: string;
  company_legal_name: string;
  company_website: string;
}

export const get_shopify_link_url = (
  shop: string
): Promise<{ linkUrl: string }> => {
  return get("/insights/shopify_link_url/", { shop });
};

export const continue_shopify_link = (
  entity_id: number,
  company_id: string,
  shop: string,
  connection_id: string,
  integration_id?: string | unknown
): Promise<{ success: boolean }> => {
  return post("/insights/setup/setup_insights_entity/", {
    entity_id,
    company_id,
    shop,
    connection_id,
    integration_id,
  });
};

export const switch_active_entity = async (data: { entity_id: number }) => {
  const response = await get("/auth/switch_active_entity/", data);
  if (response.active_entity === data.entity_id) {
    publish(Event.REFETCH_USER, null);
  }
  return response;
};
