import { crud } from "ampla-core/api/rest";
import { Integration } from "ampla-core/api/integration/types";
import { APIList, APIListParams } from "ampla-core/types";

const FivetranAPI = {
  get_connect_card_link: async (data: any) =>
    crud("list", "/fivetran/connect_card_link/", data),

  get_available_integrations: async () =>
    crud("list", "/fivetran/enabled_integrations/"),

  complete_connection: async (data: any) =>
    crud("update", `/fivetran/complete_integration_connection/`, data),

  remove_integration: async (data: any) => {
    const response = await crud(
      "update",
      `/fivetran/remove_integration/`,
      data
    );
    return response;
  },

  update: async (id: string, data: Integration) =>
    crud("update", `/fivetran/${id}/`, data),

  detail: async (id: string): Promise<Integration> =>
    crud("detail", `/fivetran/${id}/`),

  list: async (
    integrationType?: string,
    data?: APIListParams
  ): Promise<APIList<Integration>> =>
    crud("list", "/fivetran/", {
      ...(integrationType ? { type: integrationType } : {}),
      ...data,
    }),
};

export default FivetranAPI;
