import React, { FormEvent, useState } from "react";
import classNames from "classnames";
import { useMutation } from "react-query";

export interface SimpleFormProps {
  form: FormState<any>;
  children: React.ReactNode;
  actions?: React.ReactNode[];
  grid?: boolean;
  className?: string;
}

const SimpleForm: React.FC<SimpleFormProps> = (props) => {
  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    props.form.submit();
  }

  const firstErrorKey = Object.keys(props.form.errors ?? {}).find(
    (key) => key !== "code"
  );
  const firstError = firstErrorKey ? props.form.errors[firstErrorKey] : {};

  const errors = Array.isArray(firstError)
    ? firstError
    : firstError?.["non_field_errors"];

  return (
    <form onSubmit={handleSubmit} className={props.className}>
      <div
        className={classNames(
          "space-y-8 bg-white mt-10",
          props.grid && "grid grid-cols-4 gap-x-6"
        )}
      >
        {props.children}
        <div
          className={classNames(
            "flex flex-col space-y-2",
            props.grid && "col-span-4"
          )}
        >
          {(Array.isArray(errors) ? errors : null)?.map((error) => (
            <span key={error} className="text-sm text-red-500">
              {error}
            </span>
          ))}
        </div>
        {props.actions?.map((action, i) => (
          <div
            key={i}
            className="col-span-4 pt-4 flex items-center justify-between gap-6"
          >
            {action}
          </div>
        ))}
      </div>
    </form>
  );
};

export interface FormState<T> {
  state: T;
  isLoading: boolean;
  isSuccess: boolean;
  errors: { [error: string]: [string] | { [error: string]: [string] } };
  update: (name: string, value: any) => void;
  submit: () => void;
  setErrors: (errors: any) => void;
}

export function useSimpleForm<T>(
  action: (state: T) => Promise<any>,
  validate: (state: T) => boolean,
  initialState: Partial<T> = {}
): FormState<T> {
  const [state, setState] = useState(initialState as T);
  const [errors, setErrors] = useState(null as any);

  function update(key: string, value: any) {
    setState({ ...state, [key]: value });
  }

  const { mutate, isLoading, isSuccess } = useMutation(action, {
    onError: (errorResponse) => {
      setErrors(
        Array.isArray(errorResponse)
          ? { non_field_errors: errorResponse }
          : errorResponse
      );
    },
  });

  function submit() {
    setErrors(null);
    if (validate(state)) mutate(state);
  }

  return {
    state,
    isLoading,
    isSuccess,
    errors,
    update,
    submit,
    setErrors,
  };
}

export default SimpleForm;
