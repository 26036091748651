import React from "react";
import { RemoteData, fold3 } from "@devexperts/remote-data-ts";
import moment from "moment";
import {
  AnalyticsData,
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import { usePageControls } from "ampla-core/contexts";
import {
  avgBy,
  formatChartValue,
  isMissingData,
  isMissingRecentEcommerceData,
  percentChange,
  sumBy,
} from "util/charts";
import AnalyticsLineGraph, {
  omitNRecentMonths,
  padAnalyticsResult,
  SeriesBreakout,
} from "./AnalyticsLineGraph";
import SparkLine from "./SparkLine";
import StatDetail, { timeLagWarning } from "./StatDetail";
import ProgressPlaceholder from "./ProgressPlaceholder";
import ChartTitle from "pages/Summary/components/ChartTitle";
import EmptyPlaceholder from "./EmptyPlaceholder";
import MutedText from "./Common/MutedText";
import { Paper } from "@material-ui/core";
import { Typography } from "@ampla/ui-components";
import PageControls from "./PageControls";
import { Timeframe } from "ampla-core/contexts/PageControlsProvider";
import { useEmbedded } from "contexts/EmbedContext";

export enum AggregateType {
  Sum = "sum",
  Average = "average",
}

export interface ChartProps<K> {
  key: K;
  title: string;
  timeframe: string;
  chart: AnalyticsGraph;
  chartType: AnalyticsGraphType;
  chartDescription: string;
  aggregateType: AggregateType;
  reportX: string;
  reportY: string;
  seriesBreakout?: SeriesBreakout;
  timeLagMonths?: number;
  unit?: string;
  renderHelpText?: (point: { x: string; y: string }) => string;
}

export interface AnalyticsLineStatsDashboardProps<K> {
  data: RemoteData<never, AnalyticsData>;
  placeholderComponent?: JSX.Element;
  chartProps: ChartProps<K>[];
  selectedChart: K;
  onSelectChart: (key: K) => void;
  pageControlTimeframe?: Timeframe;
}

const AnalyticsLineStatsDashboard = <K extends string>(
  props: AnalyticsLineStatsDashboardProps<K>
): React.ReactElement => {
  const isEmbedded = useEmbedded();
  const pageControlAttributes = { only: props.pageControlTimeframe };
  const externalPageControls = usePageControls();

  const renderRemote = fold3<never, AnalyticsData, React.ReactElement>(
    () => <ProgressPlaceholder />,
    () => <>Error</>,
    (data: AnalyticsData) => {
      if (isMissingRecentEcommerceData(data, externalPageControls?.dateRange) || isMissingData(data)) {
        return props.placeholderComponent || <EmptyPlaceholder />;
      } else {
        return (
          <>
            {isEmbedded ? (
              <div className="absolute top-3 right-0">
                <PageControls {...pageControlAttributes} />
              </div>
            ) : null}
            <SuccessComponent
              data={data}
              chartProps={props.chartProps}
              selectedChart={props.selectedChart}
              onSelectChart={props.onSelectChart}
            />
          </>
        );
      }
    }
  );

  return renderRemote(props.data);
};

interface SuccessComponentProps<K> {
  data: AnalyticsData;
  chartProps: ChartProps<K>[];
  selectedChart: K;
  onSelectChart: (key: K) => void;
}

const SuccessComponent = <K extends string>(
  props: SuccessComponentProps<K>
): React.ReactElement => {
  const pageControls = usePageControls();

  function getData(chart: ChartProps<K>) {
    let data = props.data[chart.chart];

    if (data == null) return [];
    data = padAnalyticsResult(
      data,
      pageControls.dateRange,
      pageControls.timeframe,
      chart.reportX,
      chart.reportY
    );

    return chart.timeLagMonths
      ? omitNRecentMonths(data, chart.reportX, chart.timeLagMonths)
      : data;
  }

  return (
    <>
      <div className="col-span-12">
        {props.chartProps
          .filter((chart) => chart.key === props.selectedChart)
          .map((chart) => ({ chart, data: getData(chart) }))
          .map(({ chart, data }) => (
            <React.Fragment key={chart.key}>
              <div className="flex justify-between">
                <ChartTitle
                  title={chart.title}
                  value={(chart.chartType === AnalyticsGraphType.Money
                    ? (v: number) => v
                    : (v: number) =>
                        formatChartValue(chart.chartType, v) as string)(
                    chart.aggregateType === AggregateType.Sum
                      ? sumBy(data, chart.reportY)
                      : avgBy(data, chart.reportY)
                  )}
                  isMoney={chart.chartType === AnalyticsGraphType.Money}
                />
                <div className="w-96"></div>
              </div>
              <div className="h-64 mt-12">
                <AnalyticsLineGraph
                  data={data}
                  type={chart.chartType}
                  x={chart.reportX}
                  y={chart.reportY}
                  seriesBreakout={chart.seriesBreakout}
                  unit={chart.unit}
                  renderHelpText={chart.renderHelpText}
                  timeframe={pageControls.timeframe}
                />
              </div>
              <div className="h-8">
                <Typography variant="body3" className="text-gray-500">
                  {chart.chartDescription}
                  {chart.timeLagMonths && timeLagWarning(chart.timeLagMonths)}
                </Typography>
              </div>
            </React.Fragment>
          ))}
      </div>

      {props.chartProps.map((chart) => {
        const chartData = getData(chart);
        const thisTimeframeData = chartData[chartData.length - 1]
        const thisTimeframeAggregate = thisTimeframeData?.[chart.reportY];

        const lastTimeframeAggregate =
          chartData[chartData.length - 2]?.[chart.reportY];
        const timeframeText = (pageControls.timeframe === Timeframe.Monthly) ? `month of ${moment(thisTimeframeData.report_month).format("MMM YYYY")}` : (pageControls.timeframe === Timeframe.Weekly) ? `week of ${moment(thisTimeframeData.report_week).format("MMM D")}` : (pageControls.timeframe === Timeframe.Daily) ? `${moment(thisTimeframeData.report_date).format("MMM D")}` : chart.timeframe;
        return (
          <StatDetail
            key={chart.key}
            title={chart.title}
            timeframe={timeframeText}
            value={
              thisTimeframeAggregate != null
                ? formatChartValue(chart.chartType, thisTimeframeAggregate)!
                : "N/A"
            }
            percentChange={
              thisTimeframeAggregate != null && lastTimeframeAggregate != null
                ? percentChange(lastTimeframeAggregate, thisTimeframeAggregate)
                : 0
            }
            timeLagMonths={chart.timeLagMonths}
            selected={props.selectedChart === chart.key}
            onSelect={() => props.onSelectChart(chart.key)}
            className="col-span-4"
          >
            <SparkLine
              data={chartData}
              x={chart.reportX}
              y={chart.reportY}
              seriesBreakout={chart.seriesBreakout}
            />
          </StatDetail>
        );
      })}
    </>
  );
};

export default AnalyticsLineStatsDashboard;
