export const AGREEMENT_DETAIL_QUERY_KEY = "agreement";
export const ADDRESSES_QUERY_KEY = "addresses";
export const AGREEMENT_CONSENTS_QUERY_KEY = "agreement-consents";
export const MARKET_CATEGORY_QUERY_KEY = "market-categories";
export const BANCO_RECURRING_PAYMENT_KEY = "banco-recurring-payment";
export const BANCO_CARD_LIST_QUERY_KEY = "banco-card-list";
export const BANCO_CARD_AUTHORIZATIONS_LIST_QUERY_KEY =
  "banco-card-authorizations-list";
export const BANCO_CARD_DETAIL_QUERY_KEY = "banco-card-detail";
export const BANCO_COUNTERPARTY_LIST_QUERY_KEY = "banco-counterparty-list";
export const BANCO_COUNTERPARTY_PREVIEW_QUERY_KEY =
  "banco-counterparty-preview";
export const BANCO_COUNTERPARTY_DETAIL_QUERY_KEY = "banco-counterparty-detail";
export const BANCO_COUNTERPARTY_DELETE_QUERY_KEY = "delete-banco-counterparty";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_LIST_QUERY_KEY =
  "banco-counterparty-payment-method-list";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_DETAIL_QUERY_KEY =
  "banco-counterparty-payment-method-detail";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_ACCOUNT_NUMBER_QUERY_KEY =
  "banco-counterparty-payment-method-account-number";
export const BANCO_COUNTERPARTY_CONTACT_LIST_QUERY_KEY =
  "banco-counterparty-contact-list";
export const BANCO_ENTITY_DASHBOARD_QUERY_KEY = "banco-entity-dashboard";
export const BANCO_TRANSACTION_BUYERPAYMENT_QUERY_KEY =
  "banco-transaction-buyerpayment";
export const BANCO_TRANSACTION_ADVANCE_QUERY_KEY = "banco-transaction-advance";
export const BANCO_MONEY_IN_MONEY_OUT_QUERY_KEY = "banco-money-in-money-out";
export const BANCO_ACCOUNT_LIST_QUERY_KEY = "banco-account-list";
export const BANCO_ACCOUNT_CHARGE_CARDS_QUERY_KEY =
  "banco-account-charge-cards";
export const BANCO_DAILY_BALANCES_QUERY_KEY = "banco-daily-balances";
export const BANCO_INSTRUCTION_LIST_QUERY_KEY = "banco-instructions";
export const BANCO_AUTHORIZATIONS_LIST_QUERY_KEY = "banco-authorizations";
export const BANCO_INSTRUCTION_DETAIL_QUERY_KEY = "banco-instruction-detail";
export const BANCO_TRANSACTION_DETAIL_QUERY_KEY = "banco-transaction-detail";
export const BANCO_MONTHLY_STATEMENTS_LIST_QUERY_KEY =
  "banco-monthly-statements-list";
export const BANCO_DOWNLOAD_STATEMENT_PDF_QUERY_KEY =
  "banco-download-statement-pdf";
export const BANCO_SIGNED_BANK_VERIFICATION_LETTER =
  "signed-bank-verification-letter";
export const BANCO_USER_TOKEN_VALID_QUERY_KEY = "user-token-valid";
export const BANCO_DASHBOARD_CALC_DATA_QUERY_KEY = "calc-data";
export const BANCO_ACCOUNT_NUMBER_QUERY_KEY = "account_number";
export const BANCO_INSTRUCTION_NEXT_VALID_SCHEDULE_DATA_QUERY_KEY =
  "banco-instruction-next-valid-schedule-date";
export const CODAT_SHOPIFY_INTEGRATION_QUERY_KEY = "codat-shopify-integration";
export const CODAT_ENABLED_INTEGRATIONS_BY_TYPE_QUERY_KEY =
  "codat-enabled-integrations-by-type";
export const FUNDING_APPLICATION_DETAIL_QUERY_KEY =
  "funding-application-detail";
export const PRODUCTION_RUN_TRANSACTION_DETAIL_QUERY_KEY =
  "production-run-transaction-detail";
export const ENTITY_DETAIL_QUERY_KEY = "entity-detail";
export const CODAT_GET_COMPANY_LINK_URL_QUERY_KEY =
  "codat-get-company-link-url";
export const USER_MANAGEMENT_LIST_QUERY_KEY = "user-management-list";
export const CARD_LIST_QUERY_KEY = "card-list";
export const CARD_DETAIL_QUERY_KEY = "card-detail";
export const CARD_PAYMENT_DETAIL_QUERY_KEY = "card-payment-detail";
export const CARD_SPEND_REPORT_QUERY_KEY = "card-payment-spend-report";
export const CARD_DAILY_SPEND_QUERY_KEY = "card-daily-spend";

export const CHARGE_ACCOUNT_TOTAL_SPENT_QUERY_KEY =
  "charge-account-total-spent";

export const DOCUMENT_OF_TYPE_LIST_QUERY_KEY = "document-of-type-list";
export const DATA_SNAPSHOT_LIST_QUERY_KEY = "data-snapshot-list";

export const MONEY_ACCOUNT_DETAIL_QUERY_KEY = "money-account";

export const BILL_PAY_LIST_QUERY_KEY = "bill-pay-list";
export const BILL_PAYMENTS_LIST = "bill-payments-list";
export const BILL_PAYMENTS_PREVIEW = "bill-payments-preview";
export const BILL_PAY_DETAIL = "bill-pay-detail";
export const BILL_CHART_OF_ACCOUNTS_LIST = "bill-chart-of-accounts-list";
export const BILL_CATEGORY_LIST = "bill-pay-category-list";
export const BILL_PAY_SETTINGS_LIST = "bill-pay-settings-list";
export const BILL_PAY_FETCH_CODAT_INTEGRATIONS =
  "bill-pay-fetch-codat-integrations";
export const BILL_PAY_DELETE = "bill-pay-delete";
export const BILL_PAY_AMPLA_CODAT_COUNTERPARTY_LIST_QUERY_KEY =
  "bill-pay-ampla-codat-counterparty-list";
export const BILL_PAY_UNREAD_INVOICES_NUMBER_QUERY_KEY =
  "bill-pay-unread-invoices-number";
export const BILL_PAY_INVOICES_LIST_QUERY_KEY = "bill-pay-invoices-list";
export const BILL_PAY_INVOICES_ALL_LIST_QUERY_KEY =
  "bill-pay-invoices-all-list";

export const TRANSACTION_EVENT_LIST_QUERY_KEY = "transaction-event-list";
export const TRANSACTION_EVENT_COUNTS_QUERY_KEY = "transaction-event-counts";
export const TRANSACTION_PR_DETAILS_QUERY_KEY = "transaction-pr-details";
export const TRANSACTION_EVENT_DASHBOARD_QUERY_KEY =
  "transaction-event-dashboard";
export const TRANSACTION_EVENT_TRANSACTION_AMOUNTS_QUERY_KEY =
  "transaction-event-transaction-amounts";

export const PAY_LATER_SETTINGS_LIST_QUERY_KEY = "pay-later-settings-list";
export const PAY_LATER_REPAYMENT_ACCOUNT = "pay-later-repayment-account";
export const PAY_LATER_REPAYMENT_DATE = "pay-later-repayment-date";
export const PAY_LATER_PAYMENT_SUMMARY = "pay-later-payment-summary";
export const BILL_PAYMENT_INSTRUCTIONS = "bill-payment-instructions";

export const INTEGRATION_BANK_INSTITUTIONS_LIST =
  "integration-bank-institutions-list";
export const INTEGRATION_BANK_CONNECTIONS_LIST =
  "integration-bank-connections-list";
export const PLAID_ITEMS_LIST = "plaid-items-list";
export const PLAID_ACCOUNT_LIST = "plaid-accounts-list";
export const PLAID_LINK_FETCH = "plaid-link-fetch";

export const INTEGRATION_LIST_QUERY_KEY = "integration-list";

export const CHARGE_CARDS_APPLICATION_GET_ACTIVE_KEY =
  "charge-cards-product-application-active";

export const NET_TERMS_WHITELABEL_KEY = "net-terms-whitelabel";
export const NET_TERMS_BRAND_INVOICE_LIST_KEY = "net-terms-brand-invoice-list";
export const NET_TERMS_BRAND_INVOICE_DETAIL_KEY =
  "net-terms-brand-invoice-detail";
export const NET_TERMS_BRAND_LOANS_LIST_KEY = "net-terms-brand-loans-list";
