import env from "environment";
import { clearCache } from "hooks/useJsonCache";
import routes from "routes/routes";

export const ROOT_API_URL = env.REACT_APP_API_URL;
export const SESSION_TOKEN_KEY = "_ggAuth";
export const HEADERS: HeadersInit = {
  "content-type": "application/json",
  crossDomain: "true",
};

export const FETCH_OPTIONS: RequestInit & { crossDomain: boolean } = {
  referrer: "no-referrer",
  redirect: "error",
  mode: "cors",
  crossDomain: true,
};

type RestFunctionType<Type> = (
  path: string,
  body?: any,
  extraData?: any
) => Promise<Type>;

export const get_headers = (): HeadersInit => {
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);

  if (token) return { Authorization: `Bearer ${token}`, ...HEADERS };

  return HEADERS;
};

export const logout = (redirectUrl: string = routes.login.path) => {
  clearCache();
  sessionStorage.removeItem(SESSION_TOKEN_KEY);

  if ((window as any).refreshTokenInterval)
    clearInterval((window as any).refreshTokenInterval);

  window.location.href = redirectUrl;
};

export function isLoggedIn() {
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);
  return !!token;
}

export const setToken = async (token: string) => {
  sessionStorage.setItem(SESSION_TOKEN_KEY, token);
};

export const post: RestFunctionType<any> = async (path, body) => {
  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: get_headers(),
    ...FETCH_OPTIONS,
  });

  if (response.ok) {
    if (response.status === 204) return null; // Handle 204 No Content

    if (response.headers.get("content-length") === "0") return null;

    const contentType = response.headers.get("content-type") || "";

    if (contentType.indexOf("application/json") > -1) return response.json();

    return response.text();
  }

  if (response.status === 401) logout();

  if (typeof response.json === "function") {
    throw await response.json();
  }

  throw new Error(response.statusText);
};

export const patch: RestFunctionType<any> = async (path, body) => {
  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers: get_headers(),
    ...FETCH_OPTIONS,
  });

  if (response.ok) {
    if (response.status === 204) return null; // Handle 204 No Content

    if (response.headers.get("content-length") === "0") return null;

    const contentType = response.headers.get("content-type") || "";

    if (contentType.indexOf("application/json") > -1) return response.json();

    return response.text();
  }

  if (response.status === 401) logout();

  if (typeof response.json === "function") {
    throw await response.json();
  }

  throw new Error(response.statusText);
};

export const get: RestFunctionType<any> = async (path, params) => {
  const urlParams: URLSearchParams = new URLSearchParams(params);

  const response = await fetch(
    `${ROOT_API_URL}${path}?${urlParams.toString()}`,
    {
      headers: get_headers(),
      ...FETCH_OPTIONS,
    }
  );

  if (response.ok) {
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") > -1)
      return response.json();

    return response.text();
  }

  if (response.status === 401) logout();

  throw new Error(response.statusText);
};

const restFunctions: { [key: string]: RestFunctionType<any> } = {
  update: patch,
  create: post,
  list: get,
  detail: get,
};

export const crud = async (
  method: string,
  path: string,
  body?: any,
  extraData?: any
) => {
  const func = restFunctions[method];
  if (!func) {
    throw new Error(`${method} is not a valid method`);
  }

  let result;

  if (body && extraData) {
    result = await func(path, body, extraData);
  } else if (body) {
    result = await func(path, body);
  } else {
    result = await func(path);
  }

  return result;
};
