/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, ReactNode, useEffect, useState } from "react";

import { useMutation } from "react-query";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { usePlaidLink } from "react-plaid-link";
import PlaidAPI from "ampla-core/api/plaid";
import usePersistentState from "ampla-core/hooks/utils/usePersistentState";
import {
  PlaidLinkParams,
  PlaidCallbacks,
} from "ampla-core/hooks/integration/types";
import {
  PLAID_CALLBACK_CALLER_URI_KEY,
  PLAID_CALLBACK_ITEM_ID_KEY,
  PLAID_CALLBACK_LINK_TOKEN_KEY,
  PLAID_CALLBACK_REDIRECT_URI_KEY,
  PLAID_ENV_KEY,
} from "ampla-core/constants/storageKeys";
import { PLAID_LINK_FETCH } from "ampla-core/constants/queryKeys";

interface PlaidLinkButtonProps<T = any> extends PlaidCallbacks {
  plaidItemId?: number;
  ButtonComponent?: (props: T) => ReactElement<T>;
  ButtonProps?: T;
  children: ReactNode;
  routingNumber?: string;
  disableMultipleAccountsSelection?: boolean;
  enableAuthProduct?: boolean;
}

interface PlaidLauncherProps extends PlaidCallbacks {
  linkToken: string;
  plaidEnv: string;
  plaidItemId?: string;
}

const PlaidLauncher = ({
  linkToken,
  plaidEnv,
  plaidItemId,
  onSuccess,
  onExit,
  onEvent,
}: PlaidLauncherProps) => {
  const [
    plaidCallbackLinkToken,
    setPlaidCallbackLinkToken,
    removePlaidCallbackLinkToken,
  ] = usePersistentState(PLAID_CALLBACK_LINK_TOKEN_KEY, "");
  const [plaidCallbackRedirectURI, , removePlaidCallbackRedirectURI] =
    usePersistentState(PLAID_CALLBACK_REDIRECT_URI_KEY, "");
  const [, setPlaidCallbackCallerURI] = usePersistentState(
    PLAID_CALLBACK_CALLER_URI_KEY,
    ""
  );
  const [, setPlaidCallbackItemId, removePlaidCallbackItemId] =
    usePersistentState(PLAID_CALLBACK_ITEM_ID_KEY, "");

  const plaidLinkParams: PlaidLinkParams = {
    env: plaidEnv,
    token: linkToken,
    onSuccess: (...params) => {
      // Clear the persisted variables used for the OAuth flow
      removePlaidCallbackLinkToken();
      removePlaidCallbackRedirectURI();
      removePlaidCallbackItemId();

      onSuccess(...params);
    },
    onExit: (...params) => {
      // Clear the persisted variables used for the OAuth flow
      removePlaidCallbackLinkToken();
      removePlaidCallbackRedirectURI();
      removePlaidCallbackItemId();

      onExit?.(...params);
    },
    onEvent: (eventName, ...params) => {
      if (eventName === "OPEN_OAUTH") {
        // Before the OAuth flow redirects the user to the bank's website we need to set the
        // current location so that our callback page can redirect the user back and the
        // link token because we need to send the same one again after OAuth is complete
        const currentPathname = window.location.pathname;
        setPlaidCallbackLinkToken(linkToken);
        setPlaidCallbackCallerURI(currentPathname);
        setPlaidCallbackItemId(plaidItemId || "");
      }
      onEvent?.(eventName, ...params);
    },
  };

  if (plaidCallbackRedirectURI) {
    // If the plaidCallbackRedirectURI is thruthy that means the user has been
    // redirected here from the OAuth flow and we must pass the redirect URI
    // and original Link token to the usePlaidLink hook
    plaidLinkParams.token = plaidCallbackLinkToken;
    plaidLinkParams.receivedRedirectUri = plaidCallbackRedirectURI;
  }

  const { open, ready } = usePlaidLink(plaidLinkParams);

  useEffect(() => {
    if (typeof open === "function" && ready) open();
  }, [open, ready]);

  return null;
};

const PlaidLinkButton = ({
  plaidItemId: plaidId,
  onSuccess,
  onExit,
  children,
  ButtonComponent = Button,
  ButtonProps: { onClick, ...ButtonProps },
  routingNumber,
  disableMultipleAccountsSelection = false,
  enableAuthProduct = false,
}: PlaidLinkButtonProps) => {
  const plaidItemId = plaidId?.toString();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [plaidEnv, setPlaidEnv] = usePersistentState(PLAID_ENV_KEY, "");

  const [plaidCallbackLinkToken] = usePersistentState(
    PLAID_CALLBACK_LINK_TOKEN_KEY,
    ""
  );
  const [plaidCallbackRedirectURI] = usePersistentState(
    PLAID_CALLBACK_REDIRECT_URI_KEY,
    ""
  );
  const [plaidCallbackItemId] = usePersistentState(
    PLAID_CALLBACK_ITEM_ID_KEY,
    ""
  );

  const handlePlaidEvent = (eventName: string) => {
    if (eventName === "EXIT" || eventName === "HANDOFF") setLinkToken(null);
  };

  const { mutate: handleFetchToken, isLoading } = useMutation(
    PLAID_LINK_FETCH,
    async () => {
      if (linkToken) return;

      let response;

      if (plaidItemId) {
        response = await PlaidAPI.get_update_link_token(plaidItemId);
      } else {
        response = await PlaidAPI.link_token(routingNumber, {
          disableMultipleAccounts: disableMultipleAccountsSelection,
          enableAuthProduct,
        });
      }

      setLinkToken(response.link_token);
      setPlaidEnv(response.plaid_env);
    }
  );

  const handleOnClick = () => {
    handleFetchToken();
    onClick?.();
  };

  useEffect(() => {
    if (plaidCallbackRedirectURI) {
      if (plaidItemId && plaidItemId !== plaidCallbackItemId) return;
      if (!plaidItemId && plaidCallbackItemId) return;
      setLinkToken(plaidCallbackLinkToken);
    }
  }, [plaidCallbackRedirectURI]);

  return (
    <>
      <ButtonComponent
        onClick={handleOnClick}
        variant="text"
        color="primary"
        startIcon={
          isLoading ? <CircularProgress size={20} /> : ButtonProps?.startIcon
        }
        {...ButtonProps}
        disabled={isLoading || ButtonProps.disabled}
      >
        {children}
      </ButtonComponent>
      {linkToken && plaidEnv && (
        <PlaidLauncher
          linkToken={linkToken}
          plaidEnv={plaidEnv}
          plaidItemId={plaidItemId}
          onSuccess={onSuccess}
          onExit={onExit}
          onEvent={handlePlaidEvent}
        />
      )}
    </>
  );
};

export default PlaidLinkButton;
