import React from "react";
import classNames from "classnames";
import SerifTypography from "./Common/SerifTypography";

export interface HeaderProps {
  children: React.ReactNode;
  className?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  subtitle?: React.ReactChild;
  icon?: React.ReactElement;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <header
      className={classNames(
        props.icon != null && "flex items-center justify-between gap-3",
        props.className
      )}
    >
      <div>
        <SerifTypography variant={props.variant || "h3"}>
          {props.children}
        </SerifTypography>
        {props.subtitle && (
          <p className="mt-2 text-sm leading-6 text-zinc-600">
            {props.subtitle}
          </p>
        )}
      </div>
      <div className="flex-none">{props.icon}</div>
    </header>
  );
};

export default Header;
