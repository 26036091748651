import React from "react";
import classNames from "classnames";
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";
import { Typography } from "@ampla/ui-components";
import SerifTypography from "ampla-core/components/Common/SerifTypography";
import MutedText from "./Common/MutedText";
import Badge from "./Badge";
import { Paper } from "@material-ui/core";

export interface StatDetailProps {
  title: string;
  timeframe?: string;
  value: string;
  percentChange: number | null;
  timeLagMonths?: number;
  selected?: boolean;
  onSelect?: () => void;
  className?: string;
}

type Direction = "up" | "down" | "none";

const determineDirection = (value: number): Direction => {
  if (value > 0) return "up";
  if (value < 0) return "down";
  return "none";
};

export const timeLagWarning = (months: number) => `
  This metric only shows data from up to ${months} months ago because it relies on
  sources that may not be accurate until you have closed your most recent books.
`;

const StatDetail: React.FC<StatDetailProps> = (props) => {
  return (
    <StatDetailOuter {...props}>
      <StatDetailInner {...props} />
    </StatDetailOuter>
  );
};

export const StatDetailOuter: React.FC<
  Omit<StatDetailProps, "value" | "percentChange">
> = (props) => {
  return (
    <Paper
      className={classNames(
        "relative",
        props.onSelect &&
          (props.selected
            ? "border border-teal-400"
            : "group cursor-pointer border border-white"),
        props.className
      )}
      onClick={() => props.onSelect?.()}
    >
      <div className="pt-6 pr-6 pb-2 pl-6">
        <div className="flex items-center mb-4">
          <Typography
            variant="overline1"
            className={props.selected ? "text-teal-900" : "text-gray-500"}
          >
            {props.title}
          </Typography>
          {props.timeLagMonths && (
            <Badge
              className="right-4"
              tooltip={timeLagWarning(props.timeLagMonths)}
            >
              -{props.timeLagMonths}M
            </Badge>
          )}
        </div>
        {props.children}
      </div>
    </Paper>
  );
};

export const StatDetailInner: React.FC<StatDetailProps> = (props) => {
  return (
    <div className="flex">
      <div className="w-2/5 space-y-4 pb-4">
        <div className="space-y-1">
          <div className="relative inline-block">
            <TrendTicker
              className="absolute top-1 left-[100%] pl-2"
              percentChange={props.percentChange}
            />
            <SerifTypography variant="h4">{props.value}</SerifTypography>
          </div>
          <MutedText>{props.timeframe}</MutedText>
        </div>
        {/* <div className="border border-gray-100" />
        <div className="space-y-1">
          <SerifTypography variant="h4">{props.value}</SerifTypography>
          <MutedText>{props.timeframe}</MutedText>
        </div> */}
      </div>
      <div className="flex-1 flex items-baseline px-6">{props.children}</div>
    </div>
  );
};

const TrendTicker: React.FC<{
  className?: string;
  percentChange: number | null;
}> = (props) => {
  const percentChangeAbs = props.percentChange
    ? Math.abs(props.percentChange).toFixed(1)
    : "N/A";

  const direction = props.percentChange
    ? determineDirection(props.percentChange)
    : "none";

  return (
    <Typography
      variant="body2"
      className={classNames(
        props.className,
        "flex items-center gap-1",
        direction === "up" && "text-teal-500",
        direction === "down" && "text-red-600",
        direction === "none" && "text-gray-500"
      )}
    >
      {direction === "up" && <ArrowTrendingUpIcon className="w-5 h-5" />}
      {direction === "down" && <ArrowTrendingDownIcon className="w-5 h-5" />}
      {direction === "none" && <span>{"—"}</span>}
      <span>{percentChangeAbs}%</span>
    </Typography>
  );
};

export default StatDetail;
