import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { theme } from "@ampla/ui-components";
import { AnalyticsResult } from "ampla-core/api/analytics/types";
import {
  buildMultiSeries,
  buildSingleSeries,
  findBoundWith,
  SeriesBreakout,
} from "./AnalyticsLineGraph";

interface SparkLineProps {
  data?: AnalyticsResult;
  x: string;
  y: string;
  seriesBreakout?: SeriesBreakout;
}

const SparkLine: React.FC<SparkLineProps> = (props) => {
  if (props.data == null) return null;

  const data = props.seriesBreakout
    ? buildMultiSeries(props.data, props.x, props.y, props.seriesBreakout)
    : buildSingleSeries(props.data, props.x, props.y);

  const minY = findBoundWith(data, Math.min);
  const maxY = findBoundWith(data, Math.max);

  return (
    <ResponsiveLine
      data={data}
      axisLeft={null}
      axisBottom={null}
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      margin={{ top: 16, right: 0, bottom: 16, left: 0 }}
      curve="catmullRom"
      colors={{ datum: "color" }}
      yScale={{
        type: "linear",
        min: minY,
        max: maxY,
      }}
      defs={[
        {
          id: "redColor",
          type: "linearGradient",
          colors: [
            {
              offset: 0,
              color: theme.palette.brand.mintAlt[600],
              opacity: 0.9,
            },
            {
              offset: 100,
              color: theme.palette.brand.mintAlt[600],
              opacity: 0.08,
            },
          ],
        },
        {
          id: "greenColor",
          type: "linearGradient",
          colors: [
            {
              offset: 0,
              color: theme.palette.brand.mintAlt[600],
              opacity: 0.9,
            },
            {
              offset: 100,
              color: theme.palette.brand.mintAlt[600],
              opacity: 0.08,
            },
          ],
        },
      ]}
      fill={[
        { match: (d) => (d.data.value ?? 0) < 0, id: "redColor" },
        { match: (d) => (d.data.value ?? 0) >= 0, id: "greenColor" },
      ]}
    />
  );
};

export default SparkLine;
