import React from "react";

type SecureIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const SecureIcon = ({
  width = 32,
  height = 32,
  color = "#08646A",
}: SecureIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 11C18 9.9 17.1 9 16 9C14.9 9 14 9.9 14 11V13H18V11Z"
      fill={color}
    />
    <path
      d="M4 7V15C4 23.3 8.4 25.8 14.4 29.2L15.5 29.8C15.7 30 15.8 30 16 30C16.2 30 16.3 30 16.5 29.9L17.6 29.3C23.6 25.8 28 23.3 28 15V7C28 6.6 27.7 6.2 27.3 6.1L16.3 2.1C16.1 2 15.8 2 15.6 2.1L4.6 6.1C4.3 6.2 4 6.6 4 7ZM10 16C10 14.7 10.8 13.6 12 13.2V11C12 8.8 13.8 7 16 7C18.2 7 20 8.8 20 11V13.2C21.2 13.6 22 14.7 22 16V19C22 22.3 19.3 25 16 25C12.7 25 10 22.3 10 19V16Z"
      fill={color}
    />
    <path
      d="M16 16C15.4 16 15 16.4 15 17V19C15 19.6 15.4 20 16 20C16.6 20 17 19.6 17 19V17C17 16.4 16.6 16 16 16Z"
      fill={color}
    />
  </svg>
);

export default SecureIcon;
