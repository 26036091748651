import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import {
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import { Datum } from "@nivo/line";
import { AxisTick } from "@nivo/axes";
import { theme } from "@ampla/ui-components";
import { formatChartValue, formatMonthsAgo } from "util/charts";
import moment from "moment";
import ChartTooltip from "components/ChartTooltip";

export interface AnalyticsHeatmapProps {
  g: string;
  x: string;
  y: string;
  type: AnalyticsGraphType;
  unit: string;
  data?: AnalyticsResult;
}

const AnalyticsHeatmap: React.FC<AnalyticsHeatmapProps> = (props) => {
  if (props.data == null) return null;

  const data = processHeatmapData(props.data, props.g, props.x, props.y);

  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 24, right: 0, bottom: 16, left: 72 }}
      valueFormat={(value) => formatChartValue(props.type, value) as string}
      axisLeft={{
        renderTick: (props) => {
          const date = moment(props.value).format("MMM YY");
          return <AxisTick {...props} value={date} />;
        },
      }}
      tooltip={(tooltipProps: Datum) => (
        <ChartTooltip
          x={formatMonthsAgo(tooltipProps.cell.data.x)}
          y={tooltipProps.cell.formattedValue}
          unit={props.unit}
        />
      )}
      colors={{
        type: "sequential",
        colors: ["#fff", theme.palette.primary.main!],
      }}
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: theme.palette.grey[200],
            },
            text: {
              stroke: theme.palette.grey[200],
              strokeWidth: 0.5,
              fontSize: 12,
            },
          },
        },
      }}
    />
  );
};

export type HeatmapData = {
  id: string;
  item: any;
  data: { x: number; y: number }[];
}[];

export function processHeatmapData(
  result: AnalyticsResult,
  groupBy: string,
  x: string,
  y: string
): HeatmapData {
  result = populateAnalyticsResult(result, groupBy, x, y);

  const groups = result.reduce((groups, item) => {
    const g = item[groupBy];
    groups[g] = groups[g] ?? { item, data: [] };
    groups[g].data.push({ x: item[x], y: item[y] } as Datum);
    return groups;
  }, {});

  let data = [];

  for (const date in groups) {
    const maxIndex = groups[date].data.length - 1;

    const d = groups[date].data.map((a: { x: string }) => ({
      ...a,
      x: Math.abs(toMonthsAgo(a.x) - maxIndex).toString(),
    }));

    d.sort((a: any, b: any) => parseInt(a.x) - parseInt(b.x));

    data.push({ id: date, item: groups[date].item, data: d });
  }

  data.sort((a, b) => Date.parse(a.id) - Date.parse(b.id));
  return data;
}

function populateAnalyticsResult(
  result: AnalyticsResult,
  groupBy: string,
  x: string,
  y: string
): AnalyticsResult {
  // Create a nested object for looking up `y` given `groupBy` and `x`
  const resultLookup: Record<string, Record<string, any>> = {};

  for (let i = 0; i < result.length; i++) {
    const item = result[i];
    resultLookup[item[groupBy]] = resultLookup[item[groupBy]] ?? {};
    resultLookup[item[groupBy]][item[x]] = item;
  }

  // Generate a full analytics result, use the lookup object to populate `y` where present
  const template = generateEmptyAnalyticsResult(groupBy, x, y);

  for (let i = 0; i < template.length; i++) {
    const item = template[i];
    template[i] = resultLookup[item[groupBy]]?.[item[x]] ?? template[i];
  }

  return template;
}

function generateEmptyAnalyticsResult(
  groupBy: string,
  x: string,
  y: string
): AnalyticsResult {
  const now = moment().startOf("month");
  const data: AnalyticsResult = [];

  // Walk back in time 12 months
  // For each signup / report month combination, generate an empty record
  for (let signup = 0; signup < 12; signup++) {
    const signupMonth = now.clone().subtract(signup, "months");

    for (let report = 0; report <= signup; report++) {
      const reportMonth = now.clone().subtract(report, "months");

      data.push({
        [groupBy]: signupMonth.format("YYYY-MM-DD"),
        [x]: reportMonth.format("YYYY-MM-DD"),
        [y]: null,
      });
    }
  }

  return data;
}

function toMonthsAgo(date: string): number {
  return moment().diff(moment(date), "months");
}

export default AnalyticsHeatmap;
