import React from "react";
import IconWrapper from "./IconWrapper";
import { theme as amplaDesignSystem } from "@ampla/ui-components";

const QuickbooksIcon = ({
  color = amplaDesignSystem.palette.white,
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="100%"
    viewBox="0 0 91 91"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="logo-intuit-quickbooks-preferred"
        transform="translate(-45.000000, -45.000000)"
        fill={color}
      >
        <g id="QuickBooks_Lockup" transform="translate(45.000000, 45.000000)">
          <g id="Icon_2_">
            <path
              d="M45.370392,90.225586 C20.5175783,90.225586 0.370392,70.0783997 0.370392,45.225586 C0.370392,20.3727723 20.5175783,0.225586 45.370392,0.225586 C70.2232057,0.225586 90.370392,20.3727723 90.370392,45.225586 C90.370392,70.0783997 70.2232057,90.225586 45.370392,90.225586 Z M12.865799,45.224396 C12.865799,54.889191 20.70079,62.724213 30.365799,62.724213 L32.865799,62.724213 L32.865799,56.224213 L30.365799,56.224213 C24.3004,56.224213 19.365799,51.289612 19.365799,45.224396 C19.365799,39.158814 24.3004,34.224396 30.365799,34.224396 L36.373398,34.224396 C36.373398,34.224396 36.373398,67.580994 36.373398,68.224213 C36.373398,71.813996 39.2836,74.724213 42.873398,74.724213 L42.873398,27.724396 C42.873398,27.724396 36.14679,27.724396 30.365799,27.724396 C20.70079,27.724396 12.865799,35.559601 12.865799,45.224396 Z M60.374802,27.72699 L57.874802,27.72699 L57.874802,34.22699 L60.374802,34.22699 C66.440201,34.22699 71.374802,39.161591 71.374802,45.22699 C71.374802,51.292389 66.440201,56.226807 60.374802,56.226807 L54.367188,56.226807 C54.367188,56.226807 54.367188,22.870209 54.367188,22.22699 C54.367188,18.636994 51.457001,15.72699 47.867203,15.72699 L47.867203,62.726807 C47.867203,62.726807 54.593796,62.726807 60.374802,62.726807 C70.039795,62.726807 77.874802,54.891602 77.874802,45.22699 C77.874802,35.562012 70.039795,27.72699 60.374802,27.72699 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconWrapper(QuickbooksIcon);
