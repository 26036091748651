import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import routes from "./routes";
import AppOutlet from "./components/AppOutlet";
import PublicOutlet from "./components/PublicOutlet";
import Auth from "pages/Auth";
import Connect from "pages/Connect";
import Summary from "pages/Summary";
import Entry from "pages/Entry";
import Settings from "pages/Settings";
import Orders from "pages/Orders";
import Cohorts from "pages/Cohorts";
import Benchmarks from "pages/Benchmarks";
import Login from "pages/Login";
import Register from "pages/Register";
import LinkAccount from "pages/LinkAccount";
import EntityOutlet from "./components/EntityOutlet";
import Marketing from "pages/Marketing";
import Financials from "pages/Financials";
import AccountsPayablesReceivables from "pages/AccountsPayablesReceivables";
import Cashflow from "pages/Cashflow";
import ConnectCodatIntegration from "ampla-core/pages/ConnectCodatIntegration";
import ConnectFivetranIntegration from "ampla-core/pages/ConnectFivetranIntegration";
import FinancialIntegrationPage from "ampla-core/pages/FinancialIntegration/FinancialIntegrationPage";
import EmbedContextProvider from "contexts/EmbedContext";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const InsightsRouter = () => {
  return (
    <Router>
      <EmbedContextProvider>
        <SentryRoutes>
          <Route path={routes.auth.path} element={<Auth />} />
          <Route path={routes.connect.path} element={<Connect />} />
          <Route path={routes.entry.path} element={<Entry />} />
          <Route
            path={routes.connectCodatIntegration.path}
            element={<ConnectCodatIntegration />}
          />
          <Route
            path={routes.connectFivetranIntegration.path}
            element={<ConnectFivetranIntegration />}
          />
          <Route element={<PublicOutlet />}>
            <Route path={routes.login.path} element={<Login />} />
            <Route path={routes.register.path} element={<Register />} />
          </Route>
          <Route element={<EntityOutlet />}>
            <Route
              path={routes.loginLinkAccount.path}
              element={<LinkAccount />}
            />
          </Route>
          <Route element={<AppOutlet layout={true} />}>
            <Route path={routes.appSummary.path} element={<Summary />} />
            <Route path={routes.appFinancials.path} element={<Financials />} />
            <Route path={routes.appCashflow.path} element={<Cashflow />} />
            <Route
              path={routes.appAccountsPayablesReceivables.path}
              element={<AccountsPayablesReceivables />}
            />
            <Route path={routes.appMarketing.path} element={<Marketing />} />
            <Route path={routes.appOrders.path} element={<Orders />} />
            <Route
              path={routes.appCustomerBehavior.path}
              element={<Cohorts />}
            />
            <Route path={routes.appBenchmarks.path} element={<Benchmarks />} />
            <Route path={routes.appSettings.path} element={<Settings />} />
          </Route>
          <Route element={<AppOutlet layout={false} />}>
            <Route
              path={routes.appSettingsFinancialIntegrations.path}
              element={<FinancialIntegrationPage />}
            />
          </Route>
        </SentryRoutes>
      </EmbedContextProvider>
    </Router>
  );
};

export default InsightsRouter;
