import {
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { GetApp as GetAppIcon, Sync as SyncIcon } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { CamelCasedCodatIntegration } from "ampla-core/api/codat/types";
import CopyChip from "ampla-core/components/Common/CopyChip";
import SerifTypography from "components/Common/SerifTypography";
import Spacer from "ampla-core/components/Common/Spacer";

type QuickBooksDesktopConnectInstructionsProps = {
  codatCompanyId: string;
  connectorDownloadUrl: string;
  integration: CamelCasedCodatIntegration;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: 45,
      width: 514,
    },
    logo: {
      borderRadius: "100%",
      display: "block",
      height: 90,
      margin: "auto",
      width: 90,
    },
    downloadButtonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    separator: {
      backgroundColor: theme.palette.grey[100],
      border: "none",
      height: 1,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      width: "100%",
    },
  });

const QuickBooksDesktopConnectInstructions = ({
  codatCompanyId,
  connectorDownloadUrl,
  integration,
  classes,
}: QuickBooksDesktopConnectInstructionsProps & WithStyles<typeof styles>) => (
  <Grid spacing={3} className={classes.root} container>
    <Grid xs={12} item>
      <img
        src={integration.logoUrl}
        alt="QuickBooks Desktop Logo"
        className={classes.logo}
      />
    </Grid>
    <Grid xs={12} item>
      <SerifTypography variant="h2" align="center">
        Connect QuickBooks Desktop
      </SerifTypography>
    </Grid>
    <Spacer size={2} />

    <Grid xs={12} item>
      <SerifTypography variant="h4">Step 1</SerifTypography>
      <Spacer />
      <Typography>
        Download the QuickBooks Desktop connector using the option below.
      </Typography>
    </Grid>
    <Grid xs={12} className={classes.downloadButtonContainer} item>
      <Button
        href={connectorDownloadUrl}
        variant="contained"
        startIcon={<GetAppIcon />}
        download
      >
        Download Connector
      </Button>
    </Grid>
    <hr className={classes.separator} />

    <Grid xs={12} item>
      <SerifTypography variant="h4">Step 2</SerifTypography>
      <Spacer />
      <Typography>
        When prompted for a license key use the one below.
      </Typography>
    </Grid>
    <Grid xs={12} className={classes.downloadButtonContainer} item>
      <CopyChip value={codatCompanyId} label={codatCompanyId} />
    </Grid>
    <hr className={classes.separator} />

    <Grid xs={12} item>
      <SerifTypography variant="h4">Step 3</SerifTypography>
      <Spacer />
      <Typography>
        Follow the QuickBooks Connector setup instructions; once the setup
        finishes click the button below to sync your QuickBooks data.
      </Typography>
    </Grid>
    <Grid xs={12} className={classes.downloadButtonContainer} item>
      <Button
        to={`/connect-integration/${codatCompanyId}`}
        component={RouterLink}
        variant="contained"
        startIcon={<SyncIcon />}
      >
        Sync QuickBooks Integration
      </Button>
    </Grid>
  </Grid>
);

export default withStyles(styles)(QuickBooksDesktopConnectInstructions);
