import React from "react";
import { Outlet } from "react-router-dom";
import NavigateRouteContext, {
  RouteContext,
  useRouteContext,
} from "./NavigateRouteContext";

const EntityOutlet: React.FC = () => {
  const routeContext = useRouteContext();

  if (routeContext !== RouteContext.Setup) {
    return <NavigateRouteContext context={routeContext} />;
  }

  return (
    <div className="bg-gray-50 h-full">
      <Outlet />
    </div>
  );
};

export default EntityOutlet;
