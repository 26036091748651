import React, { useState } from "react";
import { toNullable } from "@devexperts/remote-data-ts";
import Header from "components/Header";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import { usePageControls } from "ampla-core/contexts";
import { Timeframe } from "ampla-core/contexts/PageControlsProvider";
import {
  tagForTimeframe,
  xForTimeframe,
} from "../../components/AnalyticsLineGraph";
import useChartData from "hooks/useChartData";
import PageControls from "components/PageControls";
import routes from "routes/routes";
import { useEmbedded } from "contexts/EmbedContext";
import NavCta from "./components/NavCta";
import AnalyticsLineStatsDashboard, {
  AggregateType,
  ChartProps,
} from "components/AnalyticsLineStatsDashboard";
import EcommerceIntegrationsCta from "components/EcommerceIntegrationsCta";
import { isMissingData } from "util/charts";
import moment from "moment";

const Summary: React.FC = () => {
  const pageControls = usePageControls();
  const isEmbedded = useEmbedded();

  const [selectedChart, setSelectedChart] = useState(
    AnalyticsGraph.InsightsRevenue
  );

  const analyticsData = useChartData({ controls: true }, [
    AnalyticsGraph.EcommercePerformance,
    AnalyticsGraph.InsightsClv,
  ]);

  const analyticsDataNullable = toNullable(analyticsData);

  const CHART_PROPS: ChartProps<AnalyticsGraph>[] = [
    {
      key: AnalyticsGraph.InsightsRevenue,
      title: "Revenue",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Revenue generated on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "total_revenue",
    },
    {
      key: AnalyticsGraph.InsightsClv,
      title: "Customer Lifetime Value",
      timeframe: "last 3 months",
      chart: AnalyticsGraph.InsightsClv,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Customer lifetime value on a ${pageControls.timeframe} basis, calculated using 3 months of trailing order history.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: {
        [Timeframe.Cohorts]: null,
        [Timeframe.Daily]: "average_of_ltv_estimate_90d",
        [Timeframe.Monthly]: "average_of_ltv_estimate_3m",
        [Timeframe.Weekly]: "average_of_ltv_estimate_12w",
      }[pageControls.timeframe]!,
      renderHelpText: (point) =>
        `Average 12-month customer lifetime value since ${moment(point.x)
          .subtract(3, "months")
          .format("MMMM DD")}.`,
    },
    {
      key: AnalyticsGraph.InsightsAov,
      title: "Average Order Value",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Average order value on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "average_order_value",
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Summary</Header>
          <PageControls />
        </div>
      ) : null}

      <AnalyticsLineStatsDashboard
        data={analyticsData}
        placeholderComponent={
          isEmbedded ? <EcommerceIntegrationsCta /> : undefined
        }
        chartProps={CHART_PROPS}
        selectedChart={selectedChart}
        onSelectChart={setSelectedChart}
      />

      {analyticsDataNullable && !isMissingData(analyticsDataNullable) && (
        <>
          <NavCta
            title="Financials"
            prompt="See your other key financial metrics, like cash conversion cycle and gross margin."
            route={routes.appFinancials}
            className="col-span-4"
          />

          <NavCta
            title="Customer Behavior"
            prompt="Learn more about how your customers behave based on cohort."
            route={routes.appCustomerBehavior}
            className="col-span-4"
          />

          <NavCta
            title="Orders"
            prompt="Wondering how discounts and refunds impact your business? Dive into your orders."
            route={routes.appOrders}
            className="col-span-4"
          />
        </>
      )}
    </>
  );
};

export default Summary;
