export enum Size {
  Large = "large",
  Small = "small",
}

export enum LogoSize {
  Default = "default",
  Lg = "lg",
  Md = "md",
  Sm = "sm",
  Xs = "xs",
  Xxs = "xxs",
}

export enum Color {
  Primary = "primary",
  White = "white",
  Black = "black",
  Teal = "teal",
  PayWaveBrand = "#0535BC",
  QuickbooksBrand = "#41912E",
  XeroBrand = "#39AAE1",
}
