import React from "react";
import classNames from "classnames";
import { Tooltip } from "@ampla/ui-components";

export type BadgeProps = {
  tooltip?: string;
  className?: string;
};

const Badge: React.FC<BadgeProps> = (props) => {
  const badge = (
    <div
      className={classNames(
        props.className,
        "absolute flex bg-orange-50 border border-orange-300 rounded-full px-3 py-px"
      )}
    >
      <span className="text-xs text-orange-700 leading-none tracking-wider">
        {props.children}
      </span>
    </div>
  );

  return props.tooltip ? (
    <Tooltip title={props.tooltip}>{badge}</Tooltip>
  ) : (
    badge
  );
};

export default Badge;
