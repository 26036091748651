import React from "react";
import moment from "moment";
import { Datum, DatumValue } from "@nivo/line";
import {
  Paper,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import { formatChartValue } from "util/charts";
import SerifTypography from "./Common/SerifTypography";

// Params

export type TooltipParams = CohortSpendPerUserTooltipParams;

export type CohortSpendPerUserTooltipParams = {
  chart: AnalyticsGraph.InsightsCohortSpendPerUser;
  id: string;
  item: any;
  point: Datum;
  month: number;
};

// Data

export type TooltipData = CohortSpendPerUserTooltipData;

export type CohortSpendPerUserTooltipData = {
  chart: AnalyticsGraph.InsightsCohortSpendPerUser;
  accumulatedSalesFormatted: string;
  elapsedMonthsFormatted: string;
  percentOfFirstOrderFormatted: null | string;
  signupMonthFormatted: string;
  cacPaybackMonthsFormatted: null | string;
  isPaybackMonth: boolean;
};

// Build TooltipData from TooltipParams

export const buildTooltipData = (params: TooltipParams): TooltipData | null => {
  if (params.chart === AnalyticsGraph.InsightsCohortSpendPerUser) {
    return {
      chart: AnalyticsGraph.InsightsCohortSpendPerUser,
      accumulatedSalesFormatted: formatChartValue(
        AnalyticsGraphType.Money,
        (params.point.y ?? 0) as DatumValue,
        { short: true }
      )!,
      elapsedMonthsFormatted: `${params.month}`,
      percentOfFirstOrderFormatted:
        params.point.y != null &&
        params.item.average_first_purchase_value != null
          ? formatChartValue(
              AnalyticsGraphType.Percentage,
              ((params.point.y ?? 0) as number) /
                params.item.average_first_purchase_value
            )!
          : null,
      signupMonthFormatted: moment(params.id).format("MMM YYYY"),
      cacPaybackMonthsFormatted: params.item.months_to_paydown
        ? `${params.item.months_to_paydown}`
        : null,
      isPaybackMonth: params.item.months_to_paydown === params.month,
    };
  }

  return null;
};

// Render component from TooltipData

const ContextualTooltip: React.FC<{ data: TooltipData | null }> = (props) => {
  if (props.data?.chart === AnalyticsGraph.InsightsCohortSpendPerUser) {
    return (
      <CustomTooltip title={<CohortSpendPerUserTooltip data={props.data} />}>
        {props.children as JSX.Element}
      </CustomTooltip>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    background: "transparent",
  },
}))(Tooltip);

const CohortSpendPerUserTooltip: React.FC<{
  data: CohortSpendPerUserTooltipData;
}> = (props) => {
  return (
    <Paper className="py-4 px-6 space-y-2">
      <SerifTypography variant="h4">
        {props.data.signupMonthFormatted} Cohort
      </SerifTypography>
      <Typography variant="body2" color="secondary">
        Customers who made their first order in{" "}
        {props.data.signupMonthFormatted}
      </Typography>
      <Typography variant="body2" color="secondary">
        This cohort accumulated{" "}
        <Emphasized>{props.data.accumulatedSalesFormatted}</Emphasized> in sales
        during month{" "}
        <Emphasized>{props.data.elapsedMonthsFormatted}</Emphasized> following
        their first order
        {props.data.percentOfFirstOrderFormatted && (
          <span>
            , equaling{" "}
            <Emphasized>{props.data.percentOfFirstOrderFormatted}</Emphasized>{" "}
            of their first purchase value
          </span>
        )}
        .
      </Typography>
      {props.data.isPaybackMonth && (
        <div className="flex items-center gap-2">
          <div className="relative h-4 w-4 overflow-hidden">
            <div className="absolute -top-3 -right-3 h-6 w-6 bg-orange-300 rotate-45" />
          </div>
          <Typography variant="body2" color="secondary">
            CAC payback:{" "}
            <Emphasized>
              {props.data.cacPaybackMonthsFormatted} month(s)
            </Emphasized>
          </Typography>
        </div>
      )}
    </Paper>
  );
};

const Emphasized: React.FC = (props) => (
  <span className="text-gray-600 font-bold">{props.children}</span>
);

export default ContextualTooltip;
