import React from "react";
import MutedText from "./Common/MutedText";
import { theme } from "@ampla/ui-components";

export interface ChartTooltipProps {
  x: string;
  y: string;
  unit?: string;
  renderHelpText?: (point: { x: string; y: string }) => string;
}

const ChartTooltip: React.FC<ChartTooltipProps> = (props) => {
  return (
    <div
      className="py-1 px-2 rounded-md border flex flex-col text-center"
      style={{ background: theme.palette.primary.main }}
    >
      <span className="text-center text-sm text-white uppercase">
        {props.x}
      </span>
      <span className="text-center text-sm text-white uppercase">
        {props.y}
        {props.unit && " " + props.unit}
      </span>
      {props.renderHelpText && (
        <div className="w-64 mt-1">
          <MutedText className="italic text-xs">
            {props.renderHelpText(props)}
          </MutedText>
        </div>
      )}
    </div>
  );
};

export default ChartTooltip;
