import React from "react";
import { Button } from "@ampla/ui-components";
import { useAuthContext } from "ampla-core/contexts";
import { clearCache } from "hooks/useJsonCache";
import Header from "components/Header";
import Section from "./components/Section";
import AdIntegrationsSection from "./components/AdIntegrationsSection";
import MutedText from "components/Common/MutedText";
import CodatIntegrationsSection from "./components/CodatIntegrationsSection";
import FinancialIntegrationsSection from "./components/FinancialIntegrationsSection";
import { useEmbedded } from "contexts/EmbedContext";

const Settings: React.FC = () => {
  const auth = useAuthContext();

  // We delegate to the parent app settings route when embedded
  const embedded = useEmbedded();
  if (embedded) return null;

  return (
    <div className="col-span-12">
      <Header variant="h4">Settings</Header>

      {/* <CodatIntegrationsSection /> */}

      {/* <AdIntegrationsSection />

      <FinancialIntegrationsSection /> */}

      <Section
        title="Chart cache"
        action="Clear cache"
        onAction={() => clearCache(true)}
      >
        <MutedText>
          Clears local data, charts will show the most recent data the next time
          they load.
        </MutedText>
      </Section>

      <Button color="primary-contrast" onClick={() => auth.signOut()}>
        Sign out
      </Button>
    </div>
  );
};

export default Settings;
