import { Theme, withStyles, createStyles, WithStyles } from "@material-ui/core";
import { flow } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
        margin: 0,
      },
    },
    mainDiv: {
      margin: "auto",
      maxWidth: 1104,
    },
  });

type PageContainerProps = {
  children?: React.ReactNode;
  maxWidth?: number;
};

const PageContainer = ({
  children,
  maxWidth,
  classes,
}: PageContainerProps & WithStyles<typeof styles>) => (
  <div className={classes.root}>
    <div className={classes.mainDiv} style={{ maxWidth }}>
      {children}
    </div>
  </div>
);

export default flow(withStyles(styles))(PageContainer);
