import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { usePageControls } from "ampla-core/contexts";
import {
  PageControlsState,
  Timeframe,
} from "ampla-core/contexts/PageControlsProvider";
import SelectMenu from "./SelectMenu";
import { MessageType } from "contexts/EmbedContext";
import { useEventFromParent } from "hooks/useEventFromParent";

const PageControls: React.FC<{ only?: Timeframe }> = (props) => {
  const pageControls = usePageControls();

  return (
    <div className="flex items-center">
      <TimeframeControls pageControls={pageControls} only={props.only} />
      <DateRangeControls pageControls={pageControls} />
    </div>
  );
};

const TimeframeControls: React.FC<{
  pageControls: PageControlsState;
  only?: Timeframe;
}> = (props) => {
  useEventFromParent(() => setOpen(false));
  const [open, setOpen] = useState(false);

  return props.only ? (
    <Tooltip title="This dashboard only shows data in a monthly timeframe">
      <Button>Monthly</Button>
    </Tooltip>
  ) : (
    <SelectMenu
      open={open}
      setOpen={setOpen}
      tooltip="Time interval"
      value={props.pageControls.timeframe}
      options={[
        [Timeframe.Daily, "Daily"],
        [Timeframe.Weekly, "Weekly"],
        [Timeframe.Monthly, "Monthly"],
      ]}
      onChange={(timeframe) => props.pageControls.setTimeframe(timeframe)}
    />
  );
};

const DateRangeControls: React.FC<{ pageControls: PageControlsState }> = (
  props
) => {
  useEventFromParent(() => setOpen(false));
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const onChange = (dateRange: DateRange) => {
    props.pageControls.setDateRange(dateRange);
    setOpen(false)
  };

  return (
    <div className="relative">
      <div className="absolute top-10 right-0">
        <DateRangePicker
          open={open}
          toggle={toggle}
          initialDateRange={props.pageControls.dateRange}
          onChange={onChange}
          closeOnClickOutside={true}
        />
      </div>

      <Tooltip title="Date Range">
        <Button
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
          onClick={toggle}
        >
          <span>
            {new Intl.DateTimeFormat().format(
              props.pageControls.dateRange.startDate
            )}
          </span>
          <span className="mx-2">&ndash;</span>
          <span>
            {new Intl.DateTimeFormat().format(
              props.pageControls.dateRange.endDate
            )}
          </span>
        </Button>
      </Tooltip>
    </div>
  );
};

export default PageControls;
