import React from "react";

const SmallAmplaLogo = ({
  color = "#085D6A",
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="90px"
    height="31px"
    viewBox="0 0 90 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="smaller"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g fill={color} fillRule="nonzero">
        <path
          d="M13.5460502,2 L8.46229115,2 L0,24 L4.21522884,24 L16.7116693,14.8803992 L15.1724842,18.1791369 L17.1509627,24 L22,24 L13.5460502,2 Z M6.135046,18.5302212 L10.9975003,5.70281803 L14.1446709,15.1238111 L6.135046,18.5302212 Z"
          id="Shape"
        />
        <path
          d="M40.6552365,10.7324961 C39.9876346,10.7175993 39.3348682,10.9344997 38.8030691,11.3478662 C38.3237652,11.7133056 37.9016734,12.151435 37.5513251,12.6472018 C37.6182374,13.0585795 37.6740421,13.4974109 37.718663,13.963657 C37.7632839,14.4299032 37.7855753,14.8915868 37.7855753,15.3487077 L37.7855753,24 L33.2140429,24 L33.2140429,15.3144295 C33.2140429,13.7648122 33.0026546,12.6140545 32.5798401,11.8621175 C32.1570636,11.1101805 31.4447647,10.733627 30.4430195,10.7324961 C29.835787,10.709254 29.2361626,10.8767454 28.7244866,11.2124692 C28.2807121,11.5183604 27.8913347,11.8998274 27.5732442,12.3403747 L27.5732442,23.9965683 L23,23.9965683 L23,7.34877957 L27.4059063,7.34877957 L27.4059063,9.6045906 C27.8895088,8.76448174 28.596406,8.08258305 29.4440131,7.63844807 C30.2836699,7.21260261 31.2096015,6.99589719 32.1464504,7.00591938 C33.2809933,7.00591938 34.2158262,7.2682121 34.9509871,7.79271956 C35.6971796,8.33064193 36.3042599,9.04662386 36.7196947,9.87885536 C37.4537144,8.82980144 38.276976,8.08698969 39.1895555,7.6504591 C40.1542497,7.2065581 41.202632,6.98478309 42.2601438,7.00081079 C43.3066241,7.00081079 44.191054,7.21162769 44.9138139,7.63330049 C45.6384758,8.05988689 46.2455941,8.66726263 46.67925,9.39888229 C47.1608364,10.216256 47.5005335,11.1128713 47.6835059,12.0489513 C47.9010947,13.1345063 48.0072262,14.2404956 47.9996182,15.3487077 L47.9996182,24 L43.4264501,24 L43.4264501,15.3144295 C43.4264501,13.7648122 43.2149477,12.6140545 42.7923234,11.8621175 C42.3693186,11.1101805 41.6572099,10.733627 40.6552365,10.7324961 Z"
          id="Path"
        />
        <path
          d="M50,8.32852659 L54.417982,8.32852659 L54.417982,10.3551127 C54.9514916,9.64111307 55.6452847,9.05405254 56.4470865,8.63839518 C57.2561915,8.21318848 58.2455673,8.00056649 59.4144453,8.00056649 C60.351162,7.98784647 61.2782694,8.18950165 62.1196617,8.58923101 C62.9299197,8.98235789 63.6387034,9.54655223 64.1945066,10.2403341 C64.7956658,11.004505 65.2473039,11.8692797 65.526743,12.7916489 C65.85346,13.8694559 66.0125903,14.9885936 65.9983685,16.1119465 C66.0218153,17.3587321 65.7923447,18.5978334 65.3222566,19.7585257 C64.911362,20.7594904 64.2913685,21.6671998 63.5010979,22.4246191 C62.7365802,23.1435798 61.8221571,23.6953899 60.8193282,24.0429336 C59.7642246,24.4077109 58.6510807,24.5903047 57.5310181,24.5823966 C56.9890523,24.5874697 56.4478553,24.5490486 55.9128082,24.4676181 C55.4800043,24.4002505 55.0521973,24.3077039 54.6309246,24.1905007 L54.6309246,28.9242125 L50,31 L50,8.32852659 Z M57.8588882,11.4996158 C57.1820075,11.4844712 56.5131987,11.6428186 55.9193426,11.9586929 C55.4269609,12.2174203 54.9868539,12.5607488 54.6209309,12.9720045 L54.6209309,20.8538274 C54.9484166,20.9723735 55.288971,21.0548485 55.635291,21.0997602 C56.0377294,21.1554896 56.4432428,21.1828693 56.849525,21.1817832 C58.4273757,21.1817832 59.5743444,20.7128255 60.2904311,19.7749386 C61.0061334,18.8370518 61.3655221,17.6269715 61.3689814,16.1447351 C61.3689814,14.8144301 61.0826236,13.7076767 60.509908,12.8244374 C59.9375768,11.9411982 59.0535191,11.4996158 57.8588882,11.4996158 Z"
          id="Shape"
        />
        <path
          d="M71.9360359,18.9645595 C71.9360359,19.4792758 72.049805,19.8661741 72.2789863,20.1218485 C72.5085782,20.3775611 72.8971185,20.5087468 73.4499463,20.5087468 C73.666806,20.5087468 73.8943443,20.4969982 74.134615,20.4751085 C74.3650283,20.4546729 74.5933881,20.420958 74.8192836,20.3741934 L75,23.5955141 C74.5034407,23.7238679 73.9974348,23.8188512 73.4860896,23.8797753 C72.9012256,23.9575376 72.3114331,23.996878 71.7208191,23.9975286 C70.9548281,24.0200307 70.1925335,23.8886537 69.4860967,23.6123141 C68.9172508,23.3785679 68.4165843,23.0215575 68.0259905,22.5710564 C67.6542897,22.1360158 67.3844473,21.6335838 67.232892,21.0958296 C67.0772295,20.5429209 66.9987821,19.9735566 67,19.4018962 L67,2.12960006 L71.9360359,0 L71.9360359,18.9645595 Z"
          id="Path"
        />
        <path
          d="M88.1703642,8.77624171 C86.9518583,7.5934231 85.2772573,7.00142133 83.147312,7.00031286 C82.0485546,6.99274465 80.9531756,7.1225126 79.8859506,7.38674996 C78.9167016,7.62656266 77.9853667,8.00439953 77.1200999,8.50909938 L78.0082653,11.7265418 C78.6370384,11.3917443 79.302224,11.1329347 79.9899328,10.9553877 C80.7639806,10.7502052 81.5613024,10.6485314 82.3612519,10.6529271 C83.4378615,10.6529271 84.2280509,10.9267967 84.7325709,11.4745359 C85.2367156,12.0222752 85.4893509,12.8209118 85.4904771,13.8704077 L85.4904771,14.4383288 C84.9191382,14.4618361 84.2832328,14.4836998 83.5797578,14.5055253 C82.8762827,14.5273508 82.1975832,14.5626309 81.5387792,14.60632 C79.8247625,14.7183524 78.4741205,15.1663672 77.4864775,15.9504408 C76.49921,16.7345144 76.0036992,17.8742172 76.0003208,19.3695491 C75.9931884,20.0014178 76.1050537,20.6288907 76.3306612,21.2176817 C76.5405025,21.760643 76.854326,22.2557871 77.2537376,22.6743699 C77.6636601,23.0989921 78.1572939,23.4305024 78.7019803,23.64719 C79.3108579,23.8881494 79.9595264,24.0073677 80.6123243,23.998347 C81.4152769,24.0162737 82.2152264,23.8882641 82.9738833,23.620319 C84.3658179,23.0860344 85.4506861,22.0779343 86.6215179,21.1908108 L87.7277831,20.3507401 L86.2299893,23.6572809 L90,23.6572809 L90,14.0384372 C89.9988738,11.7142339 89.3888701,9.96016879 88.1703642,8.77624171 Z M85.4852217,19.6971606 C85.0445175,20.0462536 84.5568899,20.3291439 84.0373544,20.5372313 C83.4705202,20.7642775 82.8661473,20.8783357 82.2572697,20.873252 C81.7707683,20.8985558 81.2898976,20.7571679 80.8916121,20.4717167 C80.5492593,20.2028925 80.3784582,19.8007839 80.3795789,19.2653525 C80.3795789,18.6627629 80.5774133,18.2326367 80.9723203,17.9750119 C81.4584464,17.6853179 81.9960004,17.4973741 82.5542007,17.4222654 C82.9937788,17.3550307 83.4757756,17.299607 84.0043204,17.254236 C84.5324897,17.208865 85.024622,17.1651758 85.4904771,17.119843 L85.4852217,19.6971606 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SmallAmplaLogo;
