/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from "react";
import classnames from "classnames";
import * as Mousetrap from "mousetrap";

import { debounce } from "throttle-debounce";
import Fuse from "fuse.js";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import TextField from "@material-ui/core/TextField";

import { flow } from "lodash";
import AmplaIconLogo from "ampla-core/images/logos/AmplaIconLogo";
import { isNonEmptyArray } from "ampla-core/helpers/arrays";
import AuthAPI from "ampla-core/api/auth";
import withAuthState from "ampla-core/components/withAuthState";
import { COMPANY_NAME } from "ampla-core/constants/common";
import FrontendPropTypes from "ampla-core/helpers/propTypes";
import AppMenuItem from "./AppMenuItem";

const DRAWER_WIDTH = 256;

class AppBrandSwitcher extends PureComponent {
  constructor(props) {
    super(props);
    this.switchEntity = this.switchEntity.bind(this);
    this.searchDebounced = debounce(300, this.search.bind(this));
    this.searchInputRef = React.createRef();
    this.componentDidMount = this.componentDidMount.bind(this);

    this.state = {
      switchOpen: false,
      brands_search: null,
    };
  }

  componentDidMount() {
    if (
      this.props.user &&
      isNonEmptyArray(this.props.user.membership_entities)
    ) {
      const brand_applying = this.props.user.membership_entities.filter(
        (e) => e.type === "brand_applying"
      );
      const brands = this.props.user.membership_entities.filter(
        (e) => e.type === "brand"
      );
      const gg_entity = this.props.user.membership_entities.find(
        (e) => e.type === "gg"
      );

      this.setState({
        brand_applying,
        brands,
        gg_entity,
      });
    }
    Mousetrap.bind(
      ["command+shift+f", "ctrl+shift+f"],
      this.onKeyFind.bind(this)
    );
  }

  onKeyFind() {
    if (this.state.switchOpen) {
      this.searchInputRef.current.focus();
    } else {
      this.setState({ switchOpen: true }, () => {
        setTimeout(() => {
          this.searchInputRef.current.focus();
        }, 300);
      });
    }
  }

  switchEntity = (entity_id) => () => {
    AuthAPI.switch_active_entity({ entity_id }).then(() => {
      window.location.href = "/summary";
    });
  };

  search(q) {
    if (q) {
      const fuse = new Fuse(this.props.user.membership_entities, {
        keys: ["name"],
        threshold: 0.2,
      });
      const brands_search = fuse.search(q).map((res) => res.item);
      console.log(brands_search);
      this.setState({
        brands_search,
      });
    } else {
      this.setState({
        brands_search: null,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.user &&
          this.props.user.membership_entities &&
          this.props.user.membership_entities.length > 2 && (
            <div className={classes.switchRoot}>
              <SwipeableDrawer
                anchor="right"
                classes={{
                  paper: classnames(classes.drawerPaper, {
                    [classes.switchDrawerOpen]: this.state.switchOpen,
                    [classes.switchDrawerClose]: !this.state.switchOpen,
                  }),
                }}
                open={this.state.switchOpen}
                onClose={() => {
                  this.setState({ switchOpen: false });
                }}
                onOpen={() => {
                  this.setState({ switchOpen: true });
                }}
              >
                <List component="nav">
                  <ListItem
                    classes={{
                      selected: classes.selectedListItem,
                    }}
                    component="li"
                    dense
                    className={classes.listItem}
                  >
                    <ListItemText>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          className={classes.menuText}
                        >
                          Switch Account
                        </Typography>
                      </Box>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    component="div"
                    dense
                    style={{ marginTop: "-24px" }}
                    className={classes.listItem}
                  >
                    <TextField
                      label="Search"
                      type="text"
                      name="brand_search"
                      className={classes.search}
                      onChange={(e) => {
                        this.searchDebounced.bind(this)(e.target.value);
                      }}
                      fullWidth
                      inputRef={this.searchInputRef}
                    />
                  </ListItem>
                  {isNonEmptyArray(this.state.brands_search) ? (
                    <>
                      {this.state.brands_search.map((entity) => (
                        <AppMenuItem
                          component="li"
                          dense
                          key={entity.id}
                          onClick={this.switchEntity(entity.id)}
                          text={entity.name}
                          selected={entity.id === this.props.user.active_entity}
                          icon={
                            <AddCircleIcon
                              className={classes.selectAccountLogo}
                            />
                          }
                          brandSwitcherItem
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {this.state.gg_entity && (
                        <AppMenuItem
                          component="li"
                          dense
                          onClick={this.switchEntity.bind(this)(
                            this.state.gg_entity.id
                          )}
                          text={`${COMPANY_NAME} Portfolio`}
                          selected={
                            this.state.gg_entity.id ===
                            this.props.user.active_entity
                          }
                          icon={
                            <AmplaIconLogo
                              color={
                                this.state.gg_entity.id ===
                                this.props.user.active_entity
                                  ? "black"
                                  : "primary"
                              }
                              size="small"
                              className={classes.amplaLogo}
                            />
                          }
                          brandSwitcherItem
                        />
                      )}
                      <AppMenuItem
                        component="li"
                        dense
                        onClick={() => {
                          this.setState((prevState) => ({
                            showBrands:
                              prevState.showBrands === "brand_applying"
                                ? null
                                : "brand_applying",
                          }));
                        }}
                        text="Applying Brands"
                        icon={
                          this.state.showBrands === "brand_applying" ? (
                            <ExpandMoreIcon
                              className={classes.selectAccountLogo}
                            />
                          ) : (
                            <ChevronRightIcon
                              className={classes.selectAccountLogo}
                            />
                          )
                        }
                        brandSwitcherItem
                      />
                      <Collapse in={this.state.showBrands === "brand_applying"}>
                        {isNonEmptyArray(this.state.brand_applying) && (
                          <>
                            {this.state.brand_applying.map((entity) => (
                              <AppMenuItem
                                component="li"
                                dense
                                key={entity.id}
                                onClick={this.switchEntity(entity.id)}
                                text={entity.name}
                                selected={
                                  entity.id === this.props.user.active_entity
                                }
                                icon={
                                  <AddCircleIcon
                                    className={classes.selectAccountLogo}
                                  />
                                }
                                brandSwitcherItem
                              />
                            ))}
                          </>
                        )}
                      </Collapse>
                      {isNonEmptyArray(this.state.brands) && (
                        <>
                          {this.state.brands.map((entity) => (
                            <AppMenuItem
                              component="li"
                              dense
                              key={entity.id}
                              onClick={this.switchEntity(entity.id)}
                              text={entity.name}
                              selected={
                                entity.id === this.props.user.active_entity
                              }
                              icon={
                                <AccountCircleIcon
                                  className={classes.selectAccountLogo}
                                />
                              }
                              brandSwitcherItem
                            />
                          ))}
                        </>
                      )}
                    </>
                  )}
                </List>
              </SwipeableDrawer>
              <Fab
                className={classes.switchButton}
                size="small"
                variant="extended"
                onClick={() => {
                  this.setState({
                    switchOpen: true,
                  });
                }}
                color="primary"
              >
                <CompareArrowsIcon className={classes.switchButtonIcon} />
                {this.props.user.active_entity_name}
              </Fab>
            </div>
          )}
      </div>
    );
  }
}

const styles = (theme) => ({
  switchRoot: {
    flexGrow: 1,
  },
  switchButton: {
    position: "fixed",
    zIndex: 9999,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    textTransform: "none",
  },
  switchButtonIcon: {
    marginRight: theme.spacing(1),
  },
  switchDrawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  switchDrawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.brand.black[500],
    color: theme.palette.white,
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
  },
  listItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px ${theme.spacing(
      1.5
    )}px ${theme.spacing(2)}px`,
    minWidth: "none",
    marginRight: theme.spacing(2),
  },
  // selectedListItem: {
  //     padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
  //     color: theme.palette.brand.black[500],
  // },
  selectAccountLogo: {
    width: "24px",
    height: "24px",
    padding: 0,
    color: theme.palette.white,
  },
  amplaLogo: {
    width: 24,
    height: 24,
    marginLeft: -8,
    marginRight: 8,
  },
  search: {
    "& label": {
      color: theme.palette.white,
    },
    "& input": {
      color: theme.palette.white,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid white",
    },
  },
});

AppBrandSwitcher.propTypes = {
  classes: FrontendPropTypes.classes.isRequired,
  user: FrontendPropTypes.user.isRequired,
};

export default flow(withStyles(styles), withAuthState)(AppBrandSwitcher);
