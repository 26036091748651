import React from "react";
import { Button } from "@ampla/ui-components";
import { Paper } from "@material-ui/core";
import SerifTypography from "ampla-core/components/Common/SerifTypography";

interface SectionProps {
  title: string;
  disabled?: boolean;
  action: string;
  onAction: () => void;
  isLoading?: boolean;
}

const Section: React.FC<SectionProps> = (props) => {
  return (
    <Paper className="my-8 p-8">
      <SerifTypography>{props.title}</SerifTypography>
      <div className="py-6 mb-6">{props.children}</div>
      <Button
        color="primary"
        disabled={props.disabled ?? false}
        onClick={props.onAction}
        isLoading={props.isLoading ?? false}
      >
        {props.action}
      </Button>
    </Paper>
  );
};

export default Section;
