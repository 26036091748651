import React from "react";
import { Button } from "@ampla/ui-components";
import Header from "components/Header";
import MutedText from "components/Common/MutedText";

const ErrorFallback: React.FC = (props) => {
  return (
    <div className="max-w-xl mx-auto mt-32 space-y-8">
      <Header variant="h2">Something went wrong!</Header>

      <MutedText>
        Our team has been notified and are on the case. Feel free to keep this
        page open, and just refresh when you would like to try again.
      </MutedText>

      <Button size="small" fullWidth onClick={() => window.location.reload()}>
        Refresh page
      </Button>
    </div>
  );
};

export default ErrorFallback;
