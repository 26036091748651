import React, { useState } from "react";
import moment from "moment";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import { Timeframe } from "ampla-core/contexts/PageControlsProvider";
import useChartData from "hooks/useChartData";
import Header from "components/Header";
import PageControls from "components/PageControls";
import AnalyticsLineStatsDashboard, {
  AggregateType,
  ChartProps,
} from "components/AnalyticsLineStatsDashboard";
import { tagForTimeframe, xForTimeframe } from "components/AnalyticsLineGraph";
import FinancialIntegrationsCta from "components/FinancialIntegrationsCta";
import { useEmbedded } from "contexts/EmbedContext";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

enum FinancialsChart {
  CashConversionCycle = "CashConversionCycle",
  ContributionMargin = "ContributionMargin",
  ContributionProfit = "ContributionProfit",
  CurrentRatio = "CurrentRatio",
  DaysInventoryOutstanding = "DaysInventoryOutstanding",
  DaysPayableOutstanding = "DaysPayableOutstanding",
  DaysSalesOutstanding = "DaysSalesOutstanding",
  GrossMargin = "GrossMargin",
  GrossProfit = "GrossProfit",
  LiquidityTotal = "LiquidityTotal",
  NetOperatingMargin = "NetOperatingMargin",
  NetOperatingProfit = "NetOperatingProfit",
  NetProfit = "NetProfit",
  OperatingExpense = "OperatingExpense",
  OperatingIncome = "OperatingIncome",
  ProfitMargin = "ProfitMargin",
  TotalExpense = "TotalExpense",
  TotalIncome = "TotalIncome",
}

const Financials: React.FC = () => {
  // Only makes sense to support montly metrics for financials
  const pageControls = { timeframe: Timeframe.Monthly };
  const isEmbedded = useEmbedded();

  const analyticsData = useChartData(
    { timeframe: Timeframe.Monthly, dateRange: "controls" },
    [AnalyticsGraph.InsightsFinancials]
  );

  const [selectedChart, setSelectedChart] = useState(
    FinancialsChart.CashConversionCycle
  );

  const defaultTimeLagMonths = 2;
  const defaultTimeLagTimefram = moment()
    .subtract(defaultTimeLagMonths, "months")
    .format("MMMM");

  const CHART_PROPS: ChartProps<FinancialsChart>[] = [
    {
      key: FinancialsChart.CashConversionCycle,
      title: "Cash Conversion Cycle",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Cash conversion cycle on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "cash_conversion_cycle",
      timeLagMonths: defaultTimeLagMonths,
      unit: "days",
    },
    {
      key: FinancialsChart.LiquidityTotal,
      title: "Liquidity",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Liquidity on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "liquidity_total",
    },
    {
      key: FinancialsChart.CurrentRatio,
      title: "Current Ratio",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Current ratio on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "current_ratio",
    },
    {
      key: FinancialsChart.DaysInventoryOutstanding,
      title: "Days Inventory Outstanding",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: ` on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "days_inventory_outstanding",
      timeLagMonths: defaultTimeLagMonths,
      unit: "days",
    },
    {
      key: FinancialsChart.DaysSalesOutstanding,
      title: "Days Sales Outstanding",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Days sales outstanding on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "days_sales_outstanding",
      timeLagMonths: defaultTimeLagMonths,
      unit: "days",
    },
    {
      key: FinancialsChart.DaysPayableOutstanding,
      title: "Days Payable Outstanding",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Days payable outstanding on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "days_payable_outstanding",
      timeLagMonths: defaultTimeLagMonths,
      unit: "days",
    },
    {
      key: FinancialsChart.TotalIncome,
      title: "Total Income",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Total income on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "total_income",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.TotalExpense,
      title: "Total Expense",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Total expense on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "total_expense",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.NetProfit,
      title: "Net Profit",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Net profit on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "net_profit",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.OperatingIncome,
      title: "Operating Income",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Operating income on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "operating_income",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.OperatingExpense,
      title: "Operating Expense",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Operating expense on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "operating_expense",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.NetOperatingProfit,
      title: "Net Operating Profit",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Net operating profit on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "net_operating_profit",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.GrossMargin,
      title: "Gross Margin",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Gross margin on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "gross_margin",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.ContributionMargin,
      title: "Contribution Margin",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Contribution margin on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "contribution_margin",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.ProfitMargin,
      title: "Profit Margin",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Profit margin on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "profit_margin",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.GrossProfit,
      title: "Gross Profit",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Gross profit on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "gross_profit",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.ContributionProfit,
      title: "Contribution Profit",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Contribution profit on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "contribution_profit",
      timeLagMonths: defaultTimeLagMonths,
    },
    {
      key: FinancialsChart.NetOperatingMargin,
      title: "Net Operating Margin",
      timeframe: `last ${defaultTimeLagTimefram}`,
      chart: AnalyticsGraph.InsightsFinancials,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Net operating margin on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "net_operating_margin",
      timeLagMonths: defaultTimeLagMonths,
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Financials</Header>
          <PageControls only={Timeframe.Monthly} />
        </div>
      ) : null}

      <AnalyticsLineStatsDashboard
        data={analyticsData}
        placeholderComponent={<FinancialIntegrationsCta />}
        chartProps={CHART_PROPS}
        selectedChart={selectedChart}
        onSelectChart={setSelectedChart}
        pageControlTimeframe={Timeframe.Monthly}
      />
    </>
  );
};

export default Financials;
