import { Typography, useTheme } from "@material-ui/core";
import { CamelCasedBankInstitution } from "ampla-core/api/integration/types";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";
import { useStyles } from "ampla-core/pages/FinancialIntegration/components/BankInstitutionTile.styles";
import BankingIcon from "ampla-core/images/icons/BankingIcon";

interface BankInstitutionTileProps {
  bankInstitution: CamelCasedBankInstitution;
  onClick: (institution: CamelCasedBankInstitution) => void;
}

const BankInstitutionTile = ({
  bankInstitution,
  onClick,
}: BankInstitutionTileProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { logo, institution } = bankInstitution;

  return (
    <RoundedPaper
      onClick={() => onClick(bankInstitution)}
      className={classes.paper}
      borderless
    >
      {logo ? (
        <img
          src={logo.upload as string}
          alt={institution}
          className={classes.logo}
        />
      ) : (
        <div className={classes.logo}>
          <BankingIcon color={theme.palette.primary.main} />
        </div>
      )}
      <Typography className={classes.bankName}>{institution}</Typography>
    </RoundedPaper>
  );
};

export default BankInstitutionTile;
