import React from "react";
import { useTheme } from "@material-ui/core";
import { Color, Size } from "ampla-core/images/enums";
import { LogoProps } from "ampla-core/images/types";

const LargeAmplaIconLogo = ({
  color,
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="126"
    height="70"
    viewBox="0 0 532 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.1999 8H50.8799L0.409912 140.87H25.5499L87.3699 95.1V95.21L100.08 85.8L90.8999 105.72L102.7 140.87H131.62L81.1999 8ZM36.9999 107.82L65.9999 30.36L84.7699 87.25L36.9999 107.82Z"
      fill={color}
    />
  </svg>
);

const SmallAmplaIconLogo = ({
  color,
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="40"
    height="auto"
    viewBox="0 0 132 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.2001 0H50.8801L0.410156 132.85H25.5502L87.3701 87.08V87.19L100.08 77.78L90.9001 97.7L102.7 132.85H131.62L81.2001 0ZM37.0001 99.82L66.0001 22.36L84.7701 79.25L37.0001 99.82Z"
      fill={color}
    />
  </svg>
);

const AmplaIconLogo = ({
  className,
  size = Size.Small,
  color = Color.Primary,
}: LogoProps<Size, Color> &
  React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const theme = useTheme();

  const logoSrcMap = {
    large: LargeAmplaIconLogo,
    small: SmallAmplaIconLogo,
  };

  const LogoSVG = logoSrcMap[size];

  const logoColorMap: Partial<Record<Color, any>> = {
    [Color.Primary]: (theme.palette as any).brand.yellow[500],
    [Color.Teal]: (theme.palette as any).brand.teal[500],
    [Color.White]: "#FFFFFF", // confirm looks okay in components/Common/Header
    [Color.Black]: (theme.palette as any).brand.black[500],
  };

  const logoColor = logoColorMap[color] as string;

  return (
    <div className={className}>
      <LogoSVG color={logoColor} />
    </div>
  );
};

export default AmplaIconLogo;
