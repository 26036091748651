import React from "react";
import { Typography } from "@ampla/ui-components";
import classNames from "classnames";

const MutedText: React.FC<{ className?: string }> = (props) => {
  return (
    <Typography
      className={classNames(props.className, "text-gray-500")}
      variant="body3"
    >
      {props.children}
    </Typography>
  );
};

export default MutedText;
