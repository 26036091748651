import React from "react";

const Callout: React.FC = (props) => {
  return (
    <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
      <p className="text-sm text-gray-500">{props.children}</p>
    </div>
  );
};

export default Callout;
