import React from "react";
import { Outlet } from "react-router-dom";
import { PageControlsProvider } from "ampla-core/contexts";
import AppLayout from "layouts/AppLayout";
import NavigateRouteContext, {
  RouteContext,
  useRouteContext,
} from "./NavigateRouteContext";
import { useEmbedded } from "contexts/EmbedContext";
import PageContainer from "ampla-core/components/Common/PageContainer";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

const AppOutlet: React.FC<{ layout: boolean }> = (props) => {
  const routeContext = useRouteContext();
  const isEmbedded = useEmbedded();
  const Wrapper = isEmbedded ? RoundedPaper : React.Fragment;

  if (routeContext !== RouteContext.SignedIn) {
    return <NavigateRouteContext context={routeContext} />;
  }

  const children = (
    <PageControlsProvider>
      <Outlet />
    </PageControlsProvider>
  );

  if (isEmbedded) {
    return (
      <PageContainer>
        <Wrapper>
          <div
            className="grid grid-cols-12 gap-x-8 gap-y-12 p-6"
            style={{ position: "relative" }}
          >
            {children}
          </div>
        </Wrapper>
      </PageContainer>
    );
  } else if (props.layout) {
    return (
      <AppLayout>
        <div className="grid grid-cols-12 gap-x-8 gap-y-12 p-6">{children}</div>
      </AppLayout>
    );
  } else {
    return children;
  }
};

export default AppOutlet;
