import { User } from "ampla-core/types";
import { getEnvironmentFromAPIURL } from "ampla-core/helpers/url";

type HeapUserProperties = {
  entity_id?: number;
  entity_name?: string;
  user_name?: string;
  user_email?: string;
  user_is_staff?: string;
  env_name?: string;
};

export function getTrackingUserProperties(user: User): HeapUserProperties {
  return {
    entity_id: user.active_entity,
    entity_name: user.active_entity_name,
    user_name: [user.contact?.first_name, user.contact?.last_name].join(" "),
    user_email: user.email ?? user.username,
    user_is_staff: user?.is_staff?.toString(),
    env_name: getEnvironmentFromAPIURL(),
  };
}

export function getHeapInstance() {
  const { heap } = window;
  if (typeof heap === "object" && window.env.REACT_APP_HEAP_KEY) return heap;
  return null;
}

export function identifyUserInHeap(user: User) {
  const heap = getHeapInstance();

  if (heap && user.id) {
    heap.identify(user.id.toString());
    heap.addUserProperties(getTrackingUserProperties(user));
  }
}
