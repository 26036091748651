import snakecaseKeys from "snakecase-keys";
import { IntegrationStatus } from "ampla-core/api/integration/enums";
import { crud } from "ampla-core/api/rest";
import { APIList } from "ampla-core/types";
import { PlaidItemEnvironment } from "./enums";
import { PlaidAccount, PlaidItem, PlaidItemWrite } from "./types";

const PlaidAPI = {
  create: async (data: PlaidItemWrite): Promise<PlaidItemWrite> =>
    crud("create", "/plaid/", data),

  list: async (): Promise<APIList<PlaidItem>> => crud("list", "/plaid/", {}),

  accounts: async () => crud("list", "/plaid/accounts/"), // ToDo: TS Add return type

  plaid_item_accounts: async (id: number): Promise<Array<PlaidAccount>> =>
    crud("list", `/plaid/${id}/plaid_item_accounts/`),

  update_plaid_item_account: async (
    id: number,
    accountId: string,
    excluded: boolean
  ): Promise<any> => // ToDo: TS Add return type
    crud(
      "update",
      `/plaid/${id}/update_plaid_item_account/`,
      snakecaseKeys({
        accountId,
        excluded,
      })
    ),

  link_token: async (
    routingNumber?: string,
    config?: { disableMultipleAccounts?: boolean; enableAuthProduct?: boolean }
  ): Promise<{ link_token: string; plaid_env: PlaidItemEnvironment }> =>
    crud("list", "/plaid/create_link_token/", {
      routing_number: routingNumber,
      disable_multiple_accounts: config?.disableMultipleAccounts,
      enable_auth_product: config?.enableAuthProduct,
    }),

  get_update_link_token: async (
    id: string
  ): Promise<{ link_token: string; plaid_env: PlaidItemEnvironment }> =>
    crud("detail", `/plaid/${id}/get_update_link_token/`),

  verify: async (
    id: number
  ): Promise<PlaidItemWrite & { prev_status: IntegrationStatus }> =>
    crud("detail", `/plaid/${id}/verify/`),
};

export default PlaidAPI;
