import { FirstLetterRange } from "./types";

export const INSTITUTION_NAME_RANGE_LIST: FirstLetterRange[] = [
  { rangeStart: "A", rangeEnd: "E" },
  { rangeStart: "F", rangeEnd: "P" },
  { rangeStart: "Q", rangeEnd: "Z" },
];

export const FIRST_LETTER_IN_RANGE_LIST =
  INSTITUTION_NAME_RANGE_LIST[0].rangeStart[0];

export const RETURN_PAGE_LINK = "/settings/integrations";

export const PRIVACY_LINK = "https://www.getampla.com/legal";
