import React, { useEffect } from "react";
import PageLoading from "routes/components/PageLoading";
import { useAuthContext } from "ampla-core/contexts";
import { Navigate } from "react-router-dom";
import routes from "routes/routes";
import env from "environment";

const SHOPIFY_APP_URL =
  env.REACT_APP_ENV_ROLE === "production"
    ? "https://apps.shopify.com/ampla-insights"
    : "https://partners.shopify.com/2683098/apps/24008032257/test";

const Entry: React.FC = () => {
  const { user, isLoading } = useAuthContext();

  useEffect(() => {
    if (user) window.location.href = SHOPIFY_APP_URL;
  }, [user]);

  return user || isLoading ? (
    <PageLoading />
  ) : (
    <Navigate to={routes.login.path} />
  );
};

export default Entry;
