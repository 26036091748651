/* eslint-disable react/jsx-props-no-spreading */
import {
  Chip,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  ChipProps,
} from "@material-ui/core";
import { useState } from "react";
import { Tooltip } from "@ampla/ui-components";

type CopyChipProps = ChipProps & {
  label: string | React.ReactNode;
  value: string;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      fontSize: 16,
      color: theme.palette.common.black,
      padding: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: theme.palette.grey[200],
    },
    label: {
      flexGrow: 1,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      "& p": {
        paddingRight: ({ deleteIcon }: CopyChipProps) => (deleteIcon ? 27 : 0),
      },
    },
    clickable: {
      "&&:hover": {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },
      "&&:focus": {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
        boxShadow: "none",
      },
    },
    deleteIcon: {
      position: "absolute",
      right: theme.spacing(0.5),
      color: theme.palette.common.black,
      "&&:hover": {
        color: theme.palette.success.main,
      },
      "&&:focus": {
        color: theme.palette.success.main,
      },
    },
  });

const CopyChip = ({
  value,
  label,
  classes,
  ...props
}: CopyChipProps & WithStyles<typeof styles>) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  return (
    <Chip
      label={
        <Tooltip
          title={copied ? "Copied!" : "Click to copy"}
          placement="top"
          arrow
        >
          <p>{label}</p>
        </Tooltip>
      }
      classes={classes}
      onClick={handleClick}
      clickable
      {...props}
    />
  );
};

export default withStyles(styles)(CopyChip);
