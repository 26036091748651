import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Button } from "@ampla/ui-components";
import EntityApi from "ampla-core/api/entity";
import { APIList } from "ampla-core/types";
import { Entity, ShallowEntity } from "ampla-core/api/entity/types";
import SimpleForm, { useSimpleForm } from "components/SimpleForm";
import Input from "components/Input";
import SessionComponentWrapper from "components/SessionComponentWrapper";
import MutedText from "components/Common/MutedText";
import { continue_shopify_link } from "ampla-core/api/auth/auth";
import PageLoading from "routes/components/PageLoading";
import routes from "routes/routes";
import { safeSearchParams } from "util/params";
import { useAuthContext } from "ampla-core/contexts";
import {
  SESSION_INTEGRATION_ID_KEY,
  SESSION_RETURN_URL_KEY,
} from "ampla-core/constants/storageKeys";
import useSession from "hooks/useSession";

interface LinkAccountForm {
  name: string;
  legal_name: string;
}

const LinkAccount: React.FC = () => {
  const { isLoading, data } = useQuery<APIList<Entity>>(
    "user.account_entities",
    () => EntityApi.list({})
  );

  return !isLoading && data != null ? (
    <LinkAccountWithEntities entities={data.results} />
  ) : (
    <PageLoading />
  );
};

const LinkAccountWithEntities: React.FC<{ entities: ShallowEntity[] }> = (
  props
) => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const companyId = params.get("company_id");
  const connectionId = params.get("connection_id");
  const shop = params.get("shop");
  const [returnUrl] = useSession(SESSION_RETURN_URL_KEY);
  const [integrationId] = useSession(SESSION_INTEGRATION_ID_KEY);

  const entities: ShallowEntity[] = props.entities;

  useEffect(() => {
    if (returnUrl && auth.user && auth.user.active_entity && !isDisabled) {
      setLoading(true);
      setDisabled(true);
      linkEntity({ id: auth.user.active_entity } as ShallowEntity);
      return;
    }
    if (!auth.isLoading) {
      setLoading(false);
    }
  }, [auth.user, returnUrl]);

  async function linkEntity(entity: ShallowEntity) {
    try {
      await continue_shopify_link(
        entity.id!,
        companyId!,
        shop!,
        connectionId!,
        integrationId
      );
      await auth.refetchUser();
      if (returnUrl) {
        (window as any).location.href = returnUrl + "/success";
        return;
      }
      navigate(routes.appSummary.path);
    } catch (e: any) {
      if (returnUrl) {
        (window as any).location.href = returnUrl + "/error";
        return;
      }
      console.error(e);
      navigate(routes.login.path);
    }
  }

  const form = useSimpleForm<LinkAccountForm>(
    async (state) => {
      setLoading(true);
      setDisabled(true);
      setErrors([]);

      try {
        const entity = await EntityApi.create({
          ...state,
          product: "insights",
        });
        await linkEntity(entity);
      } catch (e: any) {
        setLoading(false);
        setDisabled(false);
        setErrors(e.detail ? [e.detail] : (e as string[]));
      }
    },
    (state) => true
  );

  if (!companyId || !shop) {
    return <Navigate to={routes.auth.path + safeSearchParams()} />;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <SessionComponentWrapper
      title={
        entities.length > 0
          ? "Link an existing business to your store"
          : "Tell us about your business"
      }
    >
      <SimpleForm
        className="mt-8 space-y-6"
        form={form}
        actions={[
          <Button
            fullWidth
            type="submit"
            isLoading={form.isLoading || isLoading}
            disabled={form.isLoading || isDisabled}
          >
            Submit
          </Button>,
        ]}
      >
        {errors.map((error: string) => (
          <div
            key={error}
            className="rounded-lg bg-red-50 text-sm text-red-500 p-4 mb-2"
          >
            {error}
          </div>
        ))}

        {entities.length > 0 && (
          <>
            <div className="space-y-4 pb-4 max-h-64 overflow-y-scroll">
              {entities.map((entity: ShallowEntity) => (
                <LinkEntityItem
                  key={entity.id}
                  entity={entity}
                  linkEntity={linkEntity}
                  disabled={isDisabled}
                  onDisable={(disabled) => setDisabled(disabled)}
                  onError={(errors) => setErrors(errors)}
                />
              ))}
            </div>
            <MutedText>Or, create a new business for this store:</MutedText>
          </>
        )}

        <Input f={form} name="name" label="Company name or DBA" />
        <Input f={form} name="legal_name" label="Company legal name" />
      </SimpleForm>
    </SessionComponentWrapper>
  );
};

const LinkEntityItem: React.FC<{
  entity: ShallowEntity;
  linkEntity: (entity: ShallowEntity) => Promise<void>;
  disabled: boolean;
  onDisable: (disabled: boolean) => void;
  onError: (error: string[]) => void;
}> = (props) => {
  const [isLoading, setLoading] = useState(false);

  async function submit() {
    setLoading(true);
    props.onDisable(true);
    props.onError([]);

    try {
      await props.linkEntity(props.entity);
    } catch (e: any) {
      setLoading(false);
      props.onDisable(false);
      props.onError(e.detail ? [e.detail] : (e as string[]));
    }
  }

  return (
    <div className="border border-gray-200 p-4 flex items-center justify-between">
      <span className="text-grey-800">{props.entity.name}</span>
      <Button
        size="small"
        isLoading={isLoading}
        disabled={props.disabled}
        onClick={() => submit()}
      >
        Link
      </Button>
    </div>
  );
};

export default LinkAccount;
