import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "ampla-core/contexts";
import {
  continue_shopify_link,
  magic_auth_login,
} from "ampla-core/api/auth/auth";
import { safeSearchParams } from "util/params";
import { clearCache } from "hooks/useJsonCache";
import routes from "routes/routes";
import PageLoading from "routes/components/PageLoading";
import {
  SESSION_INTEGRATION_ID_KEY,
  SESSION_RETURN_URL_KEY,
} from "ampla-core/constants/storageKeys";
import { useMutation } from "react-query";
import useSession from "hooks/useSession";

/**
 * Auth scenarios:
 *
 * 1. User arrives unauthenticated with no params -> Input Shopify shop
 * 2. User arrives authenticated with no params -> Redirect to app
 * 3. User arrives from callback with token param -> Set token and redirect to app
 * 4. User arrives from callback with entity_id param -> Require auth, then setup Insights
 * 5. User arrives from callback with shop param -> Require account creation, then setup Insights
 */

const Auth: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [fallbackRedirect, setFallbackRedirect] = useState(false);
  const insightsActive =
    auth.user?.active_entity_products?.insights === "active";
  const [returnUrl] = useSession(SESSION_RETURN_URL_KEY);
  const [integrationId] = useSession(SESSION_INTEGRATION_ID_KEY);

  const token = params.get("token");
  const entityId = params.has("entity_id")
    ? parseInt(params.get("entity_id")!)
    : null;
  const companyId = params.get("company_id");
  const connectionId = params.get("connection_id");
  const shop = params.get("shop");

  const { mutate: mutateMagicAuth } = useMutation(magic_auth_login, {
    onMutate: () => clearCache(),
    onSuccess: (response) => {
      auth.setToken(response.token);
      navigate(routes.appSummary.path);
    },
  });

  useEffect(() => {
    if (token && !auth.isLoading) {
      mutateMagicAuth(token);
      return;
    }

    if (
      auth.user &&
      entityId &&
      companyId &&
      connectionId &&
      shop &&
      !isLoading
    ) {
      setIsLoading(true);
      continue_shopify_link(
        entityId,
        companyId,
        shop,
        connectionId,
        integrationId
      )
        .then(() => auth.refetchUser())
        .then(() => {
          if (returnUrl) {
            (window as any).location.href = returnUrl + "/success";
            return;
          }
          navigate(routes.appSummary.path);
        })
        .catch((e: any) => {
          if (returnUrl) {
            (window as any).location.href = returnUrl + "/error";
            return;
          }
          console.error(e);
          navigate(routes.login.path);
        });
      return;
    }

    if (!auth.isLoading) {
      setFallbackRedirect(true);
    }
  }, [auth.isLoading]);

  if (auth.isLoading) {
    return <PageLoading />;
  }

  if (
    !token &&
    !returnUrl &&
    insightsActive &&
    !entityId &&
    !companyId &&
    !shop
  ) {
    return <Navigate to={routes.appSummary.path} />;
  }

  if (!token && !auth.user && entityId && companyId && shop) {
    return <Navigate to={routes.login.path + safeSearchParams()} />;
  }

  if (!token && auth.user && !entityId && companyId && shop) {
    return <Navigate to={routes.loginLinkAccount.path + safeSearchParams()} />;
  }

  if (!token && !auth.user && companyId && shop) {
    return <Navigate to={routes.register.path + safeSearchParams()} />;
  }

  if (fallbackRedirect) {
    return <Navigate to={routes.entry.path} />;
  }

  return <PageLoading />;
};

export default Auth;
