import React, { createContext, useContext, useState } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";

export enum Timeframe {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Cohorts = "cohorts",
}

export interface PageControlsState {
  timeframe: Timeframe;
  dateRange: DateRange;
  displayDateRange: string;
  setTimeframe: (timeframe: Timeframe) => void;
  setDateRange: (dateRange: DateRange) => void;
}

const Context = createContext<PageControlsState>({
  timeframe: defaultTimeframe(),
  dateRange: defaultDateRange(defaultTimeframe()),
  displayDateRange: '',
  setTimeframe: () => {
    throw new Error(
      "setTimeframe called outside of a PageControlsProvider context"
    );
  },
  setDateRange: () => {
    throw new Error(
      "setDateRange called outside of a PageControlsProvider context"
    );
  },
});

const PageControlsProvider: React.FC = (props) => {
  const [timeframe, setTimeframe] = useState<Timeframe>(defaultTimeframe());
  const [dateRange, setDateRange] = useState<DateRange>(
    defaultDateRange(timeframe)
  );


  const startDate = dateRange?.startDate;

  const endDate = dateRange?.endDate;

  const displayStartDate = startDate
    ? moment(startDate).format("MMM D, YYYY")
    : "";
  const displayEndDate = endDate ? moment(endDate).format("MMM D, YYYY") : "";

  const displayDateRange = (displayStartDate && displayEndDate) ? `between ${displayStartDate} and ${displayEndDate}` : ""

  const value = {
    timeframe,
    dateRange,
    displayDateRange,
    setTimeframe: (timeframe: Timeframe) => {
      setTimeframe(timeframe);
      setDateRange(enforceDateRangeConstraints(timeframe, dateRange));
    },
    setDateRange: (dateRange: DateRange) => {
      setDateRange(enforceDateRangeConstraints(timeframe, dateRange));
    },
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export function defaultTimeframe(): Timeframe {
  return Timeframe.Weekly;
}

export function defaultDateRange(timeframe: Timeframe): DateRange {
  return enforceDateRangeConstraints(timeframe, {
    startDate: moment().subtract(1, "year").toDate(),
    endDate: moment().toDate(),
  });
}

export function enforceDateRangeConstraints(
  timeframe: Timeframe,
  dateRange: DateRange
): DateRange {
  const minDays = {
    [Timeframe.Daily]: 7,
    [Timeframe.Weekly]: 30,
    [Timeframe.Monthly]: 90,
    [Timeframe.Cohorts]: 365,
  };

  const endDate = moment(dateRange.endDate);
  const daysNowToEnd = Math.max(endDate.diff(moment(), "days"), 0);
  const newEndDate = endDate.subtract(daysNowToEnd, "days");

  const startDate = moment(dateRange.startDate);
  const daysStartToEnd = Math.max(endDate.diff(startDate, "days"), 0);
  const newStartDate = startDate.add(
    Math.min(daysStartToEnd - minDays[timeframe], 0),
    "days"
  );

  return {
    startDate: newStartDate.toDate(),
    endDate: newEndDate.toDate(),
  };
}

export const usePageControls = () => useContext(Context);

export default PageControlsProvider;
