import React from "react";
import { ResponsiveBar, BarDatum } from "@nivo/bar";
import { theme } from "@ampla/ui-components";
import {
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import { Timeframe } from "ampla-core/contexts/PageControlsProvider";
import { formatAxisTick, formatChartValue } from "util/charts";
import { xForTimeframe } from "components/AnalyticsLineGraph";
import interpolate from "color-interpolate";
import ChartTooltip from "./ChartTooltip";
import { Datum } from "@nivo/line";
import { AxisTick } from "@nivo/axes";
import moment from "moment";

const colormap = interpolate([
  theme.palette.grey[100],
  theme.palette.ampla.teal[400],
]);

export type BarChartKey = { key: string; detail: string };

export interface InsightsBarChartProps {
  data: AnalyticsResult;
  type: AnalyticsGraphType;
  keys: BarChartKey[];
  timeframe: Timeframe;
  unit?: string;
  renderHelpText?: (point: { x: string; y: string }) => string;
}

const InsightsBarChart: React.FC<InsightsBarChartProps> = (props) => {
  const strKeys = props.keys.map((key) => key.key);

  const groups = props.data.reduce((groups, item) => {
    const g = item[xForTimeframe(props.timeframe)];
    groups[g] = groups[g] ?? {};
    groups[g][item.category] = item.amount_due;
    return groups;
  }, {});

  let dataSorted: BarDatum[] = [];

  for (const date in groups) {
    dataSorted.push({ report_date: date, ...groups[date] });
  }

  dataSorted.sort(
    (a, b) =>
      Date.parse(a.report_date as string) - Date.parse(b.report_date as string)
  );

  return (
    <ResponsiveBar
      data={dataSorted}
      indexBy="report_date"
      keys={strKeys}
      margin={{ top: 4, right: 72, bottom: 64, left: 0 }}
      colors={(x) =>
        colormap(strKeys.indexOf(x.id as string) / (props.keys.length - 1))
      }
      enableGridY={false}
      enableLabel={false}
      valueFormat={(value) => formatChartValue(props.type, value)!}
      tooltip={(tooltipProps: Datum) => (
        <ChartTooltip
          x={tooltipProps.indexValue}
          y={tooltipProps.formattedValue}
          unit={props.keys.find((key) => key.key === tooltipProps.id)?.detail}
          renderHelpText={props.renderHelpText}
        />
      )}
      axisLeft={null}
      axisRight={{
        renderTick: (tickProps) => {
          return tickProps.tickIndex % 2 === 0 ? (
            <AxisTick
              {...tickProps}
              format={(value) => formatAxisTick(props.type, value)}
            />
          ) : (
            <></>
          );
        },
      }}
      axisBottom={{
        renderTick: (tickProps) => {
          const date = moment(tickProps.value);
          const dateStr = date.format("MMM YY");

          if (
            props.timeframe === Timeframe.Monthly ||
            (props.timeframe === Timeframe.Weekly && date.date() <= 7) ||
            (props.timeframe === Timeframe.Daily && date.date() === 1)
          ) {
            return <AxisTick {...tickProps} value={dateStr} textAnchor="" />;
          } else {
            return <></>;
          }
        },
      }}
    />
  );
};

export const BarChartLegend: React.FC<{ keys: BarChartKey[] }> = (props) => {
  return (
    <div className="flex gap-4">
      {props.keys.map((key, i) => (
        <div key={key.key} className="flex">
          <div
            style={{ background: colormap(i / (props.keys.length - 1)) }}
            className="w-4 h-4"
          />
          <span className="ml-2 text-xs">{key.detail}</span>
        </div>
      ))}
    </div>
  );
};

export default InsightsBarChart;
