import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@ampla/ui-components";
import { AuthAPI } from "ampla-core/api";
import routes from "routes/routes";
import SimpleForm, { useSimpleForm } from "components/SimpleForm";
import Input from "components/Input";
import SessionComponentWrapper from "components/SessionComponentWrapper";
import { safeSearchParams } from "util/params";
import Callout from "components/Callout";

interface LoginForm {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  const entityId = params.has("entity_id")
    ? parseInt(params.get("entity_id")!)
    : null;
  const companyId = params.get("company_id");
  const shop = params.get("shop");
  const defaultEmail = params.get("email");

  const form = useSimpleForm<LoginForm>(
    async (state) => {
      try {
        setLoading(true);

        await AuthAPI.sign_in(state);

        if (entityId) {
          navigate(routes.auth.path + safeSearchParams());
        } else if (companyId && shop) {
          navigate(routes.loginLinkAccount.path + safeSearchParams());
        } else {
          navigate(routes.appSummary.path);
        }
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    (state) => true,
    { username: defaultEmail ?? "" }
  );

  return (
    <SessionComponentWrapper
      title="Log in to your account"
      titleAction={
        entityId === null ? (
          <p className="mt-2 text-sm text-gray-600">
            Or{" "}
            <Link
              to={routes.register.path + safeSearchParams()}
              className="font-medium text-teal-400 hover:text-teal-500"
            >
              create a new account
            </Link>
          </p>
        ) : null
      }
    >
      {entityId && companyId && shop && (
        <Callout>
          You have an existing Ampla user account connected to this Shopify
          shop. Please log in to continue.
        </Callout>
      )}
      <SimpleForm
        className="mt-8 space-y-6"
        form={form}
        actions={[
          <Button
            fullWidth
            type="submit"
            isLoading={form.isLoading || isLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <Input
          f={form}
          name="username"
          label="Email"
          type="email"
          defaultValue={defaultEmail ?? ""}
        />
        <Input f={form} name="password" label="Password" type="password" />
      </SimpleForm>
    </SessionComponentWrapper>
  );
};

export default Login;
