import { Button, Grid, Link, Typography } from "@material-ui/core";
import AmplaLogo from "ampla-core/images/logos/Logo";
import { useStyles } from "ampla-core/pages/FinancialIntegration/components/FinancialIntegration.styles";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";
import BankInstitutionList from "./BankInstitutionList";
import IntegrationAPI from "ampla-core/api/integration";
import {
  INTEGRATION_BANK_INSTITUTIONS_LIST,
  PLAID_LINK_FETCH,
} from "ampla-core/constants/queryKeys";
import { useIsMutating, useQuery } from "react-query";
import { CamelCasedBankInstitution } from "ampla-core/api/integration/types";
import camelcaseKeys from "camelcase-keys";
import {
  FIRST_LETTER_IN_RANGE_LIST,
  INSTITUTION_NAME_RANGE_LIST,
  PRIVACY_LINK,
} from "../constants";
import { FirstLetterRange } from "../types";
import LoadingContainer from "ampla-core/components/Common/LoadingContainer";
import SecureIcon from "ampla-core/images/icons/SecureIcon";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import PlaidLinkButton from "ampla-core/components/PlaidLinkButton";
import useStripeConnection from "ampla-core/hooks/integration/useStripeConnection";
import usePlaidConnection from "ampla-core/hooks/integration/usePlaidConnection";
import SerifTypography from "ampla-core/components/Common/SerifTypography";
import { Color, LogoSize } from "ampla-core/images/enums";

interface FinancialIntegrationProps {
  onSuccess?: () => void;
  onBack?: () => void;
}

const FinancialIntegration = ({
  onSuccess,
  onBack,
}: FinancialIntegrationProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isFetchingPlaid = !!useIsMutating(PLAID_LINK_FETCH);
  const { data: bankInstitutionsList, isFetching: isFetchingBanks } = useQuery({
    queryFn: IntegrationAPI.listInstitutions,
    queryKey: INTEGRATION_BANK_INSTITUTIONS_LIST,
    select: (data) =>
      getBankInstitutionsList(
        camelcaseKeys(data.results, {
          deep: true,
        }) as CamelCasedBankInstitution[]
      ),
  });

  const onLinkSuccess = () => {
    enqueueSnackbar("Successfully linked bank accounts", {
      variant: "success",
    });
    onSuccess?.();
  };

  const {
    handleStripeConnection,
    isCreateError,
    isCreatingAccounts,
    isLoadingStripe,
    isStripeError,
  } = useStripeConnection({ onSuccess: onLinkSuccess });

  const {
    handlePlaidConnection,
    handlePlaidExit,
    isError: isPlaidError,
    isLoading: isCreatingPlaid,
  } = usePlaidConnection({ onSuccess: onLinkSuccess });

  useEffect(() => {
    if (isStripeError)
      enqueueSnackbar("An error has occurred, please try again later", {
        variant: "error",
      });
    if (isCreateError || isPlaidError)
      enqueueSnackbar("Failed to link bank accounts, please try again later", {
        variant: "error",
      });
  }, [isStripeError, isCreateError, isPlaidError]);

  const isLoading =
    isLoadingStripe || isCreatingAccounts || isCreatingPlaid || isFetchingPlaid;

  return (
    <Grid container className={classes.page}>
      {isLoading ? <LoadingContainer fullscreen backdrop /> : null}
      <Grid item xs={12} md={4} className={classes.infoSection}>
        {onBack && (
          <Button
            onClick={onBack}
            startIcon={<ChevronLeftIcon />}
            className={classes.backButton}
          >
            Back
          </Button>
        )}
        <AmplaLogo
          size={LogoSize.Sm}
          color={Color.Black}
          className={classes.amplaLogo}
        />
        <SerifTypography variant="h3" className={classes.title}>
          Select your bank or continue with a different bank
        </SerifTypography>
        <Typography>
          You will be directed to our secure third-party connector to complete
          the flow
        </Typography>
        <div className={classes.plaidButton}>
          <PlaidLinkButton
            onSuccess={handlePlaidConnection}
            onExit={handlePlaidExit}
            ButtonProps={{ fullWidth: true, variant: "contained" }}
          >
            Connect a different bank
          </PlaidLinkButton>
        </div>
        <div className={classes.secureNote}>
          <SecureIcon />
          <div>
            <Typography>
              We keep your data secure.{" "}
              <Link href={PRIVACY_LINK} underline="always" target="_blank">
                Learn more
              </Link>
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={8} className={classes.bankSection}>
        {!isFetchingBanks ? (
          bankInstitutionsList?.map((bankInstitutions, index) => (
            <div
              key={INSTITUTION_NAME_RANGE_LIST[index].rangeStart}
              className={classes.listContainer}
            >
              <BankInstitutionList
                firstLetterRange={INSTITUTION_NAME_RANGE_LIST[index]}
                bankInstitutions={bankInstitutions}
                onSelectStripeBank={handleStripeConnection}
                onPlaidSuccess={handlePlaidConnection}
                onPlaidExit={handlePlaidExit}
              />
            </div>
          ))
        ) : (
          <div className={classes.bankLoading}>
            <LoadingContainer />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const getBankInstitutionsList = (
  bankInstitutions: CamelCasedBankInstitution[]
) =>
  INSTITUTION_NAME_RANGE_LIST.map((firstLetterRange) =>
    getBankInstitutionsInFirstLetterRange(bankInstitutions, firstLetterRange)
  );

const getBankInstitutionsInFirstLetterRange = (
  bankInstitutions: CamelCasedBankInstitution[],
  firstLetterRange: FirstLetterRange
): CamelCasedBankInstitution[] =>
  bankInstitutions.filter(
    (bank) =>
      getBankInstitutionFirstLetter(bank) >=
        firstLetterRange.rangeStart[0].toLowerCase() &&
      getBankInstitutionFirstLetter(bank) <=
        firstLetterRange.rangeEnd[0].toLowerCase()
  );

const getBankInstitutionFirstLetter = (
  bankInstitution: CamelCasedBankInstitution
) =>
  bankInstitution.institution[0] >= FIRST_LETTER_IN_RANGE_LIST
    ? bankInstitution.institution[0].toLowerCase()
    : FIRST_LETTER_IN_RANGE_LIST.toLowerCase();

export default FinancialIntegration;
