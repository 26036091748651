import { useState, useEffect, Dispatch, SetStateAction } from "react";

function usePersistentState<S>(
  key: string,
  initialValue: S | (() => S),
  backend: Storage = localStorage
): [S, Dispatch<SetStateAction<S>>, () => void] {
  const [value, setValue] = useState<S>(() => {
    const persistedValueStr = backend.getItem(key);

    if (initialValue) return initialValue;

    // This is to guard against a case such as when the value is undefined
    try {
      if (persistedValueStr) return JSON.parse(persistedValueStr);
    } catch (e) {
      console.error(e);
      return null;
    }

    return null;
  });

  const removeValue = () => backend.removeItem(key);

  useEffect(() => {
    backend.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue, removeValue];
}

export default usePersistentState;
