import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "ampla-core/contexts";
import routes from "routes/routes";
import PageLoading from "./PageLoading";
import { useEmbedded } from "contexts/EmbedContext";
import { SESSION_RETURN_URL_KEY } from "ampla-core/constants/storageKeys";
import useSession from "hooks/useSession";

export enum RouteContext {
  Loading = "Loading",
  SignedOut = "SignedOut",
  SignedIn = "SignedIn",
  Setup = "Setup",
}

export type NavigateRouteContextProps = {
  context: RouteContext;
};

const NavigateRouteContext: React.FC<NavigateRouteContextProps> = (props) => {
  if (props.context === RouteContext.Loading) {
    console.log("Loading");
    return <PageLoading />;
  }

  if (props.context === RouteContext.SignedOut) {
    console.log("Navigate: Login");
    return <Navigate to={routes.login.path} />;
  }

  if (props.context === RouteContext.SignedIn) {
    console.log("Navigate: AppSummary");
    return <Navigate to={routes.appSummary.path} />;
  }

  if (props.context === RouteContext.Setup) {
    console.log("Navigate: LinkAccount");
    return <Navigate to={routes.loginLinkAccount.path} />;
  }

  return null;
};

export const useRouteContext = () => {
  const isEmbedded = useEmbedded();
  const { user, isLoading } = useAuthContext();
  const [returnUrl] = useSession(SESSION_RETURN_URL_KEY);

  if (isLoading) {
    return RouteContext.Loading;
  }

  if (user === null) {
    return RouteContext.SignedOut;
  }

  if (
    !returnUrl &&
    (user.active_entity_products?.insights === "active" || isEmbedded)
  ) {
    return RouteContext.SignedIn;
  }

  return RouteContext.Setup;
};

export default NavigateRouteContext;
