import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { fold3 } from "@devexperts/remote-data-ts";
import {
  AnalyticsData,
  AnalyticsGraph,
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import { usePageControls } from "ampla-core/contexts";
import { formatChartValue, isMissingData, sumBy } from "util/charts";
import useChartData from "hooks/useChartData";
import ChartTitle from "pages/Summary/components/ChartTitle";
import Header from "components/Header";
import PageControls from "components/PageControls";
import InsightsBarChart, {
  BarChartKey,
  BarChartLegend,
} from "components/InsightsBarChart";
import { xForTimeframe } from "components/AnalyticsLineGraph";
import ProgressPlaceholder from "components/ProgressPlaceholder";
import EmptyPlaceholder from "components/EmptyPlaceholder";
import MutedText from "components/Common/MutedText";
import { StatDetailOuter } from "components/StatDetail";
import FinancialIntegrationsCta from "components/FinancialIntegrationsCta";
import { useEmbedded } from "contexts/EmbedContext";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

export interface ChartProps<K> {
  key: K;
  title: string;
  chart: AnalyticsGraph;
  chartType: AnalyticsGraphType;
  chartDescription: string;
  reportX: string;
  reportY: string;
  keys: BarChartKey[];
}

const AccountsPayablesReceivables: React.FC = () => {
  const pageControls = usePageControls();
  const isEmbedded = useEmbedded();

  const [selectedChart, setSelectedChart] = useState(
    AnalyticsGraph.InsightsPayables
  );

  const analyticsData = useChartData({ controls: true }, [
    AnalyticsGraph.InsightsPayables,
    AnalyticsGraph.InsightsReceivables,
  ]);

  const charts: ChartProps<AnalyticsGraph>[] = [
    {
      key: AnalyticsGraph.InsightsPayables,
      title: "Accounts Payable",
      chart: AnalyticsGraph.InsightsPayables,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `
        Summarizes the distribution of accounts payable on a ${pageControls.timeframe} basis by days past due.
        Aged payables are defined as expenses for which you have yet to pay your vendor.
        Aged payables are calculated using your accounting data integration.
      `,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "amount_due",
      keys: [
        { key: "ap_current", detail: "current" },
        { key: "ap_30", detail: "1-30 days" },
        { key: "ap_60", detail: "31-60 days" },
        { key: "ap_90", detail: "61-90 days" },
        { key: "ap_91plus", detail: "91+ days" },
      ],
    },
    {
      key: AnalyticsGraph.InsightsReceivables,
      title: "Accounts Receivable",
      chart: AnalyticsGraph.InsightsReceivables,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `
        Summarizes the distribution of accounts receivable on a ${pageControls.timeframe} basis by days past due.
        Aged receivables are defined as revenue for which cash has not yet been received.
        Aged receivables are calculated using your accounting data integration.
      `,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "amount_due",
      keys: [
        { key: "ar_current", detail: "current" },
        { key: "ar_30", detail: "1-30 days" },
        { key: "ar_60", detail: "31-60 days" },
        { key: "ar_90", detail: "61-90 days" },
        { key: "ar_91plus", detail: "91+ days" },
      ],
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">AP & AR</Header>
          <PageControls />
        </div>
      ) : null}

      {fold3(
        () => <ProgressPlaceholder />,
        () => <>Error</>,
        (data: AnalyticsData) => {
          if (isMissingData(data)) {
            return <FinancialIntegrationsCta />;
          } else {
            return (
              <>
                {isEmbedded ? (
                  <div className="absolute top-3 right-0">
                    <PageControls />
                  </div>
                ) : null}
                <SuccessComponent
                  data={data}
                  chartProps={charts}
                  selectedChart={selectedChart}
                  onSelectChart={setSelectedChart}
                />
              </>
            );
          }
        }
      )(analyticsData)}
    </>
  );
};

interface SuccessComponentProps<K> {
  data: AnalyticsData;
  chartProps: ChartProps<K>[];
  selectedChart: K;
  onSelectChart: (key: K) => void;
}

const SuccessComponent = <K extends string>(
  props: SuccessComponentProps<K>
): React.ReactElement => {
  const pageControls = usePageControls();

  function getData(chart: ChartProps<K>): AnalyticsResult {
    return props.data[chart.chart] ?? [];
  }

  function getLatestSnapshot(chart: ChartProps<K>) {
    const chartData = getData(chart);

    const keyValues = chart.keys.map((key) => {
      const filtered = chartData.filter((d) => d.category === key.key);
      return {
        key,
        value: filtered[filtered.length - 1]?.[chart.reportY] ?? 0,
      };
    });

    const total = keyValues.reduce((total, { value }) => value + total, 0);

    return { keyValues, total };
  }

  return (
    <>
      <div className="col-span-12">
        {props.chartProps
          .filter((chart) => chart.key === props.selectedChart)
          .map((chart) => ({ chart, data: getData(chart) }))
          .map(({ chart, data }) => (
            <React.Fragment key={chart.key}>
              <div className="flex justify-between items-end">
                <ChartTitle
                  title={chart.title}
                  value={getLatestSnapshot(chart).total}
                  isMoney
                />
                <BarChartLegend keys={chart.keys} />
              </div>
              <div className="relative h-72 mt-12">
                <InsightsBarChart
                  data={data}
                  type={chart.chartType}
                  keys={chart.keys}
                  timeframe={pageControls.timeframe}
                />
              </div>
              <div className="h-4">
                <MutedText className="italic text-xs">
                  {chart.chartDescription}
                </MutedText>
              </div>
            </React.Fragment>
          ))}
      </div>
      {props.chartProps.map((chart) => {
        const snapshotData = getLatestSnapshot(chart);

        return (
          <StatDetailOuter
            key={chart.key}
            title={chart.title}
            className="col-span-6"
            selected={props.selectedChart === chart.key}
            onSelect={() => props.onSelectChart(chart.key)}
          >
            <div className="grid grid-cols-4 gap-x-6 text-xs text-gray-500 font-mono">
              {snapshotData.keyValues.map(({ key, value }) => (
                <React.Fragment key={key.key}>
                  <div className="flex items-center">{key.detail}</div>
                  <div className="flex items-center justify-end">
                    {formatChartValue(chart.chartType, value, {
                      currencyFullFormat: true,
                    })}
                  </div>
                  <div className="col-span-2 py-4">
                    <LinearProgress
                      value={(value / snapshotData.total) * 100}
                      variant="determinate"
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </StatDetailOuter>
        );
      })}
    </>
  );
};

export default AccountsPayablesReceivables;
