import React from "react";
import { Color } from "ampla-core/images/enums";
import PayWaveIcon from "ampla-core/images/icons/PayWaveIcon";
import QuickbooksIcon from "ampla-core/images/icons/QuickbooksIcon";
import XeroIcon from "ampla-core/images/icons/XeroIcon";
import IntegrationCta from "./IntegrationCta";
import IconCircle from "./IconCircle";

const FinancialIntegrationsCta: React.FC = () => {
  return (
    <IntegrationCta
      title="Connect your financial platforms"
      icons={[
        <IconCircle background={Color.XeroBrand}>
          <XeroIcon />
        </IconCircle>,
        <IconCircle>
          <QuickbooksIcon color={Color.QuickbooksBrand} />
        </IconCircle>,
        <IconCircle background={Color.PayWaveBrand}>
          <PayWaveIcon />
        </IconCircle>,
      ]}
    />
  );
};

export default FinancialIntegrationsCta;
