export enum TransactionDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export enum TimePeriod {
  Year = "year",
  HalfYear = "half_year",
  YearToDate = "ytd",
  Quarter = "quarter",
  Month = "month",
  MonthToDate = "mtd",
}

export enum Currency {
  USD = "USD",
}

export enum EnabledProducts {
  ProductionFunding = "production_funding",
  Banking = "banking",
  DebitCards = "debit_cards",
  GrowthCards = "growth_cards",
  ChargeCards = "charge_cards",
  BillPay = "bill_pay",
  IntegrationWarnings = "integration_warnings",
  PayLater = "pay_later",
  NetTermsVendor = "net_terms_vendor",
  NetTermsBrand = "net_terms_brand",
  Insights = "insights",
}

export enum ProductState {
  Disabled = "disabled", // Not enabled by config
  Applying = "applying", // User needs to complete the product application
  Reviewing = "reviewing", // Product application is being reviewed
  Active = "active", // User has been approved and able to use this product
  Onboarding = "onboarding", // User needs to complete the onboarding process,
  NoApplication = "no_application", // User has product enabled but hasn't started the application
}

export enum UserGroups {
  Investor = "investor",
  PotentialInvestor = "potential_investor",
  AccessAllBrands = "access_all_brands",
  AccessApplyingBrands = "access_applying_brands",
  AccessFullDetailTransactionReport = "access_full_detail_transaction_report",
  AccessGGBankData = "access_gg_bank_data",
  AccessGGEventsExport = "access_gg_events_export",
  AccessGGPortfolio = "access_gg_portfolio",
  BankAccessOnly = "bank_access_only",
  SignupBankingAccess = "signup_banking_access",
  PreventRequestFunding = "prevent_request_funding",
  SkipFullOnboarding = "skip_full_onboarding",
  MicroserviceQuickbooksDesktop = "microservice_quickbooks_desktop",
  MicroserviceXero = "microservice_xero",
  Microservice = "microservice",
  AccountManagers = "account_managers",
  TestEmailRecipients = "test_email_recipients",
  SkipCreditAuthorization = "skip_credit_authorization",
  BrandOwner = "brand_owner",
  BrandAccessEmailAdvances = "brand_access_email_advances",
  BrandAccessFundingRequest = "brand_access_funding_request",
  BrandAccessIntegrations = "brand_access_integrations",
  BrandAccessTransactions = "brand_access_transactions",
  BrandAccessSelfReporting = "brand_access_self_reporting",
  BrandAccessVendorManagement = "brand_access_vendor_management",
  BrandAccessBankingFullAccess = "brand_access_banking_full_access",
  BrandAccessBankingUseCardAccess = "brand_access_banking_use_card_access",
  BrandAccessBankingViewOnly = "brand_access_banking_view_only",
  BrandAccessPaymentsOnly = "brand_access_payments_only",
  BrandAccessBillpayFullAccess = "brand_access_billpay_full_access",
  BrandAccessBillpayViewOnly = "brand_access_billpay_view_only",
  BrandAccessPaylaterFullAccess = "brand_access_paylater_full_access",
  BrandAccessPaylaterViewOnly = "brand_access_paylater_view_only",
  AccessPerformanceDash = "access_performance_dash",

  // staff to do manual data entry
  InternalMechanicalTurks = "mechanical_turks",

  // staff to release transaction events
  InternalReleaseEvents = "release_events",

  // staff ability give customer funding without an advance event
  InternalBancoFundingWithoutAdvance = "funding_without_advance",

  InternalBancoReadOnly = "internal_banco_read_only",
  InternalBancoOperationsAccess = "internal_banco_operations_access",
  InternalBancoFullAccess = "internal_banco_full_access",
  InternalAccessStandard = "internal_access_standard",

  // staff ability to create custom paydowns /ops/banco/bancoaccount/<ID>/actions/create_custom_paydown/
  InternalBancoCreateCustomPaydown = "custom_paydown",

  // staff ability to transfer check deposits
  InternalBancoTransferCheckDepositsFunds = "internal_banco_transfer_check_deposit_funds",
  InternalBrandOnboarding = "internal_brand_onboarding",

  // staff ability to create a staff user /ops/account/user/
  InternalCreateStaffUser = "internal_create_staff_user",

  // staff ability to make modifications to parameters before yesterday
  InternalRetroactivelyChangeParameters = "internal_retroactively_change_parameters",
}
