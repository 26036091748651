import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import MutedText from "./Common/MutedText";
import Header from "./Header";

const EmptyPlaceholder: React.FC = (props) => {
  return (
    <div className="col-span-12 my-6">
      <div className="h-64 flex flex-col items-center justify-center space-y-6">
        <Header variant="h3" icon={<CloudArrowDownIcon className="h-7 w-7" />}>
          We're still loading your data
        </Header>
        <MutedText>
          This may take a few hours from the time you installed the app, come
          back in a bit to see your insights.
        </MutedText>
      </div>
    </div>
  );
};

export default EmptyPlaceholder;
