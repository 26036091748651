import EventEmitter from "events";
import { useEffect } from "react";

const events = new EventEmitter();

export enum Event {
  SIGN_IN = "SIGN_IN",
  REFETCH_USER = "REFETCH_USER",
}

export const publish = <V>(event: Event, value: V): void => {
  events.emit(event, value);
};

export const subscribe = <V>(
  event: Event,
  callback: (value: V) => void
): (() => void) => {
  events.addListener(event, callback);
  return () => events.removeListener(event, callback);
};

export const useSubscribe = <V>(
  event: Event,
  callback: (value: V) => void
): void => {
  useEffect(() => subscribe(event, callback), []);
};
