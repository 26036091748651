import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    cursor: "pointer",
    height: "3.5rem",
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "&:hover": {
      outline: "1px solid black",
    },
  },
  logo: {
    width: "2.5rem",
    marginRight: theme.spacing(2),
  },
  bankName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
    fontSize: ".875rem",
  },
}));
