/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";

// ToDo: We should migrate the components that use this to TS
// so we can remove this and avoid confusion
const FrontendPropTypes = {
  // Note: "classes" covers object styling, so there isn't
  // much we can do about making this proptype much different
  classes: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    contact: PropTypes.shape({
      user_id: PropTypes.number,
      user: PropTypes.shape({
        username: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        is_active: PropTypes.bool,
        verify_date: PropTypes.string,
      }),
      title: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
      data: PropTypes.string,
    }),
    active_entity: PropTypes.number,
    active_entity_name: PropTypes.string,
    active_entity_logo: PropTypes.string,
    active_entity_type: PropTypes.string,
    // TODO: Make this more detailed
    active_entity_settings: PropTypes.object,
    membership_entities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        host: PropTypes.string,
      })
    ),
    connected_plaid: PropTypes.number,
    connected_quickbooks: PropTypes.bool,
    num_connected_quickbooks: PropTypes.number,
    connected_quickbooks_desktop: PropTypes.bool,
    connected_xero: PropTypes.bool,
    connected_accounting: PropTypes.bool,
    connected_banking: PropTypes.bool,
    connected_ecommerce: PropTypes.bool,
    connected_ads: PropTypes.bool,
    num_connected_xero: PropTypes.number,
    completed_credit_authorization: PropTypes.bool,
    connected_shopify: PropTypes.bool,
    active_entity_host: PropTypes.string,
    last_login: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    active_entity_num_plaid_access_memberships: PropTypes.number,
    active_application: PropTypes.shape({
      id: PropTypes.number,
      lastUrl: PropTypes.string,
    }),
    // TODO: Make this more detailed
    active_banking_application: PropTypes.object,
    active_entity_primary_production_run: PropTypes.number,
    active_entity_has_order_invoices: PropTypes.bool,
    active_entity_has_embed: PropTypes.bool,
    banking_user_id: PropTypes.string,
    banking_user_is_verified: PropTypes.bool,
    banking_fingerprint_is_valid: PropTypes.bool,
    // TODO: Make this more detailed
    user_groups: PropTypes.object,
  }),
  bankingNode: PropTypes.shape({
    bankingSubnets: PropTypes.arrayOf(PropTypes.any),
    bankingUser: PropTypes.string,
    beneficiary: PropTypes.shape({
      contacts: PropTypes.arrayOf(PropTypes.any),
      data: PropTypes.any,
      id: PropTypes.number,
      name: PropTypes.string,
      numTransactions: PropTypes.number,
      phone: PropTypes.any,
      type: PropTypes.string,
    }),
    entity: PropTypes.number,
    extra: PropTypes.shape({
      note: PropTypes.any,
      other: PropTypes.shape({
        accessToken: PropTypes.any,
        microMeta: PropTypes.shape({
          microAttempts: PropTypes.number,
          microSentCount: PropTypes.number,
          skipMicro: PropTypes.bool,
        }),
        updatedOn: PropTypes.any,
      }),
      suppId: PropTypes.string,
    }),
    id: PropTypes.string,
    info: PropTypes.shape({
      accountNum: PropTypes.string,
      address: PropTypes.string,
      balance: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.string,
      }),
      bankCode: PropTypes.string,
      bankHlogo: PropTypes.string,
      bankLogo: PropTypes.string,
      bankLongName: PropTypes.string,
      bankName: PropTypes.string,
      bankUrl: PropTypes.string,
      class: PropTypes.string,
      matchInfo: PropTypes.shape({
        emailMatch: PropTypes.string,
        nameMatch: PropTypes.string,
        phonenumberMatch: PropTypes.string,
      }),
      nameOnAccount: PropTypes.string,
      nickname: PropTypes.string,
      routingNum: PropTypes.string,
      speeds: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string,
    }),
    responsible: PropTypes.shape({
      address: PropTypes.any,
      data: PropTypes.any,
      email: PropTypes.string,
      firstName: PropTypes.any,
      lastName: PropTypes.any,
      phone: PropTypes.any,
      title: PropTypes.any,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        isActive: PropTypes.bool,
        lastName: PropTypes.string,
        username: PropTypes.string,
        verifyDate: PropTypes.any,
      }),
      userId: PropTypes.number,
    }),
    type: PropTypes.string,
    verified: PropTypes.bool,
  }),
};

export default FrontendPropTypes;
