import { crud } from "ampla-core/api/rest";
import { APIList } from "ampla-core/types";
import {
  Integration,
  IntegrationAPIListParams,
  BankInstitution,
  FinancialSession,
  BankConnection,
  BankConnectionCreate,
  BankConnectionUpdate,
} from "./types";

const IntegrationAPI = {
  create: async (data: Integration): Promise<Integration> =>
    crud("create", "/integration/", data),

  list: async (
    data?: IntegrationAPIListParams
  ): Promise<APIList<Integration>> => crud("list", "/integration/", data),

  update: async (id: number, data: Integration): Promise<Integration> =>
    crud("update", `/integration/${id}/`, data),

  detail: async (id: number): Promise<Integration> =>
    crud("detail", `/integration/${id}/`),

  set_password: async (id: number): Promise<{ password: string }> =>
    crud("create", `/integration/${id}/set_password/`),

  refresh_token: async (id: number): Promise<any> =>
    crud("update", `/integration/${id}/refresh_token/`),

  listInstitutions: (): Promise<APIList<BankInstitution>> =>
    crud("list", `/bank_institution/`),

  createStripeFinancialSession: (): Promise<FinancialSession> =>
    crud("create", `/stripe/financial_connection_session/`),

  listBankConnections: (): Promise<APIList<BankConnection>> =>
    crud("list", "/bank_connection/"),

  createBankConnection: (data: BankConnectionCreate): Promise<void> =>
    crud("create", "/bank_connection/", data),

  updateBankConnection: (
    id: string,
    data: BankConnectionUpdate
  ): Promise<void> => crud("update", `/bank_connection/${id}/`, data),

  deleteBankConnection: (id: string): Promise<void> =>
    crud("delete", `/bank_connection/${id}/`),
};

export default IntegrationAPI;
