import React from "react";
import { fold3 } from "@devexperts/remote-data-ts";
import Header from "components/Header";
import {
  AnalyticsData,
  AnalyticsGraph,
  BenchmarkResult,
} from "ampla-core/api/analytics/types";
import { useEmbedded } from "contexts/EmbedContext";
import SparkLine from "components/SparkLine";
import useChartData from "hooks/useChartData";
import {
  defaultDateRange,
  Timeframe,
} from "ampla-core/contexts/PageControlsProvider";
import ProgressPlaceholder from "components/ProgressPlaceholder";
import EmptyPlaceholder from "components/EmptyPlaceholder";
import MutedText from "components/Common/MutedText";
import { isMissingData } from "util/charts";
import { Typography } from "@ampla/ui-components";
import EcommerceIntegrationsCta from "components/EcommerceIntegrationsCta";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

const segments = [
  "avg_order_discount",
  "avg_order_refund",
  "avg_order_value",
  "items_per_order",
  "new_customer_percentage",
  "new_customer_revenue_percentage",
  "order_growth",
  "orders_with_discounts",
  "orders_with_refunds",
];

type Metric = {
  name: string;
  report_month: string;
  value: number;
  industry_percentile: number;
  percentile: number;
};

function toMetric(data: BenchmarkResult, segment: string): Metric {
  return {
    name: segment,
    report_month: data.report_month,
    value: data[segment],
    industry_percentile: data[segment + "_industry_percentile"],
    percentile: data[segment + "_percentile"],
  };
}

function process(data: BenchmarkResult): Metric[] {
  const metrics = segments.map((segment) => toMetric(data, segment));
  metrics.sort((a, b) => b.percentile - a.percentile);
  return metrics;
}

function processSparkline(
  results: BenchmarkResult[],
  segment: string
): Metric[] {
  return results.map((data) => toMetric(data, segment));
}

const Benchmarks: React.FC = () => {
  const isEmbedded = useEmbedded();

  const analyticsData = useChartData(
    {
      timeframe: Timeframe.Monthly,
      dateRange: defaultDateRange(Timeframe.Monthly),
    },
    [AnalyticsGraph.InsightsBenchmarks]
  );

  return (
    <>
      {!isEmbedded && (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Benchmarks</Header>
        </div>
      )}

      {fold3(
        () => <ProgressPlaceholder />,
        () => <>Error</>,
        (data: AnalyticsData) => {
          if (isMissingData(data)) {
            if (isEmbedded) {
              return <EcommerceIntegrationsCta />;
            } else {
              return <EmptyPlaceholder />;
            }
          } else {
            return <SuccessComponent data={data} />;
          }
        }
      )(analyticsData)}
    </>
  );
};

interface SuccessComponentProps {
  data: AnalyticsData;
}

const SuccessComponent: React.FC<SuccessComponentProps> = (props) => {
  const benchmarkData = props.data[
    AnalyticsGraph.InsightsBenchmarks
  ] as BenchmarkResult[];

  const data = process(benchmarkData[benchmarkData.length - 1]);

  return (
    <div className="col-span-12 mb-8 grid grid-cols-7 gap-x-8">
      <div className="col-span-2 pr-12 pb-12">
        <MutedText>
          Metrics to compare with the rest of your industry.
        </MutedText>
      </div>
      <div className="col-span-2 pr-12 pb-12">
        <MutedText>
          Trend over last 12 months, in actual values{" "}
          <span className="whitespace-nowrap">(not percentiles).</span>
        </MutedText>
      </div>
      <div className="col-span-3 pr-12 pb-12">
        <MutedText>
          Performance over last 12 months compared to your industry, by
          percentile{" "}
          <span className="whitespace-nowrap">(higher is better).</span>
        </MutedText>
      </div>
      {data?.map((metric) => (
        <React.Fragment key={metric.name}>
          <div className="col-span-2 border-r h-24 pt-4">
            <Typography variant="h5">
              {((s) => s[0].toUpperCase() + s.slice(1))(
                metric.name.split("_").join(" ")
              )}
            </Typography>
          </div>
          <div className="col-span-2 pr-12 pb-2 pt-4">
            <SparkLine
              data={processSparkline(benchmarkData, metric.name)}
              x="report_month"
              y="value"
            />
          </div>
          <div className="col-span-3 flex items-center">
            <Meter percent={metric.percentile} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const Meter: React.FC<{ percent: number }> = (props) => {
  const percent = `${(props.percent || 0).toFixed(0)}%`;

  return (
    <div className="w-full h-2 bg-gray-50 relative">
      <div
        style={{ left: percent }}
        className="absolute -top-6 -ml-3 w-8 flex flex-col items-center"
      >
        <MutedText>{percent}</MutedText>
      </div>
      <div
        className="bg-gradient-to-r from-teal-200 to-teal-400"
        style={{ width: percent }}
      ></div>
    </div>
  );
};

export default Benchmarks;
