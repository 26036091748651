import React from "react";
import { Datum, ResponsiveLine } from "@nivo/line";
import { theme } from "@ampla/ui-components";
import {
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import moment from "moment";
import { AxisTick } from "@nivo/axes";
import { DateRange } from "materialui-daterange-picker";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { formatAxisTick, formatChartValue } from "util/charts";
import ChartTooltip from "components/ChartTooltip";
import { Timeframe } from "ampla-core/contexts/PageControlsProvider";

interface AnalyticsLineGraphProps {
  data?: AnalyticsResult;
  x: string;
  y: string;
  type: AnalyticsGraphType;
  timeframe?: Timeframe;
  seriesBreakout?: SeriesBreakout;
  unit?: string;
  renderHelpText?: (point: { x: string; y: string }) => string;
}

export type SeriesBreakout = {
  key: string;
  series: string[];
};

export type Series = {
  id: string;
  color: string;
  data: Datum[];
};

export function buildSingleSeries(
  data: AnalyticsResult,
  x: string,
  y: string
): Series[] {
  return [
    {
      id: y,
      color: theme.palette.ampla.teal[400],
      data: data.map((d) => ({ x: d[x], y: d[y] } as Datum)),
    },
  ];
}

export function buildMultiSeries(
  data: AnalyticsResult,
  x: string,
  y: string,
  seriesBreakout: SeriesBreakout
): Series[] {
  return seriesBreakout.series.map((series) => {
    return {
      id: series,
      color: theme.palette.ampla.teal[400],
      data: data
        .filter((d) => d[seriesBreakout.key] === series)
        .map((d) => ({ x: d[x], y: d[y] } as Datum)),
    };
  });
}

export function findBoundWith(
  data: Series[],
  minmax: (a: number, b: number) => number
): number {
  return data.reduce(
    (outerAcc, s) =>
      minmax(
        s.data.reduce(
          (innerAcc, d: Datum) => minmax(d.y as number, innerAcc),
          0
        ),
        outerAcc
      ),
    0
  );
}

export function omitNRecentMonths(
  data: AnalyticsResult,
  x: string,
  nMonths: number
) {
  const filterDate = moment().subtract(nMonths, "months");
  return data.filter((d) => moment(d[x]).isBefore(filterDate));
}

export function padAnalyticsResult(
  data: AnalyticsResult,
  dateRange: DateRange,
  timeframe: Timeframe,
  x: string,
  y: string
): AnalyticsResult {
  let minPresentDate = data.reduce((acc, d) => {
    const reportPeriod = moment(d[x]);
    return reportPeriod.isBefore(acc) ? reportPeriod : acc;
  }, moment());

  let unit: "day" | "week" | "month" | null = null;
  if (timeframe === Timeframe.Daily) unit = "day";
  if (timeframe === Timeframe.Weekly) unit = "week";
  if (timeframe === Timeframe.Monthly) unit = "month";

  const startDate = moment(dateRange.startDate);
  const results = [];

  // Subtract a full period initially to be sure we should be padding
  minPresentDate = minPresentDate.subtract(1, unit!);

  while (minPresentDate.isAfter(startDate)) {
    results.push({ [x]: minPresentDate.format("YYYY-MM-DD"), [y]: 0 });
    minPresentDate = minPresentDate.subtract(1, unit!);
  }

  return results.concat(data);
}

const AnalyticsLineGraph: React.FC<AnalyticsLineGraphProps> = (props) => {
  if (props.data == null) return null;

  const data = props.seriesBreakout
    ? buildMultiSeries(props.data, props.x, props.y, props.seriesBreakout)
    : buildSingleSeries(props.data, props.x, props.y);

  const minY = findBoundWith(data, Math.min);
  const maxY = findBoundWith(data, Math.max);

  return (
    <ResponsiveLine
      data={data}
      enableArea
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      margin={{ top: 4, right: 72, bottom: 40, left: 0 }}
      curve="catmullRom"
      colors={{ datum: "color" }}
      useMesh
      yScale={{
        type: "linear",
        min: minY,
        max: maxY,
      }}
      yFormat={(value) => formatChartValue(props.type, value)!}
      xFormat={(value) => moment(value).format("MMM D, YYYY")}
      enableSlices="x"
      sliceTooltip={({ slice }) => (
        <>
          {slice.points &&
            slice.points.map((point) =>
              (point.data as any).hideTooltip ? null : (
                <ChartTooltip
                  x={String(point.data.xFormatted)}
                  y={String(point.data.yFormatted)}
                />
              )
            )}
        </>
      )}
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: theme.palette.ampla.gray[200],
            },
            text: {
              stroke: theme.palette.ampla.gray[200],
              strokeWidth: 0.5,
              fontSize: 12,
            },
          },
        },
      }}
      axisLeft={null}
      axisRight={{
        renderTick: (tickProps) => {
          return tickProps.tickIndex % 2 === 0 ? (
            <AxisTick
              {...tickProps}
              format={(value) => formatAxisTick(props.type, value)}
            />
          ) : (
            <></>
          );
        },
      }}
      axisBottom={{
        renderTick: (tickProps) => {
          const date = moment(tickProps.value);
          const dateStr = date.format("MMM YY");

          if (
            props.timeframe === Timeframe.Monthly ||
            (props.timeframe === Timeframe.Weekly && date.date() <= 7) ||
            (props.timeframe === Timeframe.Daily && date.date() === 1)
          ) {
            return <AxisTick {...tickProps} value={dateStr} textAnchor="" />;
          } else {
            return <></>;
          }
        },
      }}
      areaOpacity={1.0}
      defs={[
        {
          id: "linearGradient",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "inherit", opacity: 0.9 },
            { offset: 100, color: "inherit", opacity: 0.08 },
          ],
        },
      ]}
      fill={[{ match: "*", id: "linearGradient" }]}
    />
  );
};

export const xForTimeframe = (timeframe: Timeframe): string => {
  if (timeframe === Timeframe.Monthly) return "report_month";
  if (timeframe === Timeframe.Weekly) return "report_week";
  return "report_date";
};

export const tagForTimeframe = (timeframe: Timeframe): string => {
  if (timeframe === Timeframe.Monthly) return "this month";
  if (timeframe === Timeframe.Weekly) return "this week";
  return "today";
};

export default AnalyticsLineGraph;
