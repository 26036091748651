/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType } from "react";
import { useAuthContext } from "ampla-core/contexts";

function withAuthState<T>(WrappedComponent: ComponentType<T>) {
  return (props: any) => {
    const { user } = useAuthContext();

    return <WrappedComponent user={user} {...props} />;
  };
}

export default withAuthState;
