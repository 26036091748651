export type AnalyticsResult = Array<Record<string, any>>;

export type BenchmarkResult = Record<string, number> & {
  industry: string;
  report_month: string;
};

export enum AnalyticsGraph {
  InsightsBenchmarks = "InsightsBenchmarks",
  InsightsChurn = "InsightsChurn",
  InsightsCohortRetention = "InsightsCohortRetention",
  InsightsCohortSpendPerUser = "InsightsCohortSpendPerUser",
  InsightsCohortTotalSpend = "InsightsCohortTotalSpend",
  InsightsClv = "InsightsClv",
  InsightsAov = "InsightsAov",
  InsightsRevenue = "InsightsRevenue",
  InsightsOrders = "InsightsOrders",
  InsightsItemsPerOrder = "InsightsItemsPerOrder",
  InsightsDiscounts = "InsightsDiscounts",
  InsightsRefunds = "InsightsRefunds",
  InsightsSkuInfo = "InsightsSkuInfo",
  EcommercePerformance = "EcommercePerformance",
  MarketingPerformance = "MarketingPerformance",
  CashflowPerformance = "CashflowPerformance",
  InsightsFinancials = "InsightsFinancials",
  InsightsPayables = "InsightsPayables",
  InsightsReceivables = "InsightsReceivables",
}

export type AnalyticsData = Partial<Record<AnalyticsGraph, AnalyticsResult>>;

export enum AnalyticsGraphType {
  Raw = "Raw",
  Money = "Money",
  Percentage = "Percentage",
  Integer = "Integer",
}

export enum BenchmarkComparison {
  Global = "Global",
  Industry = "Industry",
}
