import PlaidAPI from "ampla-core/api/plaid";
import { PlaidItemWrite } from "ampla-core/api/plaid/types";
import SlackAPI from "ampla-core/api/slack";
import camelcaseKeys from "camelcase-keys";
import { CURRENT_USER_QUERY_KEY } from "ampla-core/constants/common";
import { PLAID_ITEMS_LIST } from "ampla-core/constants/queryKeys";
import { useMutation, useQueryClient } from "react-query";
import snakecaseKeys from "snakecase-keys";
import { useAuthContext } from "ampla-core/contexts";

type UsePlaidConnection = {
  onSuccess?: () => void;
};

const usePlaidConnection = ({ onSuccess }: UsePlaidConnection) => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  /* Mutations */

  const {
    mutateAsync: createPlaidItem,
    isLoading: isCreatingPlaidItem,
    isError: isCreateError,
  } = useMutation(async (data: PlaidItemWrite) => {
    const response = await PlaidAPI.create(data);
    return camelcaseKeys(response, { deep: true });
  });
  const {
    mutateAsync: verifyPlaidItem,
    isLoading: isVerifyingPlaidItem,
    isError: isVerifyError,
  } = useMutation((plaidItemId: number) => PlaidAPI.verify(plaidItemId));

  /* Handlers */

  const handlePlaidConnection = async (publicToken: string) => {
    await createPlaidItem(
      snakecaseKeys({
        publicToken,
        environment: window.env.REACT_APP_PLAID_ENV,
      })
    );
    await Promise.all([
      queryClient.invalidateQueries(PLAID_ITEMS_LIST),
      queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY),
    ]);
    onSuccess?.();
  };

  const handlePlaidUpdate = async (plaidItemId: number) => {
    await verifyPlaidItem(plaidItemId);
    queryClient.invalidateQueries(PLAID_ITEMS_LIST);
    queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
  };

  const handlePlaidExit = (error: any, metadata: any) => {
    if (error && error.error_type) {
      const institution_name =
        metadata && metadata.institution && metadata.institution.name
          ? metadata.institution.name
          : "";
      const user_email = user && user.email ? user.email : "";
      SlackAPI.send_message(
        `Error: adding connecting plaid item ${institution_name} for user ${user_email} with error: ${
          error ? error.error_message : "none"
        }`,
        "integration-notifications"
      );
    }
  };

  const isLoading = isCreatingPlaidItem || isVerifyingPlaidItem;
  const isError = isCreateError || isVerifyError;
  return {
    handlePlaidExit,
    handlePlaidConnection,
    handlePlaidUpdate,
    isLoading,
    isError,
  };
};

export default usePlaidConnection;
