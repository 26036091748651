import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  Theme,
  ListItemProps,
} from "@material-ui/core";
import React from "react";

import { Tooltip } from "@ampla/ui-components";

const useMenuListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px ${theme.spacing(
      1.25
    )}px ${theme.spacing(4.75)}px`,
    minWidth: "none",
    marginRight: theme.spacing(2),
    "&$selected": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
    "&$selected:hover": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
    "&:hover": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
  },
  selected: {
    borderLeft: `6px solid ${(theme.palette as any).brand.mint[500]}`,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px ${theme.spacing(
      1.25
    )}px ${theme.spacing(4)}px`,
  },
}));

const useSubmenuListItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1) + 6,
    "&$selected": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
    "&$selected:hover": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
    "&:hover": {
      backgroundColor: (theme.palette as any).brand.teal[100],
    },
  },
  selected: {
    borderLeft: `6px solid ${(theme.palette as any).brand.mint[500]}`,
    paddingLeft: theme.spacing(1),
  },
}));

const useBrandListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: (theme.palette as any).grey[300],
    },
  },
  selected: {},
}));

const useStyles = makeStyles((theme: Theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  menuText: {
    paddingLeft: `${theme.spacing(0)}px`,
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#012325",
    fontSize: 15,
    fontWeight: 500,
    alignItems: "center",
    display: "flex",
  },
  menuTextBrand: {
    paddingLeft: `${theme.spacing(0)}px`,
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#fff",
    fontSize: 15,
    fontWeight: 500,
  },
  listItemIntegrations: {
    display: "flex",
    alignItems: "center",
  },
}));

type AppMenuItemProps<C extends React.ElementType> = ListItemProps<
  C,
  { component?: C }
> & {
  text: string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  startAdornment?: any;
  endAdornment?: any;
  secondary?: any;
  submenuItem?: boolean;
  brandSwitcherItem?: boolean;
};

function AppMenuItem<C extends React.ElementType>({
  text,
  startAdornment,
  endAdornment,
  secondary,
  submenuItem,
  brandSwitcherItem,
  tooltip,
  ...props
}: AppMenuItemProps<C>) {
  const classes = useStyles();

  const menuListItemClasses = useMenuListItemStyles();
  const submenuListItemClasses = useSubmenuListItemStyles();
  const brandListItemClasses = useBrandListItemStyles();

  const listItemClasses = submenuItem
    ? submenuListItemClasses
    : brandSwitcherItem
    ? brandListItemClasses
    : menuListItemClasses;

  return (
    <Tooltip title={tooltip || ""} arrow placement="right">
      <ListItem
        classes={listItemClasses}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        button
      >
        {startAdornment && <ListItemIcon>{startAdornment}</ListItemIcon>}
        <ListItemText className={classes.listItemText}>
          <Typography
            variant="body2"
            component="span"
            className={
              brandSwitcherItem ? classes.menuTextBrand : classes.menuText
            }
          >
            {text}
          </Typography>
        </ListItemText>
        {endAdornment && <ListItemIcon>{endAdornment}</ListItemIcon>}
        {secondary && (
          <ListItemSecondaryAction className={classes.listItemIntegrations}>
            {secondary}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </Tooltip>
  );
}

export default AppMenuItem;
