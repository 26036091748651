import { get } from "../rest";
import { AnalyticsGraph, AnalyticsResult, BenchmarkResult } from "./types";
import { DateRange } from "materialui-daterange-picker";

export type ChartParams = DateRange | null;

export const getChart = (graph: AnalyticsGraph): Promise<AnalyticsResult> =>
  get(`/analytics/${graph}/get_chart/`);

export const getInsightsChart = (
  graph: AnalyticsGraph,
  params: ChartParams = null
): Promise<AnalyticsResult> =>
  get(`/analytics/${graph}/get_insights_chart/`, params);

export const getBenchmarks = (): Promise<BenchmarkResult> =>
  get(`/analytics/get_benchmarks/`);

export const getInsightsBenchmarks = (): Promise<BenchmarkResult[]> =>
  get(`/analytics/get_insights_benchmarks/`);
