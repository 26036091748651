import { APIList, APIListParams } from "ampla-core/types";
import { crud } from "ampla-core/api/rest";
import { Integration } from "ampla-core/api/integration/types";
import {
  CodatIntegrationByType,
  ConnectShopifyCodatIntegration,
} from "./types";

const CodatAPI = {
  get_company_link_url: async (): Promise<{ codat_link_url: string }> =>
    crud("list", "/codat/get_company_link_url/"),

  get_available_integrations: async () =>
    crud("list", "/codat/enabled_integrations/"), // ToDo: TS Add return type to function!

  remove_connection: async (data: {
    connection_id: string;
    company_id: string;
  }) => crud("update", "/codat/remove_connection/", data),

  store_integrations: async (data: { company_id: string }) =>
    crud("create", "/codat/store_integrations/", data),

  get_available_integrations_by_source_type: async (data: any) =>
    crud("list", "/codat/enabled_integrations_by_type/", data),

  list: async (
    integrationType?: string,
    data?: APIListParams
  ): Promise<APIList<Integration>> =>
    crud("list", "/codat/", {
      ...(integrationType ? { type: integrationType } : {}),
      ...data,
    }),

  update: async (id: string, data: Integration): Promise<Integration> =>
    crud("update", `/codat/${id}/`, data),

  detail: async (id: string): Promise<Integration> =>
    crud("detail", `/codat/${id}/`),

  enabled_integrations_by_type: async (): Promise<CodatIntegrationByType> =>
    crud("list", "/codat/enabled_integrations_by_type/"),

  connection_link_url: async (data: {
    platform_key: string;
  }): Promise<{ connection_link_url: string }> =>
    crud("list", "/codat/connection_link_url/", data),

  get_shopify_store_name: async (
    data: ConnectShopifyCodatIntegration | undefined
  ) => crud("create", "/codat/get_shopify_store_name/", data),
};

export default CodatAPI;
