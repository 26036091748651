import React from "react";
import * as Sentry from "@sentry/react";
import { ThemeProvider, theme } from "@ampla/ui-components";
import { AuthContextProvider } from "ampla-core/contexts";
import { QueryClient, QueryClientProvider } from "react-query";
import DateFnsUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import InsightsRouter from "routes/Router";
import ErrorFallback from "pages/ErrorFallback";
import { MuiThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { OverlaysContextProvider } from "ampla-core/contexts/OverlaysContextProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={15}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                <AuthContextProvider>
                  <OverlaysContextProvider>
                    <InsightsRouter />
                  </OverlaysContextProvider>
                </AuthContextProvider>
              </Sentry.ErrorBoundary>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
