import { Grid, Typography } from "@material-ui/core";
import { IntegrationProducts } from "ampla-core/api/integration/enums";
import { CamelCasedBankInstitution } from "ampla-core/api/integration/types";
import PlaidLinkButton from "ampla-core/components/PlaidLinkButton";
import { useAuthContext } from "ampla-core/contexts";
import {
  PlaidOnExit,
  PlaidOnSuccess,
} from "ampla-core/hooks/integration/types";
import { FirstLetterRange } from "../types";
import BankInstitutionTile from "./BankInstitutionTile";

interface BankInstitutionListProps {
  firstLetterRange: FirstLetterRange;
  bankInstitutions: CamelCasedBankInstitution[];
  onPlaidSuccess: PlaidOnSuccess;
  onPlaidExit: PlaidOnExit;
  onSelectStripeBank: (institution: CamelCasedBankInstitution) => void;
}

const BankInstitutionList = ({
  firstLetterRange,
  bankInstitutions,
  onPlaidSuccess,
  onPlaidExit,
  onSelectStripeBank,
}: BankInstitutionListProps) => {
  const { user } = useAuthContext();
  const isFeatureFlagEnabled =
    user?.active_entity_settings?.stripe_financial_enabled;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {firstLetterRange.rangeStart}-{firstLetterRange.rangeEnd}
        </Typography>
      </Grid>
      {bankInstitutions.map((bank) => (
        <Grid key={bank.routingNumber} item xs={6} md={3}>
          {bank.product === IntegrationProducts.Stripe &&
          isFeatureFlagEnabled ? (
            <BankInstitutionTile
              bankInstitution={bank}
              onClick={onSelectStripeBank}
            />
          ) : (
            <PlaidLinkButton
              onSuccess={onPlaidSuccess}
              onExit={onPlaidExit}
              ButtonComponent={BankInstitutionTile}
              ButtonProps={{ bankInstitution: bank }}
              routingNumber={bank.routingNumber}
            >
              Connect a different bank
            </PlaidLinkButton>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default BankInstitutionList;
