import { loadStripe } from "@stripe/stripe-js";
import IntegrationAPI from "ampla-core/api/integration";
import camelcaseKeys from "camelcase-keys";
import env from "environment";
import { useState } from "react";
import { useMutation } from "react-query";

const stripe = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStripe = () => {
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [isClientError, setIsClientError] = useState(false);
  const {
    mutateAsync: createSession,
    isLoading: isCreatingSession,
    isError: isSessionError,
  } = useMutation(async () => {
    const response = await IntegrationAPI.createStripeFinancialSession();
    return camelcaseKeys(response, { deep: true });
  });

  stripe.then((value) => {
    setIsLoadingClient(false);
    setIsClientError(!value);
  });

  const getStripeInstance = () => stripe;
  const createFinancialSession = () => createSession();

  const isLoading = isLoadingClient || isCreatingSession;
  const isError = isClientError || isSessionError;

  return { isLoading, isError, getStripeInstance, createFinancialSession };
};

export default useStripe;
