export const numberWithCommas = (x: number | string) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

type MoneyStrType = (
  x: number | undefined | null,
  withDecimal?: boolean
) => string;

export const moneyStr: MoneyStrType = (x, withDecimal = false) => {
  if (x === undefined || x === null) return withDecimal ? "$-.--" : "$-";

  const roundedNum = Math.round(x * 100) / 100;
  const cleanNum = Math.abs((x * 100) / 100); // ensures removing infinite precision, and negative value
  let output = "";

  if (withDecimal) {
    let decStr = "";
    const splitNum = cleanNum.toString().split(".");
    // 1) no decimal
    // if num has '.' but no decimals, splitNum !== 2 and/or decimal string is empty, then no decimal, so do nothing
    if (
      cleanNum.toString().endsWith(".") ||
      splitNum.length !== 2 ||
      splitNum[1].length === 0
    ) {
      decStr = ".00";
    } else {
      // 2) decimal is 032
      // TODO: if 3 or more digits, maybe round to 2 digits?
      const truncStr = splitNum[1].slice(0, 2); // ensure decimal string is only 2 characters or less

      // 3) decimal is 0
      // 4) decimal is 3
      decStr = truncStr.length === 1 ? `${truncStr}0` : truncStr; // ensure decimal string is exactly 2 chars

      // 5) decimal is 00
      // 6) decimal is 30
      // 7) decimal is 03
      decStr = `.${decStr}`;
    }

    output = numberWithCommas(splitNum[0]) + decStr;
  } else {
    output = numberWithCommas(Math.round(cleanNum));
  }
  return roundedNum < 0 ? `-$${output}` : `$${output}`;
};

export const percentageStr = (x: number, round: number | undefined) => {
  const useRound = typeof round === "number" ? round : 2;
  const output = Math.abs((x * 10000) / 100).toFixed(useRound);
  if (x < 0) {
    return `-${output}%`;
  }
  return `${output}%`;
};
