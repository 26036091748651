import { crud } from "ampla-core/api/rest";
import { TimePeriod } from "ampla-core/enums";
import { APIList } from "ampla-core/types";
import {
  EntityAPIListParams,
  EntityDetail,
  Entity,
  EntityCalcData,
  FunderwriterEvaluation,
  CollateralData,
  CashflowLimitReport,
  CapitalUsageReport,
  CounterpartyReport,
  AdvanceRepaymentReport,
  PerformanceReport,
  TaggedPlaidCashflowReport,
  PaymentSpeedReport,
  ARDetailReport,
  RunwayReport,
  EcommerceReport,
  EntityOperations,
  AdsDataReport,
  EntityUpdate,
} from "./types";

const EntityAPI = {
  create: async (data: any) => crud("create", "/entity/", data), // ToDo: TS Add proper types when we merge new funding application

  list: async (data: EntityAPIListParams): Promise<APIList<Entity>> =>
    crud("list", "/entity/", data),

  update: async (data: EntityUpdate): Promise<EntityUpdate> =>
    crud("update", `/entity/`, data),

  detail: async (id: number): Promise<EntityDetail> =>
    crud("detail", `/entity/${id}/`),

  dashboard: async (id: number): Promise<EntityCalcData> =>
    crud("list", `/entity/${id}/dashboard/`),

  cashflow_limit_report: async (id: number): Promise<CashflowLimitReport> =>
    crud("detail", `/entity/${id}/cashflow_limit_report/`),

  capital_usage: async (
    id: number,
    data: { report_date?: string; final?: boolean }
  ): Promise<CapitalUsageReport> =>
    crud("detail", `/entity/${id}/capital_usage/`, data),

  counterparty_report: async (id: number): Promise<CounterpartyReport> =>
    crud("detail", `/entity/${id}/counterparty_report/`),

  collateral: async (id: number): Promise<CollateralData> =>
    crud("detail", `/entity/${id}/collateral/`),

  advance_repayment: async (
    id: number,
    data: { all?: boolean }
  ): Promise<AdvanceRepaymentReport> =>
    crud("detail", `/entity/${id}/advance_repayment/`, data),

  performance: async (
    id: number,
    data: {
      data_type?: string;
      data_period?: TimePeriod;
      output_format?: "json" | "graph";
      max_periods?: number;
    }
  ): Promise<PerformanceReport> =>
    crud("detail", `/entity/${id}/performance/`, data),

  tagged_plaid_cashflow: async (
    id: number,
    data: { data_period?: TimePeriod; max_periods?: number }
  ): Promise<TaggedPlaidCashflowReport> =>
    crud("detail", `/entity/${id}/tagged_plaid_cashflow/`, data),

  future_ar_projection: async (id: number): Promise<any> =>
    crud("detail", `/entity/${id}/future_ar_projection/`), // ToDo: Check if we still need this endpoint, the component that uses it is unreferenced

  payment_speed: async (
    id: number,
    data: { hold_ratio?: number; requested_advance?: number }
  ): Promise<PaymentSpeedReport> =>
    crud("detail", `/entity/${id}/payment_speed/`, data),

  ar_curve: async (id: number): Promise<any> =>
    crud("detail", `/entity/${id}/ar_curve/`), // ToDo: Check if we still need this endpoint, the component that uses it is unreferenced

  ar_detail: async (id: number): Promise<ARDetailReport> =>
    crud("detail", `/entity/${id}/ar_detail/`),

  ap_detail: async (id: number): Promise<ARDetailReport> =>
    crud("detail", `/entity/${id}/ap_detail/`),

  runway: async (
    id: number,
    data: { hold_ratio?: number }
  ): Promise<RunwayReport> => crud("detail", `/entity/${id}/runway/`, data),

  ecommerce: async (id: number): Promise<EcommerceReport> =>
    crud("detail", `/entity/${id}/ecommerce/`),

  funderwriter: async (
    id: number
  ): Promise<{
    funderwriter_evaluation: FunderwriterEvaluation[];
    last_cached: string;
  }> => crud("detail", `/entity/${id}/funderwriter/`),

  config_data: async (
    id: number,
    data: { config_type?: "embed" | "filters" | "document_settings" }
  ): Promise<any> => crud("detail", `/entity/${id}/config_data/`, data),

  table: async (
    data: EntityAPIListParams
  ): Promise<APIList<EntityOperations>> =>
    crud("list", "/entity_operations/", data),

  ads_data: async (id: number): Promise<AdsDataReport> =>
    crud("detail", `/entity/${id}/ads_data/`),

  enable_banco: async (id: number): Promise<void> =>
    crud("update", `/entity/${id}/banco_setup/`),
};

export default EntityAPI;
