import React, { useState } from "react";
import { usePageControls } from "ampla-core/contexts";
import moment from "moment";
import { useEmbedded } from "contexts/EmbedContext";
import Header from "components/Header";
import PageControls from "components/PageControls";
import { tagForTimeframe, xForTimeframe } from "components/AnalyticsLineGraph";
import {
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import useChartData from "hooks/useChartData";
import AnalyticsLineStatsDashboard, {
  AggregateType,
  ChartProps,
} from "components/AnalyticsLineStatsDashboard";
import EcommerceIntegrationsCta from "components/EcommerceIntegrationsCta";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

enum OrdersChart {
  Orders = "Orders",
  ItemsPerOrder = "ItemsPerOrder",
  AvgDiscount = "AvgDiscount",
  RevenueDiscounted = "RevenueDiscounted",
  AvgRefund = "AvgRefund",
  RevenueRefunded = "RevenueRefunded",
}

const Orders: React.FC = () => {
  const pageControls = usePageControls();
  const isEmbedded = useEmbedded();

  const analyticsData = useChartData({ controls: true }, [
    AnalyticsGraph.EcommercePerformance,
  ]);

  const [selectedChart, setSelectedChart] = useState(OrdersChart.Orders);

  const CHART_PROPS: ChartProps<OrdersChart>[] = [
    {
      key: OrdersChart.Orders,
      title: "Orders",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Total orders received on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "total_orders",
      unit: "orders",
    },
    {
      key: OrdersChart.AvgDiscount,
      title: "Average Order Discount",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Average order discount percentage on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "average_discount_percentage",
      unit: "discounted",
    },
    {
      key: OrdersChart.AvgRefund,
      title: "Average Order Refund",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Average order refund percentage on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "average_refund_percentage",
      unit: "refunded",
    },
    {
      key: OrdersChart.ItemsPerOrder,
      title: "Items per Order",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Integer,
      chartDescription: `Average items per individual order on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "item_count_per_order",
      unit: "items",
    },
    {
      key: OrdersChart.RevenueDiscounted,
      title: "Revenue Discounted",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Percent of revenue earned that was discounted on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "discounted_revenue_percentage",
      unit: "discounted",
    },
    {
      key: OrdersChart.RevenueRefunded,
      title: "Revenue Refunded",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.EcommercePerformance,
      chartType: AnalyticsGraphType.Percentage,
      chartDescription: `Percent of revenue earned that was refunded on a ${pageControls.timeframe} basis ${pageControls.displayDateRange}`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "refunded_revenue_percentage",
      unit: "refunded",
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Orders</Header>
          <PageControls />
        </div>
      ) : null}

      <AnalyticsLineStatsDashboard
        data={analyticsData}
        placeholderComponent={
          isEmbedded ? <EcommerceIntegrationsCta /> : undefined
        }
        chartProps={CHART_PROPS}
        selectedChart={selectedChart}
        onSelectChart={setSelectedChart}
      />
    </>
  );
};

export default Orders;
