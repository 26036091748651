import React from "react";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import { Color, LogoSize } from "ampla-core/images/enums";
import { LogoProps } from "ampla-core/images/types";

const styles = (theme: Theme) => ({
  size_xs: {
    padding: `${theme.spacing(0.25)}px`,
    width: "24px",
    height: "24px",
  },
  size_sm: {
    padding: `${theme.spacing(0.25)}px`,
    width: "32px",
    height: "32px",
  },
  size_md: {
    // padding: theme.spacing.unit * 1,
    width: "36px",
    height: "36px",
  },
  size_lg: {
    width: "100%",
  },
  logo: {
    maxWidth: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "& > svg": {
      maxWidth: "100%",
      maxHeight: "100%",
      height: "100%",
    },
  },
});

const IconWrapper = (
  WrappedComponent: React.FC<React.SVGProps<SVGPathElement>>
) =>
  withStyles(styles)(
    class extends React.Component<LogoProps<LogoSize, Color>> {
      render() {
        const { classes = {}, color, size, ...otheProps } = this.props;
        return (
          <div
            className={classnames(classes.logo, {
              [classes.size_xs]: size === "xs",
              [classes.size_sm]: size === "sm",
              [classes.size_md]: size === "md",
              [classes.size_lg]: size === "lg",
            })}
          >
            <WrappedComponent color={color} {...otheProps} />
          </div>
        );
      }
    }
  );

export default IconWrapper;
