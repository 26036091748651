import {
  withStyles,
  WithStyles,
  CircularProgress,
  Theme,
  createStyles,
} from "@material-ui/core";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px`,
      textAlign: "center",
      width: "100%",
    },
    fullscreen: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdrop: {
      opacity: "0.7",
      zIndex: 1000,
    },
  });

type LoadingContainerProps = {
  fullscreen?: boolean;
  backdrop?: boolean;
};

const LoadingContainer = ({
  fullscreen = false,
  backdrop = false,
  classes,
}: LoadingContainerProps & WithStyles<typeof styles>) => (
  <div
    className={classNames(classes.root, {
      [classes.fullscreen]: fullscreen,
      [classes.backdrop]: backdrop,
    })}
  >
    <CircularProgress />
  </div>
);

export default withStyles(styles)(LoadingContainer);
