import {
  createStyles,
  WithStyles,
  CircularProgress,
  withStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import snakecaseKeys from "snakecase-keys";
import FivetranAPI from "ampla-core/api/fivetran";
import SerifTypography from "components/Common/SerifTypography";
import Spacer from "ampla-core/components/Common/Spacer";
import { IntegrationConnectStatus } from "ampla-core/constants/integrations";
import { INTEGRATION_CONNECTION_STATUS_KEY } from "ampla-core/constants/storageKeys";

const styles = () =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  });

const ConnectFivetranIntegration = ({ classes }: WithStyles<typeof styles>) => {
  const { integrationType }: any = useParams();

  const handleSubmitSuccess = () =>
    localStorage.setItem(
      INTEGRATION_CONNECTION_STATUS_KEY,
      IntegrationConnectStatus.Success
    );

  const handleSubmitError = () =>
    localStorage.setItem(
      INTEGRATION_CONNECTION_STATUS_KEY,
      IntegrationConnectStatus.Error
    );

  const { mutate } = useMutation(
    async (data: any) => {
      const response = await FivetranAPI.complete_connection(data);
      return response;
    },
    {
      onSuccess: handleSubmitSuccess,
      onError: handleSubmitError,
    }
  );

  useEffect(() => {
    if (integrationType)
      mutate(
        snakecaseKeys({
          integrationType,
        })
      );
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <CircularProgress size={40} />
        <Spacer size={5} />

        <SerifTypography variant="h2">Hold on!</SerifTypography>
        <Spacer size={3} />

        <Typography align="center">
          {integrationType ? (
            "Please wait while we set up your integrations."
          ) : (
            <>
              You are being redirected to another page,
              <br />
              this might take up to 10 seconds.
            </>
          )}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(ConnectFivetranIntegration);
