import { useAuthContext } from "ampla-core/contexts";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export enum MessageType {
  EMBEDDED_APP_NAVIGATE = "EMBEDDED_APP_NAVIGATE",
  EMBEDDED_APP_SCROLL = "EMBEDDED_APP_SCROLL",
  PARENT_APP_NAVIGATE = "PARENT_APP_NAVIGATE",
  PARENT_APP_CLICK = "PARENT_APP_CLICK",
}

const EmbedContext = React.createContext(false);

export const useEmbedded = (): boolean => useContext(EmbedContext);

const useEmbedParams = () => {
  // Store initial search parameters so that this state is not lost between navigations
  return useMemo(() => {
    const params = new URLSearchParams((window as any).location.search);

    return {
      isEmbedded: params.get("embed") === "true",
      path: params.get("embed_path"),
    };
  }, []);
};

const getScrollHeight = () => {
  return document.querySelector("#root > div")?.scrollHeight ?? 900;
};

const postMessage = (type: MessageType, data: object) => {
  // TODO: Should restrict origin for security
  window.parent.postMessage({ ...data, type }, "*");
};

const EmbedContextProvider: React.FC = (props) => {
  const { user } = useAuthContext();
  const embedParams = useEmbedParams();

  // Listen for routes changes and dispatch to the parent frame
  const location = useLocation();

  useEffect(() => {
    if (embedParams.isEmbedded && window.parent !== window) {
      console.log(
        `[Embed.Child] Dispatching route change to parent: ${location.pathname}`
      );

      postMessage(MessageType.EMBEDDED_APP_NAVIGATE, {
        pathname: location.pathname,
      });
    }
  }, [location]);

  // Listen for messages from the parent frame and trigger navigation
  // If "embed_path" is set, trigger initial navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (user && embedParams.isEmbedded && embedParams.path) {
      navigate(embedParams.path);
    }
  }, [user]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data.type === MessageType.PARENT_APP_NAVIGATE) {
        const pathname = event.data.pathname;

        if (pathname != null && pathname !== location.pathname) {
          console.log(
            `[Embed.Child] Received route change from parent: ${location.pathname} -> ${pathname}`
          );

          navigate(pathname);
        }
      }
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [location]);

  // Watch for changes to content height, send to parent frame to adjust child frame size
  const scrollHeightRef = useRef(getScrollHeight());

  useEffect(() => {
    postMessage(MessageType.EMBEDDED_APP_SCROLL, {
      height: scrollHeightRef.current,
    });

    const interval = setInterval(() => {
      const height = getScrollHeight();

      if (scrollHeightRef.current !== height) {
        scrollHeightRef.current = height;
        postMessage(MessageType.EMBEDDED_APP_SCROLL, { height });
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <EmbedContext.Provider value={embedParams.isEmbedded}>
      {props.children}
    </EmbedContext.Provider>
  );
};

export default EmbedContextProvider;
