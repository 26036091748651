import React from "react";

type BankingIconProps = {
  size?: number;
  color?: string;
};

const BankingIcon = ({
  size = 20,
  color = "#000",
  ...props
}: BankingIconProps) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 59 60"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_129:978)">
      <path d="M55.398 18.297H3.6a1.887 1.887 0 01-.874-3.56L28.615 1.195a1.89 1.89 0 011.75 0l25.732 13.462a1.887 1.887 0 01-.7 3.641zm-44.119-3.774H47.7L29.49 4.997l-18.21 9.526zM11.097 48.662a1.887 1.887 0 01-1.887-1.887v-25.02a1.887 1.887 0 113.775 0v25.02a1.888 1.888 0 01-1.888 1.887zM20.29 48.662a1.887 1.887 0 01-1.888-1.887v-25.02a1.887 1.887 0 113.776 0v25.02a1.89 1.89 0 01-1.889 1.887zM29.491 48.662a1.887 1.887 0 01-1.887-1.887v-25.02a1.887 1.887 0 113.775 0v25.02a1.89 1.89 0 01-1.888 1.887zM38.692 48.662a1.887 1.887 0 01-1.887-1.887v-25.02a1.887 1.887 0 113.775 0v25.02a1.89 1.89 0 01-1.888 1.887zM47.89 48.662a1.887 1.887 0 01-1.888-1.887v-25.02a1.887 1.887 0 113.775 0v25.02a1.89 1.89 0 01-1.888 1.887z" />
      <path d="M29.487 12.624a2.565 2.565 0 01-1.81-.747 2.586 2.586 0 01-.746-1.81c0-.674.276-1.328.745-1.809.952-.95 2.669-.95 3.62 0 .48.48.746 1.135.746 1.81s-.266 1.34-.747 1.81c-.47.48-1.133.746-1.808.746zM57.09 59.957H1.888a1.888 1.888 0 01-1.736-2.623l2.52-5.95a1.886 1.886 0 011.737-1.152h50.149a1.888 1.888 0 010 3.776H5.659l-.923 2.175h52.355a1.888 1.888 0 110 3.774zM13.241 23.642H8.954a1.887 1.887 0 110-3.775h4.287a1.888 1.888 0 110 3.775zM13.241 48.662H8.954a1.887 1.887 0 110-3.775h4.287a1.888 1.888 0 010 3.775zM22.433 23.642h-4.287a1.887 1.887 0 010-3.775h4.287a1.888 1.888 0 110 3.775zM31.627 23.642h-4.289a1.887 1.887 0 010-3.775h4.289a1.888 1.888 0 110 3.775zM40.82 23.642h-4.29a1.887 1.887 0 010-3.775h4.29a1.888 1.888 0 110 3.775zM50.012 23.642h-4.289a1.887 1.887 0 110-3.775h4.289a1.888 1.888 0 110 3.775zM22.433 48.662h-4.287a1.887 1.887 0 110-3.775h4.287a1.888 1.888 0 010 3.775zM31.627 48.662h-4.289a1.887 1.887 0 110-3.775h4.289a1.888 1.888 0 010 3.775zM40.82 48.662h-4.29a1.887 1.887 0 110-3.775h4.29a1.888 1.888 0 010 3.775zM50.012 48.662h-4.289a1.887 1.887 0 110-3.775h4.289a1.888 1.888 0 010 3.775z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_129:978">
        <path transform="translate(0 .98)" d="M0 0h58.978v58.978H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BankingIcon;
