import React, { useState } from "react";
import { fold3 } from "@devexperts/remote-data-ts";
import {
  AnalyticsData,
  AnalyticsGraph,
  AnalyticsGraphType,
} from "ampla-core/api/analytics/types";
import {
  Timeframe,
  usePageControls,
} from "ampla-core/contexts/PageControlsProvider";
import { formatChartValue, isMissingData } from "util/charts";
import useChartData from "hooks/useChartData";
import { tagForTimeframe, xForTimeframe } from "components/AnalyticsLineGraph";
import AnalyticsLineStatsDashboard, {
  AggregateType,
  ChartProps,
} from "components/AnalyticsLineStatsDashboard";
import MutedText from "components/Common/MutedText";
import Header from "components/Header";
import PageControls from "components/PageControls";
import FinancialIntegrationsCta from "components/FinancialIntegrationsCta";
import IntegrationCta from "components/IntegrationCta";
import IconCircle from "components/IconCircle";
import { Color } from "ampla-core/images/enums";
import { theme, Typography } from "@ampla/ui-components";
import BankingIcon from "ampla-core/images/icons/BankingIcon";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@ampla/ui-components";
import { HelpOutline } from "@material-ui/icons";
import SerifTypography from "ampla-core/components/Common/SerifTypography";
import { useEmbedded } from "contexts/EmbedContext";
import RoundedPaper from "ampla-core/components/Common/RoundedPaper";

enum CashflowChart {
  Burn = "Burn",
  Outflows = "Outflows",
  Revenue = "Revenue",
}

type Metric = {
  title: string;
  chartType: AnalyticsGraphType;
  subtitle: string;
  reportY: string;
  unit?: string;
  tooltip?: string;
};

const Cashflow: React.FC = () => {
  const pageControls = usePageControls();
  const isEmbedded = useEmbedded();

  const analyticsData = useChartData({ controls: true }, [
    AnalyticsGraph.CashflowPerformance,
  ]);

  const [selectedChart, setSelectedChart] = useState(CashflowChart.Burn);

  const CHART_PROPS: ChartProps<CashflowChart>[] = [
    {
      key: CashflowChart.Burn,
      title: "Burn",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.CashflowPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Burn on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "burn",
    },
    {
      key: CashflowChart.Outflows,
      title: "Outflows",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.CashflowPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Outflows on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Average,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "outflows",
    },
    {
      key: CashflowChart.Revenue,
      title: "Revenue",
      timeframe: tagForTimeframe(pageControls.timeframe),
      chart: AnalyticsGraph.CashflowPerformance,
      chartType: AnalyticsGraphType.Money,
      chartDescription: `Revenue on a ${pageControls.timeframe} basis.`,
      aggregateType: AggregateType.Sum,
      reportX: xForTimeframe(pageControls.timeframe),
      reportY: "revenue",
    },
  ];

  const CURRENT_METRICS: Metric[] = [
    {
      title: "Depository Balance",
      chartType: AnalyticsGraphType.Money,
      subtitle: "Current",
      reportY: "depository_balance",
    },
    {
      title: "Projected Runway",
      chartType: AnalyticsGraphType.Integer,
      subtitle: "Last 30 days",
      reportY: "runway_l30d",
      unit: "days",
      tooltip:
        "Using data from last 30 days, runway is calculated as your depository balance divided by revenue-tagged inflows minus non-internal-tagged outflows",
    },
    {
      title: "Projected Runway",
      chartType: AnalyticsGraphType.Integer,
      subtitle: "Last 90 days",
      reportY: "runway_l90d",
      unit: "days",
      tooltip:
        "Using data from last 90 days, runway is calculated as your depository balance divided by revenue-tagged inflows minus non-internal-tagged outflows",
    },
    {
      title: "Projected Runway",
      chartType: AnalyticsGraphType.Integer,
      subtitle: "Last 180 days",
      reportY: "runway_l180d",
      unit: "days",
      tooltip:
        "Using data from last 180 days, runway is calculated as your depository balance divided by revenue-tagged inflows minus non-internal-tagged outflows",
    },
  ];

  return (
    <>
      {!isEmbedded ? (
        <div className="col-span-12 flex items-center justify-between">
          <Header variant="h4">Cashflow</Header>
          <PageControls />
        </div>
      ) : null}

      <AnalyticsLineStatsDashboard
        data={analyticsData}
        placeholderComponent={
          <IntegrationCta
            title="Connect your banking platforms"
            icons={[
              <IconCircle background={Color.Black}>
                <BankingIcon color={theme.palette.common.white} size={20} />
              </IconCircle>,
            ]}
          />
        }
        chartProps={CHART_PROPS}
        selectedChart={selectedChart}
        onSelectChart={setSelectedChart}
      />

      {fold3<never, AnalyticsData, React.ReactElement | null>(
        () => null,
        () => null,
        (data: AnalyticsData) => {
          if (isMissingData(data)) return null;

          const chartData = data[AnalyticsGraph.CashflowPerformance];
          const latestData = chartData?.[chartData.length - 1] ?? {};

          const renderMetric = (metric: Metric) => {
            const metricValue = latestData[metric.reportY];
            if (metricValue === "Profitable") return metricValue;

            const metricFormatted = formatChartValue(
              metric.chartType,
              metricValue
            );

            return metric.unit
              ? `${metricFormatted} ${metric.unit}`
              : metricFormatted;
          };

          return (
            <>
              <div className="col-span-12">
                <Header variant="h4">Cashflow Metrics</Header>
              </div>
              <div className="col-span-12 grid grid-cols-2 xl:grid-cols-4 gap-6">
                {CURRENT_METRICS.map((metric) => (
                  <div
                    key={metric.reportY}
                    className="relative border border-gray-100 rounded-xl px-4 py-4 flex flex-col"
                  >
                    {metric.tooltip != null && (
                      <div className="absolute top-2 right-4">
                        <Tooltip title={metric.tooltip}>
                          <HelpOutline
                            fontSize="small"
                            className="text-teal-700"
                          />
                        </Tooltip>
                      </div>
                    )}
                    <Typography variant="overline1" className="text-gray-500">
                      {metric.title}
                    </Typography>
                    <div className="pt-4 pb-1">
                      <SerifTypography variant="h4">
                        {renderMetric(metric)}
                      </SerifTypography>
                    </div>
                    <MutedText className="">{metric.subtitle}</MutedText>
                  </div>
                ))}
              </div>
            </>
          );
        }
      )(analyticsData)}
    </>
  );
};

export default Cashflow;
