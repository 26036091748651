export enum IntegrationStatus {
  AuthError = "auth_error",
  Disabled = "disabled",
  Error = "error",
  Locked = "locked",
  MarkForRemoval = "mark_for_removal",
  Ok = "ok",
  PendingAuth = "pending_auth",
  Removed = "removed",
  Unkown = "unknown",
}

export enum IntegrationType {
  CodatAccounting = "codat_accounting",
  CodatBanking = "codat_banking",
  CodatEcommerce = "codat_ecommerce",
  CodatInsightsEcommerce = "codat_insights_ecommerce",
  FivetranFacebookAds = "fivetran_facebook_ads",
  FivetranGoogleAds = "fivetran_google_ads",
  FivetranTiktokAds = "fivetran_tiktok_ads",
  FivetranSnapchatAds = "fivetran_snapchat_ads",
  FivetranPinterestAds = "fivetran_pinterest_ads",
  FacebookAds = "facebook_ads",
  GoogleAds = "google_ads",
  Kehe = "kehe",
  Plaid = "plaid",
  QuickbooksDesktop = "quickbooks_desktop",
  Quickbooks = "qbo",
  RetailLink = "retaillink",
  SPS = "sps",
  Shopify = "shopify",
  UNFI = "unfi",
  Xero = "xero",
  Xero2 = "xero2",
}

export enum IntegrationProducts {
  Stripe = "stripe",
  Plaid = "plaid",
}
