import {
  createStyles,
  WithStyles,
  CircularProgress,
  withStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import snakecaseKeys from "snakecase-keys";
import CodatAPI from "ampla-core/api/codat";
import SerifTypography from "components/Common/SerifTypography";
import Spacer from "ampla-core/components/Common/Spacer";
import {
  IntegrationConnectStatus,
  CODAT_QUICKBOOKS_DESKTOP_KEY,
} from "ampla-core/constants/integrations";
import { INTEGRATION_CONNECTION_STATUS_KEY } from "ampla-core/constants/storageKeys";
import QuickBooksDesktopConnectInstructions from "./components/QuickBooksDesktopConnectInstructions";
import { CodatConnectData, QuickBooksDesktopConnectData } from "./types";

const styles = () =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  });

const ConnectCodatIntegration = ({ classes }: WithStyles<typeof styles>) => {
  const { companyId, platformKey, data: dataStr }: any = useParams();

  const handleSubmitSuccess = () =>
    localStorage.setItem(
      INTEGRATION_CONNECTION_STATUS_KEY,
      IntegrationConnectStatus.Success
    );

  const handleSubmitError = () =>
    localStorage.setItem(
      INTEGRATION_CONNECTION_STATUS_KEY,
      IntegrationConnectStatus.Error
    );

  const { mutate } = useMutation(
    async (data: any) => {
      const response = await CodatAPI.store_integrations(data);
      return response;
    },
    {
      onSuccess: handleSubmitSuccess,
      onError: handleSubmitError,
    }
  );

  const data: CodatConnectData | null = dataStr
    ? JSON.parse(atob(decodeURIComponent(dataStr)))
    : null;

  useEffect(() => {
    if (companyId)
      mutate(
        snakecaseKeys({
          companyId,
        })
      );
  }, []);

  const renderHoldOn = () => (
    <>
      <CircularProgress size={40} />
      <Spacer size={5} />

      <SerifTypography variant="h2">Hold on!</SerifTypography>
      <Spacer size={3} />

      <Typography align="center">
        {companyId ? (
          "Please wait while we set up your integrations."
        ) : (
          <>
            You are being redirected to another page,
            <br />
            this might take up to 10 seconds.
          </>
        )}
      </Typography>
    </>
  );

  const renderQuickBooksDesktopConnectInstructions = (
    params: QuickBooksDesktopConnectData
  ) => (
    <QuickBooksDesktopConnectInstructions
      codatCompanyId={params.codatCompanyId}
      connectorDownloadUrl={params.connectorDownloadUrl}
      integration={params.integration}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {platformKey === CODAT_QUICKBOOKS_DESKTOP_KEY
          ? renderQuickBooksDesktopConnectInstructions(
              data as QuickBooksDesktopConnectData
            )
          : renderHoldOn()}
      </div>
    </div>
  );
};

export default withStyles(styles)(ConnectCodatIntegration);
