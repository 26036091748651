import { useNavigate } from "react-router-dom";
import routes from "routes/routes";
import FinancialIntegration from "./components/FinancialIntegration";

const FinancialIntegrationPage = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(routes.appSettings.path);
  };

  return (
    <FinancialIntegration onSuccess={handleReturn} onBack={handleReturn} />
  );
};

export default FinancialIntegrationPage;
