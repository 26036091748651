// Key name to store in localStorage the Plaid environment value.
// This value gets loaded from the backend and it's stored
// when connecting to a bank that uses an OAuth flow.
export const PLAID_ENV_KEY = "plaid_env";

// Key name to store in localStorage the pathname of the page that initialized
// the OAuth flow.
// This value is used in our callback to know where to redirect the user
// after they complete an OAuth flow.
export const PLAID_CALLBACK_CALLER_URI_KEY = "plaid_callback_caller_uri";

// Key name to store the Plaid link token value to localStorage.
// This value gets loaded from the backend and it's stored
// when connecting to a bank that uses an OAuth flow.
export const PLAID_CALLBACK_LINK_TOKEN_KEY = "plaid_callback_link_token";

// Key name to store in localStorage the URL that Plaid redirects the user to after
// they complete an OAuth flow.
// This value is passed to Plaid when it gets initialized for the second time
// to finish an OAuth flow.
export const PLAID_CALLBACK_REDIRECT_URI_KEY = "plaid_callback_redirect_uri";

// Key name to store in localStorage the Plaid item ID in case the user is relinking Plaid
// from an existing account.
// This value is used to only initialize Plaid a second time after the OAuth flow on the
// correct item.
export const PLAID_CALLBACK_ITEM_ID_KEY = "plaid_callback_item_id";

// Key name for a flag we store in localStorage that holds the connection status for
// integrations and allows us to communicate between the tab that originated the
// OAuth call and the callback tab
export const INTEGRATION_CONNECTION_STATUS_KEY =
  "INTEGRATION_CONNECTION_STATUS";

// Key name used to store connect data in sessionStorage
export const SESSION_INTEGRATION_ID_KEY = "_ggIntegrationId";
export const SESSION_RETURN_URL_KEY = "_ggReturnUrl";

// Key name used to store the user object in sessionStorage
export const SESSION_USER_KEY = "_ggUser";

// Key name used to store the token in sessionStorage
export const SESSION_TOKEN_KEY = "_ggAuth";

// Key name used to store a timestamp of when the token was set in sessionStorage
export const SESSION_TIME_KEY = "_ggAuthTime";

// Key name used to store the session data object in sessionStorage
export const SESSION_DATA_KEY = "_ggData";
