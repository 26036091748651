export const CODAT_SHOPIFY_KEY = "fztf";
export const CODAT_QUICKBOOKS_DESKTOP_KEY = "pqsw";
export const CODAT_QUICKBOOKS_ONLINE_KEY = "qhyg";

export enum IntegrationConnectStatus {
  Pending = "pending",
  Success = "success",
  Error = "error",
}

export enum IntegrationPlatform {
  Codat = "codat",
  Fivetran = "fivetran",
}
