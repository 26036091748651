import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  page: {
    flexGrow: 1,
    height: "100%",
  },
  infoSection: {
    padding: "5%",
    paddingTop: "3%",
    backgroundColor: "#F8FAFD",
  },
  plaidButton: {
    marginBlock: theme.spacing(5),
  },
  bankSection: {
    padding: "4%",
    paddingTop: "5%",
    backgroundColor: theme.palette.common.white,
  },
  listContainer: {
    marginBottom: theme.spacing(8),
  },
  secureNote: {
    display: "flex",
    alignItems: "center",
    "& >svg": {
      marginInlineEnd: theme.spacing(1),
      width: "1.5rem",
      height: "1.5rem",
    },
    "& p": {
      fontSize: ".75rem",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  backButton: {
    padding: 0,
    marginBottom: "3.5rem",
  },
  amplaLogo: {
    marginBottom: "3rem",
    "& >svg": {
      width: "100px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "2rem",
    marginBottom: "1.5rem",
    lineHeight: "2.5rem",
  },
  bankLoading: {
    display: "flex",
    alignItems: "center",
    height: "90%",
  },
}));
