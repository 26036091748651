import React from "react";
import IconWrapper from "./IconWrapper";

const ShopifyIcon = () => (
  <svg
    width="95px"
    height="107px"
    viewBox="0 0 95 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Shopify_logo_2018">
        <path
          d="M82.4141686,20.8107791 C82.3515439,20.2466315 81.8505459,19.9958992 81.4747974,19.9958992 C81.0990489,19.9958992 72.8952069,19.3690685 72.8952069,19.3690685 C72.8952069,19.3690685 67.1963548,13.6649092 66.5074826,13.1007616 C65.8812351,12.4739309 64.6913649,12.6619801 64.1903669,12.7873462 C64.1277421,12.7873462 62.9378719,13.1634446 60.9965047,13.7902753 C59.0551375,8.21148213 55.7360259,3.13415349 49.7866748,3.13415349 L49.2230521,3.13415349 C47.5948086,1.00292912 45.4655672,0 43.7120742,0 C29.9346296,0 23.2964063,17.2378442 21.2297896,26.0134739 C15.8440613,27.7059168 12.0239517,28.8342121 11.5855784,29.0222613 C8.5795905,29.9625073 8.51696575,30.0251904 8.14121726,32.8459285 C7.82809352,34.9771529 0,95.5916813 0,95.5916813 L60.93388,107 L93.999747,99.8541301 C94.0623718,99.7287639 82.4767934,21.3749268 82.4141686,20.8107791 Z M57.6147683,14.6678383 C56.1117744,15.1066198 54.2956567,15.6707674 52.479539,16.2975981 L52.479539,15.1693029 C52.479539,11.7844171 52.0411657,9.02636204 51.227044,6.8324546 C54.3582814,7.20855302 56.2996486,10.6561219 57.6147683,14.6678383 Z M47.4069344,7.52196837 C48.2210561,9.65319274 48.7846788,12.6619801 48.7846788,16.7990627 L48.7846788,17.4258934 C45.4029424,18.4915056 41.8333318,19.5571178 38.1384716,20.7480961 C40.2050883,12.8500293 44.1504475,8.96367897 47.4069344,7.52196837 Z M43.3989505,3.63561804 C44.025198,3.63561804 44.6514455,3.88635032 45.1524434,4.26244874 C40.7060863,6.33099004 36.0092302,11.5336848 34.067863,22.0644405 C31.1244998,23.0046866 28.3063862,23.8195665 25.613522,24.6971295 C27.8680129,16.6736965 33.5042403,3.63561804 43.3989505,3.63561804 Z"
          id="Shape"
          fill="#95BF47"
          fillRule="nonzero"
        />
        <path
          d="M82.3125,19.3173971 C81.9375,19.3173971 73.75,18.686587 73.75,18.686587 C73.75,18.686587 68.0625,12.9462151 67.375,12.3784861 C67.125,12.126162 66.8125,12 66.5625,12 L62,107 L95,99.8087649 C95,99.8087649 83.4375,20.8313413 83.375,20.2636122 C83.125,19.6958831 82.6875,19.4435591 82.3125,19.3173971 Z"
          id="Path"
          fill="#5E8E3E"
        />
        <path
          d="M50,37.5623986 L46.0213144,49.6105617 C46.0213144,49.6105617 42.4156306,47.6753645 38.1261101,47.6753645 C31.722913,47.6753645 31.4120782,51.7330361 31.4120782,52.7318475 C31.4120782,58.2253106 45.7104796,60.3477849 45.7104796,73.2699081 C45.7104796,83.4452998 39.3072824,90 30.6660746,90 C20.2220249,90 15,83.5077255 15,83.5077255 L17.7975133,74.2687196 C17.7975133,74.2687196 23.268206,79.013074 27.8685613,79.013074 C30.9147425,79.013074 32.1580817,76.6408968 32.1580817,74.8929767 C32.1580817,67.7140194 20.4706927,67.4018908 20.4706927,55.5410048 C20.4706927,45.6153159 27.5577265,36.0017557 41.9182948,36.0017557 C47.2646536,35.9393299 50,37.5623986 50,37.5623986 Z"
          id="Path"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
);

export default IconWrapper(ShopifyIcon);
