import React from "react";
import IconWrapper from "./IconWrapper";
import { theme as amplaDesignSystem } from "@ampla/ui-components";

const XeroIcon = ({
  color = amplaDesignSystem.palette.white,
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    id="xero-header-logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    width="40"
    height="40"
  >
    <path
      fill={color}
      d="M9.6 19.9l3.4-3.4c.1-.1.2-.3.2-.4 0-.3-.3-.6-.6-.6-.2 0-.3.1-.4.2l-3.4 3.4-3.4-3.4c-.1-.1-.3-.2-.4-.2-.3 0-.6.3-.6.6 0 .2.1.3.2.4L8 19.9l-3.4 3.4c-.1.1-.2.3-.2.4 0 .3.3.6.6.6.2 0 .3-.1.4-.2l3.4-3.4 3.4 3.4c.1.1.3.2.4.2.3 0 .6-.3.6-.6 0-.2-.1-.3-.2-.4l-3.4-3.4zM29.9 19.9c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1z"
    />
    <path
      fill={color}
      d="M26.5 19.9c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.5 2.1-4.5 4.5zm1.3 0c0-1.8 1.4-3.2 3.2-3.2 1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2-1.8.1-3.2-1.4-3.2-3.2zM26.2 15.5H26c-.6 0-1.1.2-1.6.5-.1-.3-.3-.5-.6-.5s-.6.3-.6.6v7.6c0 .3.3.6.6.6s.6-.3.6-.6v-4.6c0-1.5.1-2.2 1.5-2.3h.3c.4 0 .6-.3.6-.6 0-.4-.3-.7-.6-.7zM22 19.1c-.3-1.2-.9-2.3-2-2.9-1.5-1-3.5-.9-5 .1-1.2.9-1.9 2.2-1.9 3.7 0 .4 0 .7.1 1.1.5 1.8 2 3.1 3.8 3.3.5.1 1.1 0 1.6-.1s.9-.3 1.3-.6.8-.6 1.1-1.1c.2-.3.2-.7-.1-.9-.2-.2-.6-.2-.9.1-.1.1-.1.2-.2.3-.2.3-.5.5-.9.7-.4.2-1 .4-1.5.4-1.8 0-2.7-1.3-3.1-2.1-.1-.2-.1-.3-.1-.5v-.1h6.4c1-.1 1.5-.7 1.4-1.4zm-7.6.1c.4-1.5 1.6-2.5 3.1-2.5s2.8 1.1 3.2 2.5h-6.3z"
    />
  </svg>
);

export default IconWrapper(XeroIcon);
