import { FinancialConnectionsSession } from "@stripe/stripe-js";
import IntegrationAPI from "ampla-core/api/integration";
import { IntegrationProducts } from "ampla-core/api/integration/enums";
import { CamelCasedBankConnectionCreate } from "ampla-core/api/integration/types";
import { INTEGRATION_BANK_CONNECTIONS_LIST } from "ampla-core/constants/queryKeys";
import useStripe from "ampla-core/hooks/utils/useStripe";
import { groupBy } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import snakecaseKeys from "snakecase-keys";
import { StripeFinnancialAccount } from "./types";

type UseStripeConnection = {
  onSuccess: () => void;
};

const useStripeConnection = ({ onSuccess }: UseStripeConnection) => {
  const {
    getStripeInstance,
    isLoading: isLoadingStripe,
    createFinancialSession,
    isError: isStripeError,
  } = useStripe();
  const queryClient = useQueryClient();

  /* Mutations */

  const {
    mutateAsync: createBankAccounts,
    isLoading: isCreatingAccounts,
    isError: isCreateError,
  } = useMutation(IntegrationAPI.createBankConnection);

  /* Handlers */

  const handleStripeConnection = async () => {
    const stripeInstance = await getStripeInstance();
    if (stripeInstance) {
      const { clientSecret } = await createFinancialSession();
      const { financialConnectionsSession, error } =
        await stripeInstance.collectFinancialConnectionsAccounts({
          clientSecret,
        });
      if (
        financialConnectionsSession &&
        financialConnectionsSession.accounts.length > 0
      ) {
        const { id: sessionId, accounts } = financialConnectionsSession;
        const banksPerInstitution = groupBy(accounts, "institution_name");
        const bankAccountCreatePromises = Object.entries(
          banksPerInstitution
        ).map(([institution, bankAccounts]) => {
          const bankAccountCreatePayload = getStripePayload(
            sessionId,
            institution,
            bankAccounts
          );
          return createBankAccounts(
            snakecaseKeys(bankAccountCreatePayload, { deep: true })
          );
        });
        await Promise.all(bankAccountCreatePromises);
        await queryClient.invalidateQueries(INTEGRATION_BANK_CONNECTIONS_LIST);
        onSuccess();
      } else if (error) {
        console.error(error);
      }
    }
  };

  return {
    handleStripeConnection,
    isLoadingStripe,
    isStripeError,
    isCreatingAccounts,
    isCreateError,
  };
};

const getStripePayload = (
  sessionId: string,
  institution: string,
  accounts: FinancialConnectionsSession.Account[]
): CamelCasedBankConnectionCreate => {
  const bankAccounts = accounts.map((account) => {
    const { id, last4, status, subcategory, display_name, ...accountDetails } =
      account as StripeFinnancialAccount;
    return {
      externalId: id,
      accountNumber: last4 as string,
      accountName: display_name,
      accountType: subcategory,
      accountDetails,
      externalStatus: status,
    };
  });

  const bankConnection = {
    externalId: sessionId,
    product: IntegrationProducts.Stripe,
    institution,
    bankAccounts,
  };

  return bankConnection;
};

export default useStripeConnection;
