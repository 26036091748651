import { Paper, PaperProps, withStyles, createStyles } from "@material-ui/core";
import { forwardRef } from "react";

import { theme } from "@ampla/ui-components";

export type RoundedPaperProps = PaperProps & {
  borderless?: boolean;
  justify?: string;
};

const styles = () =>
  createStyles({
    root: {
      borderRadius: theme.borderRadius,
      boxShadow: theme.boxShadow,
      border: ({ borderless }: RoundedPaperProps) =>
        !borderless ? theme.containerBorder : "none",
    },
  });

// eslint-disable-next-line react/jsx-props-no-spreading
const RoundedPaper = forwardRef(
  ({ borderless, ...rest }: RoundedPaperProps, ref) => (
    <Paper ref={ref} {...rest} />
  )
);

export default withStyles(styles)(RoundedPaper);
