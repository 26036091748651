/* eslint-disable no-restricted-syntax */
export const serializeToParams = (obj) => {
  if (obj) {
    const urlParams = Object.keys(obj)
      .reduce((a, k) => {
        if (k && obj[k]) {
          if (Array.isArray(obj[k])) {
            obj[k].forEach((item) => {
              a.push(`${k}=${encodeURIComponent(item)}`);
            });
          } else {
            a.push(`${k}=${encodeURIComponent(obj[k])}`);
          }
        }
        return a;
      }, [])
      .join("&");

    return urlParams && urlParams.length ? `?${urlParams}` : "";
  }
  return "";
};

// Extract URL parameters
// Use the parameter urlParametersAsArray to indicate which query parameters to
// map as an array. For example, the following indicates that the tag_ids query parameter
// should be returned as an array like tag_ids: [22, 24]
// urlParametersAsArray = {
//   tag_ids: true
// }
export const getUrlParameters = (a, processDict, urlParametersAsArray = {}) => {
  const reverseProcessDict = {};
  if (processDict && processDict === Object(processDict)) {
    for (const kk in processDict) {
      if (processDict[kk] && Array.isArray(processDict[kk])) {
        for (const val of processDict[kk]) {
          reverseProcessDict[val] = kk;
        }
      }
    }
  }
  if (!a || a === "") {
    a = window.location.search.substr(1).split("&"); // eslint-disable-line no-param-reassign
    if (a === "" || (a.length === 1 && a[0] === "")) {
      return {};
    }
  }
  const b = {};
  for (let i = 0; i < a.length; i += 1) {
    const p = a[i].split("=", 2);
    b[p[0]] = b[p[0]] || [];
    if (p.length === 1) {
      b[p[0]] = b[p[0]] ? [""].concat(b[p[0]]) : "";
    } else {
      const decoded = decodeURIComponent(p[1].replace(/\+/g, " "));
      b[p[0]] = b[p[0]] ? [decoded].concat(b[p[0]]) : decoded;
    }
  }
  for (const key in b) {
    if (b[key]) {
      if (
        Array.isArray(b[key]) &&
        b[key].length === 1 &&
        !urlParametersAsArray[key]
      ) {
        b[key] = b[key][0]; // eslint-disable-line prefer-destructuring
      }
      if (reverseProcessDict[key] === "int") {
        if (!urlParametersAsArray[key]) {
          b[key] = parseInt(b[key], 10);
        } else {
          b[key] = b[key].map((v) => parseInt(v, 10));
        }
      } else if (reverseProcessDict[key] === "bool") {
        if (b[key].toLowerCase() === "true") {
          b[key] = true;
        } else if (b[key].toLowerCase() === "false") {
          b[key] = false;
        } else {
          b[key] = undefined;
        }
      }
    }
  }
  return b;
};

export const isPathMatchOfAny = (path, paths) =>
  paths.some((route) => {
    if (typeof route === "string") return path === route;
    if (route instanceof RegExp) return route.test(path);
    return false;
  });

export const getEnvironmentFromAPIURL = () => {
  const { REACT_APP_API_URL } = window.env;

  const API_URL_ENV_MAPPING = {
    "http://localhost:8000/api": "local",
    "http://127.0.0.1:8000/api": "local",
    "http://local.getampla.com:8000/api": "local",
    "https://apiv2.staging.getampla.com/api": "staging",
    "https://apiv2.staging-a.getampla.com/api": "staging-a",
    "https://apiv2.staging-b.getampla.com/api": "staging-b",
    "https://apiv2.staging-c.getampla.com/api": "staging-c",
    "https://apiv2.growth-1.staging.getampla.com": "growth-1",
    "https://apiv2.growth-2.staging.getampla.com": "growth-2",
  };

  const environment = API_URL_ENV_MAPPING[REACT_APP_API_URL];

  return (
    environment ||
    REACT_APP_API_URL.replace("https://", "")
      .replace("http://", "")
      .replace("apiv2.", "")
      .replace("/api", "")
      .replace(".staging.getampla.com", "")
      .replace(".getampla.com", "")
  );
};
