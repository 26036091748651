import React from "react";
import { Button } from "@ampla/ui-components";
import { AuthAPI } from "ampla-core/api";
import routes from "routes/routes";
import SimpleForm, { useSimpleForm } from "components/SimpleForm";
import Input from "components/Input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SessionComponentWrapper from "components/SessionComponentWrapper";
import { safeSearchParams } from "util/params";
import Callout from "components/Callout";
import {
  PasswordConfirmSchema,
  PasswordSchema,
  PhoneCountryCodeSchema,
  PhoneNumberSchema,
} from "ampla-core/constants/validation";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  country_code: PhoneCountryCodeSchema,
  number: PhoneNumberSchema,
  password: PasswordSchema,
  confirmation: PasswordConfirmSchema,
});

interface RegisterForm {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  confirmation: string;
  country_code: string;
  number: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const companyId = params.get("company_id");
  const shop = params.get("shop");

  const validateForm = (state: RegisterForm) => {
    try {
      validationSchema.validateSync(state, { context: { countryCode: state.country_code, password: state.password } });
    } catch (e: any) {
      const error = e as Yup.ValidationError;
      form.setErrors({ [error.path]: error.errors });
      return false;
    }

    return true;
  }

  const form = useSimpleForm<RegisterForm>(
    async (state) => {
      await AuthAPI.register({
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
        confirmation: state.confirmation,
        phone: {
          country_code: state.country_code,
          number: state.number,
        },
      } as AuthAPI.RegistrationData);

      if (companyId && shop) {
        navigate(routes.loginLinkAccount.path + safeSearchParams());
      } else {
        navigate(routes.appSummary.path);
      }
    },
    validateForm,
    { 
      country_code: "+1"
    }
  );

  return (
    <SessionComponentWrapper
      title="Create a new account"
      titleAction={
        <p className="mt-2 text-sm text-gray-600">
          Or{" "}
          <Link
            to={routes.login.path + safeSearchParams()}
            className="font-medium text-teal-400 hover:text-teal-500"
          >
            log into an existing account
          </Link>
        </p>
      }
    >
      {companyId && shop && (
        <Callout>
          There is no Ampla user account associated with this Shopify shop.
          Please create one or log in to an existing account.
        </Callout>
      )}
      <SimpleForm
        form={form}
        grid
        className="mt-8 space-y-6"
        actions={[
          <Button fullWidth type="submit" isLoading={form.isLoading}>
            Register
          </Button>,
        ]}
      >
        <Input f={form} name="email" label="Email" type="email" />
        <Input
          f={form}
          span={2}
          name="first_name"
          label="First name"
          type="text"
        />
        <Input
          f={form}
          span={2}
          name="last_name"
          label="Last name"
          type="text"
        />
        <Input
          f={form}
          span={2}
          name="country_code"
          label="Phone Country Code"
          type="text"
          defaultValue="+1"
          disabled={true}
        />
        <Input
          f={form}
          span={2}
          name="number"
          label="Phone number"
          type="text"
        />
        <Input f={form} name="password" label="Password" type="password" />
        <Input
          f={form}
          name="confirmation"
          label="Confirm password"
          type="password"
        />
      </SimpleForm>
    </SessionComponentWrapper>
  );
};

export default Register;
