import { Datum, DatumValue } from "@nivo/line";
import {
  AnalyticsData,
  AnalyticsGraph,
  AnalyticsGraphType,
  AnalyticsResult,
} from "ampla-core/api/analytics/types";
import moment from "moment";
import { moneyStr, percentageStr } from "./strings";
import { DateRange } from "materialui-daterange-picker";

export const formatAxisTick = (type: AnalyticsGraphType, value: number) => {
  if (type === AnalyticsGraphType.Money) return moneyStr(value, false);
  if (type === AnalyticsGraphType.Percentage) return percentageStr(value, 2);
  if (type === AnalyticsGraphType.Raw) return value.toFixed(2);
  if (type === AnalyticsGraphType.Integer)
    return new Intl.NumberFormat().format(value);
};

export const formatChartValue = (
  type: AnalyticsGraphType,
  value: DatumValue,
  opts: { short?: boolean; currencyFullFormat?: boolean } = {
    short: false,
    currencyFullFormat: false,
  }
) => {
  if (type === AnalyticsGraphType.Money)
    if (opts.currencyFullFormat) {
      return (
        "$" +
        Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value as number)
      );
    } else if (value >= 100_000) {
      return (
        "$" +
        Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(value as number)
      );
    } else if (opts.short || value >= 10_000) {
      return (
        "$" +
        Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
          value as number
        )
      );
    } else {
      return moneyStr(value as number, true);
    }
  if (type === AnalyticsGraphType.Percentage)
    return percentageStr(value as number, 0);
  if (type === AnalyticsGraphType.Raw) return (value as number).toFixed(2);
  if (type === AnalyticsGraphType.Integer)
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
      value as number
    );
};

export const formatMonthsAgo = (monthsAgo: string) => {
  if (monthsAgo === "0") return "This month";
  if (monthsAgo === "1") return "1 month ago";
  return `${monthsAgo} months ago`;
};

export function sumBy(data: AnalyticsResult, key: string) {
  return data.reduce((sum, value) => sum + value[key], 0);
}

export function avgBy(data: AnalyticsResult, key: string) {
  return sumBy(data, key) / Math.max(data.length, 1);
}

export function thisMonth(data: AnalyticsResult, dateKey: string) {
  const now = moment();
  return data.filter((d) => {
    const then = moment(d[dateKey]);
    return then.year() === now.year() && then.month() === now.month();
  });
}

export function lastMonth(data: AnalyticsResult, dateKey: string) {
  const now = moment().subtract(1, "months");
  return data.filter((d) => {
    const then = moment(d[dateKey]);
    return then.year() === now.year() && then.month() === now.month();
  });
}

export function percentChange(from: number, to: number) {
  if (from < 0 || to < 0) {
    return null;
  }

  const d = to / from;

  if (d === Infinity) {
    return 100;
  } else if (isNaN(d)) {
    return 0;
  } else if (d >= 1) {
    return (d - 1) * 100;
  } else {
    return (1 - d) * -100;
  }
}

export const isMissingData = (data: AnalyticsData) => {
  let resultCount = 0;
  for (const result in data) {
    resultCount += data[result as AnalyticsGraph]!.length;
  }
  return resultCount === 0;
};

export const isMissingRecentEcommerceData = (data: AnalyticsData, pageControlDateRange: DateRange) => {
  if (data && data[AnalyticsGraph.EcommercePerformance] && pageControlDateRange?.startDate && pageControlDateRange?.endDate ) {
    const current_year = moment().format('YYYY');
    const dateRangeDays = moment(pageControlDateRange.endDate).diff(moment(pageControlDateRange.startDate), "days");
    if (current_year !== moment(pageControlDateRange.endDate).format('YYYY') || dateRangeDays < 30) {
      return false
    }

    const total_orders_current_year = data[AnalyticsGraph.EcommercePerformance]
      ?.map((i) => {
        const report_date = i.report_week || i.report_date || i.report_month
        if (report_date) {
          return report_date.substring(0, 4) === current_year ? i.total_orders : 0;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
    return total_orders_current_year === 0;
  }
  return true;
};

/**
 * Creates an anonymizer function, which will add a fixed randomized variation of +-50% to
 * an entire dataset, as well as an additional +-10% randomized variation per point.
 */
export const createAnonymizer = () => {
  const fixed = Math.random() + 0.5;
  return (y: number): number => y * fixed + y * ((Math.random() - 0.5) * 0.1);
};
