import React from "react";
import { CircularProgress } from "@material-ui/core";

const ProgressPlaceholder: React.FC = (props) => {
  return (
    <div className="col-span-12 my-6">
      <div className="h-64 flex items-center justify-center">
        <CircularProgress />
      </div>
    </div>
  );
};

export default ProgressPlaceholder;
