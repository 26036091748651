import React, { useEffect } from "react";
import { Button, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

export interface IconSelectProps<T> {
  tooltip: string;
  value: T;
  options: [T, string][];
  open: boolean;
  setOpen(open: boolean): void;
  onChange(value: T): void;
}

const SelectMenu = <T extends string>({
  tooltip,
  value,
  options,
  open,
  setOpen,
  onChange,
}: IconSelectProps<T>): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (!open) {
      setAnchorEl(null);
      setOpen(false);
    }
  }, [open]);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleSelect = (value: T) => {
    handleClose();
    onChange(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className="relative">
      <Tooltip title={tooltip}>
        <Button
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
          onClick={handleOpen}
        >
          {options.find(([val]) => val === value)?.[1]}
        </Button>
      </Tooltip>
      <div className="absolute top-10 right-0">
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {options.map(([val, label]) => (
            <MenuItem key={val} onClick={() => handleSelect(val)}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default SelectMenu;
