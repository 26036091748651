import { useState } from "react";

type ControlSet = (value: string) => void;
type ControlRemove = () => void;

export interface SessionControls {
  set: ControlSet;
  remove: ControlRemove;
}

const useSession = (sessionKey: string): [string | null, SessionControls] => {
  const [value, setValue] = useState(sessionStorage.getItem(sessionKey));

  function set(value: string) {
    sessionStorage.setItem(sessionKey, value);
    setValue(value);
  }

  function remove() {
    sessionStorage.removeItem(sessionKey);
    setValue(null);
  }

  return [value, { set, remove }];
};

export default useSession;
