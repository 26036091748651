import { crud } from "ampla-core/api/rest";

const SlackAPI = {
  send_message: async (message: string, channel: string) => {
    const response = await crud("create", "/entity/notify_slack/", {
      message,
      channel,
    });
    return response;
  },
};

export default SlackAPI;
