import React from "react";

const LargeAmplaLogo = ({
  color = "#085D6A",
}: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg
    width="187px"
    height="65px"
    viewBox="0 0 187 65"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="large" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color} fillRule="nonzero">
        <path
          d="M28.3235596,2 L17.6938815,2 L0,49 L8.8136603,49 L34.9425813,29.5172164 L31.7242851,36.5645198 L35.8611039,49 L46,49 L28.3235596,2 Z M12.8278235,37.3145634 L22.9947733,9.9105658 L29.575221,30.0372327 L12.8278235,37.3145634 Z"
          id="Shape"
        />
        <path
          d="M85.4291013,21.6845508 C84.0137853,21.653881 82.6299207,22.1004406 81.5025065,22.9514892 C80.4863822,23.7038644 79.5915476,24.6058956 78.8488091,25.626592 C78.9906633,26.4735459 79.1089692,27.3770224 79.2035655,28.336941 C79.2981619,29.2968596 79.3454197,30.2473845 79.3454197,31.1885159 L79.3454197,49 L69.6537709,49 L69.6537709,31.117943 C69.6537709,27.9275545 69.2056279,25.5583475 68.309261,24.0102419 C67.4129748,22.4621363 65.9029012,21.6868792 63.7792013,21.6845508 C62.4918685,21.6366994 61.2206647,21.9815347 60.1359117,22.6727307 C59.1951096,23.3025067 58.3696295,24.08788 57.6952778,24.9948891 L57.6952778,48.9929347 L48,48.9929347 L48,14.7180756 L57.3405214,14.7180756 L57.3405214,19.3623924 C58.3657586,17.6327565 59.8643807,16.2288475 61.6613079,15.3144519 C63.4413803,14.4377113 65.4043551,13.991553 67.3904749,14.012187 C69.7957058,14.012187 71.7775515,14.5522014 73.3360926,15.6320697 C74.9180208,16.7395569 76.205031,18.2136374 77.0857527,19.9270551 C78.6418746,17.7672383 80.3871891,16.23792 82.3218576,15.3391805 C84.3670094,14.4252667 86.5895798,13.9686711 88.8315049,14.0016693 C91.0500431,14.0016693 92.9250344,14.4357041 94.4572854,15.303854 C95.9935686,16.1821201 97.2806595,17.4325995 98.2000101,18.9388753 C99.2209731,20.6217035 99.941131,22.4676761 100.329032,24.3948998 C100.790321,26.6298659 101.01532,28.9069027 100.999191,31.1885159 L100.999191,49 L91.3040742,49 L91.3040742,31.117943 C91.3040742,27.9275545 90.8556891,25.5583475 89.9597255,24.0102419 C89.0629555,22.4621363 87.553285,21.6868792 85.4291013,21.6845508 Z"
          id="Path"
        />
        <path
          d="M104,15.6999045 L113.112088,15.6999045 L113.112088,20.0174139 C114.212451,18.4962844 115.6434,17.2455902 117.297116,16.3600593 C118.965895,15.4541842 121.006483,15.0012069 123.417293,15.0012069 C125.349272,14.9741077 127.261431,15.4037209 128.996802,16.2553182 C130.667959,17.0928494 132.129826,18.2948287 133.27617,19.7728857 C134.516061,21.400902 135.447564,23.2432481 136.023907,25.2082954 C136.697761,27.504493 137.025968,29.888743 136.996635,32.281973 C137.044994,34.9381684 136.571711,37.5779929 135.602154,40.0507722 C134.754684,42.1832622 133.475948,44.1170779 131.846014,45.7307102 C130.269197,47.2624092 128.383199,48.4380045 126.314864,49.1784237 C124.138713,49.955558 121.842854,50.3445622 119.532725,50.3277146 C118.41492,50.3385225 117.298702,50.2566687 116.195167,50.0831863 C115.302509,49.939664 114.420157,49.7424997 113.551282,49.4928059 L113.551282,59.57767 L104,64 L104,15.6999045 Z M120.208957,22.4557033 C118.81289,22.4234386 117.433472,22.7607875 116.208644,23.4337369 C115.193107,23.9849388 114.285386,24.7163779 113.53067,25.5925314 L113.53067,42.384241 C114.206109,42.6367958 114.908503,42.8125033 115.622788,42.9081848 C116.452817,43.0269125 117.289188,43.0852433 118.127145,43.0829294 C121.381462,43.0829294 123.747085,42.0838456 125.224014,40.0857388 C126.70015,38.0876321 127.441389,35.509635 127.448524,32.3518269 C127.448524,29.5176989 126.857911,27.1598329 125.676685,25.2781493 C124.496252,23.3964657 122.672883,22.4557033 120.208957,22.4557033 Z"
          id="Shape"
        />
        <path
          d="M149.872072,39.509499 C149.872072,40.5818247 150.09961,41.3878628 150.557973,41.9205177 C151.017156,42.4532523 151.794237,42.7265558 152.899893,42.7265558 C153.333612,42.7265558 153.788689,42.7020797 154.26923,42.6564759 C154.730057,42.6139018 155.186776,42.5436625 155.638567,42.4462363 L156,49.157321 C155.006881,49.4247248 153.99487,49.6226067 152.972179,49.7495318 C151.802451,49.9115367 150.622866,49.9934959 149.441638,49.9948513 C147.909656,50.0417306 146.385067,49.7680285 144.972193,49.1923211 C143.834502,48.7053497 142.833169,47.9615782 142.051981,47.0230341 C141.308579,46.1166996 140.768895,45.0699662 140.465784,43.9496449 C140.154459,42.7977519 139.997564,41.6115763 140,40.420617 L140,4.4366668 L149.872072,0 L149.872072,39.509499 Z"
          id="Path"
        />
        <path
          d="M183.079352,17.761453 C180.468268,15.2566607 176.879837,14.0030099 172.315669,14.0006625 C169.961188,13.9846357 167.613948,14.2594384 165.327037,14.8189999 C163.250075,15.3268386 161.254357,16.1269637 159.400214,17.1957399 L161.303426,24.0091474 C162.650797,23.3001645 164.076194,22.752097 165.549856,22.3761151 C167.20853,21.9416111 168.917077,21.7263018 170.631254,21.7356102 C172.938275,21.7356102 174.631538,22.3155694 175.712652,23.4754878 C176.792962,24.6354062 177.334323,26.3266368 177.336737,28.5490987 L177.336737,29.7517552 C176.112439,29.8015354 174.749785,29.847835 173.242338,29.8940536 C171.734892,29.9402723 170.280535,30.014983 168.868812,30.1075013 C165.19592,30.3447463 162.301687,31.2934834 160.185309,32.9538746 C158.069736,34.6142658 157.007927,37.0277541 157.000687,40.1943393 C156.985404,41.5324141 157.225115,42.8611803 157.70856,44.1080319 C158.15822,45.2578323 158.830699,46.3063726 159.686581,47.1927834 C160.564986,48.0919832 161.622773,48.794005 162.789958,49.2528729 C164.094695,49.7631399 165.4847,50.0156022 166.883552,49.9964996 C168.604165,50.034462 170.318342,49.7633827 171.944036,49.1959697 C174.926753,48.0645434 177.25147,45.9297432 179.760395,44.0511287 L182.130964,42.2721555 L178.921406,49.274242 L187,49.274242 L187,28.9049257 C186.997587,23.9830836 185.690436,20.2685927 183.079352,17.761453 Z M177.325475,40.8881049 C176.381109,41.6273606 175.336193,42.2264225 174.222902,42.6670781 C173.008258,43.1478817 171.713173,43.3894167 170.408435,43.3786512 C169.365932,43.4322357 168.335495,43.1328262 167.482026,42.5283412 C166.748413,41.9590665 166.382411,41.1075424 166.384812,39.9736877 C166.384812,38.6976156 166.808743,37.78676 167.654972,37.2412017 C168.696671,36.627732 169.848572,36.2297334 171.044716,36.0706797 C171.986669,35.9283004 173.019519,35.8109325 174.152115,35.7148527 C175.283907,35.6187729 176.338476,35.5262547 177.336737,35.4302559 L177.325475,40.8881049 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default LargeAmplaLogo;
