import React from "react";
import classNames from "classnames";
import { TextField } from "@material-ui/core";
import { FormState } from "./SimpleForm";

export interface InputProps {
  f: FormState<any>;
  name: string;
  label: string;
  type?: "text" | "email" | "password";
  span?: 1 | 2 | 3 | 4;
  defaultValue?: string;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = (props) => {
  const span =
    props.span === 1
      ? "col-span-1"
      : props.span === 2
      ? "col-span-2"
      : props.span === 3
      ? "col-span-3"
      : "col-span-4";

  return (
    <div className={classNames("flex flex-col space-y-2", span)}>
      <TextField
        error={props.f.errors?.[props.name] != null}
        variant="standard"
        label={props.label}
        type={props.type ?? "text"}
        onChange={(e) => props.f.update(props.name, e.target.value)}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
      />
      {extractErrors(props.f, props.name)?.map((error: string) => (
        <span key={error} className="text-sm text-red-500">
          {error}
        </span>
      ))}
    </div>
  );
};

// Attempts to extract an error by detecting its shape
const extractErrors = (f: FormState<any>, name: string): string[] | null => {
  const errors = f.errors?.[name];

  if (Array.isArray(errors)) {
    return errors;
  } else if (errors instanceof Object) {
    return errors[name] ?? null;
  } else {
    return null;
  }
};

export default Input;
