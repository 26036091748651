import { useEffect, useState } from "react";
import {
  RemoteData,
  initial,
  pending,
  success,
} from "@devexperts/remote-data-ts";
import { AnalyticsAPI } from "ampla-core/api";
import { AnalyticsData, AnalyticsGraph } from "ampla-core/api/analytics/types";
import { cache } from "./useJsonCache";
import { usePageControls } from "ampla-core/contexts";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import {
  Timeframe,
  enforceDateRangeConstraints,
} from "ampla-core/contexts/PageControlsProvider";

export type DefaultParams =
  | { controls: true }
  | {
      controls?: false;
      timeframe: "controls" | Timeframe;
      dateRange: "controls" | DateRange;
    };

const sleep = (t: number): Promise<true> =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(true), t);
  });

const useChartData = (
  defaultParams: DefaultParams,
  charts: AnalyticsGraph[]
): RemoteData<never, AnalyticsData> => {
  const pageControls = usePageControls();
  const [state, setState] = useState<RemoteData<never, AnalyticsData>>(initial);

  useEffect(() => {
    setState(pending);

    if (defaultParams.controls) {
      void fetchAnalyticsData(pageControls.timeframe, pageControls.dateRange);
    } else {
      void fetchAnalyticsData(
        defaultParams.timeframe === "controls"
          ? pageControls.timeframe
          : defaultParams.timeframe,
        defaultParams.dateRange === "controls"
          ? pageControls.dateRange
          : defaultParams.dateRange
      );
    }

    // eslint-disable-next-line
  }, [pageControls.timeframe, pageControls.dateRange]);

  const fetchAnalyticsData = async (
    timeframe: Timeframe,
    dateRange: DateRange
  ): Promise<void> => {
    dateRange = enforceDateRangeConstraints(timeframe, dateRange);

    const getChart = (chart: AnalyticsGraph) => {
      const params = {
        timeframe,
        start_date: moment(dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(dateRange.endDate).format("YYYY-MM-DD"),
      };
      return AnalyticsAPI.getInsightsChart(chart, params as DateRange)
    };

    const results = await Promise.all(charts.map(getChart));

    const data: AnalyticsData = {};
    for (let i = 0; i < charts.length; i++) data[charts[i]] = results[i];

    setState(success(data));
  };

  return state;
};

export default useChartData;
