import React from "react";
import IntegrationCta from "./IntegrationCta";
import IconCircle from "./IconCircle";
import ShopifyIcon from "ampla-core/images/icons/ShopifyIcon";

const EcommerceIntegrationsCta: React.FC = () => {
  return (
    <IntegrationCta
      title="Connect your ecommerce platforms"
      icons={[
        <IconCircle>
          <ShopifyIcon />
        </IconCircle>,
      ]}
    />
  );
};

export default EcommerceIntegrationsCta;
