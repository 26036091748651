export interface InsightsRoute {
  path: string;
}

interface InsightsRoutes {
  login: InsightsRoute;
  loginLinkAccount: InsightsRoute;
  register: InsightsRoute;
  entry: InsightsRoute;
  auth: InsightsRoute;
  connect: InsightsRoute;
  connectCodatIntegration: InsightsRoute;
  connectFivetranIntegration: InsightsRoute;
  appSummary: InsightsRoute;
  appFinancials: InsightsRoute;
  appCashflow: InsightsRoute;
  appAccountsPayablesReceivables: InsightsRoute;
  appMarketing: InsightsRoute;
  appOrders: InsightsRoute;
  appCustomerBehavior: InsightsRoute;
  appBenchmarks: InsightsRoute;
  appSettings: InsightsRoute;
  appSettingsFinancialIntegrations: InsightsRoute;
}

const routes: InsightsRoutes = {
  login: {
    path: "/login",
  },
  loginLinkAccount: {
    path: "/login/link-account",
  },
  register: {
    path: "/register",
  },
  entry: {
    path: "/",
  },
  auth: {
    path: "/auth",
  },
  connect: {
    path: "/connect",
  },
  connectCodatIntegration: {
    path: "/connect-integration/:companyId",
  },
  connectFivetranIntegration: {
    path: "/connect-ads-integration/:integrationType",
  },
  appSummary: {
    path: "/summary",
  },
  appFinancials: {
    path: "/financials",
  },
  appCashflow: {
    path: "/cashflow",
  },
  appAccountsPayablesReceivables: {
    path: "/ap-ar",
  },
  appMarketing: {
    path: "/marketing",
  },
  appOrders: {
    path: "/orders",
  },
  appCustomerBehavior: {
    path: "/cohorts",
  },
  appBenchmarks: {
    path: "/benchmarks",
  },
  appSettings: {
    path: "/settings",
  },
  appSettingsFinancialIntegrations: {
    path: "/settings/financial-integration",
  },
};

export default routes;
