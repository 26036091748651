import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ChevronRight } from "@material-ui/icons";
import { Button, Typography } from "@ampla/ui-components";
import { InsightsRoute } from "routes/routes";

export interface NavCtaProps {
  title: string;
  prompt: string;
  route: InsightsRoute;
  className?: string;
}

const NavCta: React.FC<NavCtaProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div className={classNames(props.className, "space-y-4")}>
      <Typography className="text-teal-900 !font-sans" variant="h5">
        {props.title}
      </Typography>
      <Typography className="text-gray-500 !font-sans" variant="body1">
        {props.prompt}
      </Typography>
      <Button
        color="secondary"
        size="small"
        endIcon={<ChevronRight />}
        onClick={() => navigate(props.route.path)}
      >
        Learn more
      </Button>
    </div>
  );
};

export default NavCta;
